import { Injectable } from '@angular/core';

import { Products, SitemateRegions } from '@site-mate/dashpivot-shared-library';
import { IGetWorkspaceSwitcherResponseDto } from '@site-mate/sitemate-global-shared/lib';

import { environment } from 'environments/environment';

import { ExternalLinkService } from '../../redirect/external-link.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceLinkService {
  constructor(private externalLinkService: ExternalLinkService) {}

  public getWorkspaceSwitcherItemLink(workspace: IGetWorkspaceSwitcherResponseDto): string {
    const { hostname } = this.externalLinkService.getLocation();

    if (hostname === 'localhost') {
      return this.getLocalhostLink(workspace);
    }

    return this.getEnvironmentLink(workspace, hostname);
  }

  private getLocalhostLink(workspace: IGetWorkspaceSwitcherResponseDto): string {
    const { protocol, hostname, port } = this.externalLinkService.getLocation();
    const route = this.getRoute(workspace);
    return `${protocol}//${hostname}:${port}/${route}`;
  }

  private getEnvironmentLink(workspace: IGetWorkspaceSwitcherResponseDto, currentHostname: string): string {
    const { protocol } = this.externalLinkService.getLocation();
    const route = this.getRoute(workspace);
    const auRegionWorkspace = this.isAU1RegionWorkspace(workspace);
    const environmentSubdomainString = this.getEnvironmentSubdomainString(currentHostname);

    if (auRegionWorkspace) {
      return `${protocol}//${environmentSubdomainString}dashpivot.com/${route}`;
    }
    const { subdomain } = workspace;
    return `${protocol}//${subdomain}.${environmentSubdomainString}dashpivot.com/${route}`;
  }

  private getEnvironmentSubdomainString(currentHostname: string): string {
    if (this.isProduction()) {
      return '';
    }
    const hostnameParts = currentHostname.split('.');
    const dashpivotDomainIndex = hostnameParts.indexOf(Products.Dashpivot.toLowerCase());
    const environmentIndex = dashpivotDomainIndex - 1;
    const environmentSubdomain = hostnameParts[environmentIndex];
    return `${environmentSubdomain}.`;
  }

  private getRoute(workspace: IGetWorkspaceSwitcherResponseDto): string {
    return `home/companies/${workspace.legacyId}?redirect=true`;
  }

  private isAU1RegionWorkspace(workspace: IGetWorkspaceSwitcherResponseDto): boolean {
    return (workspace.region as SitemateRegions) === SitemateRegions['ap-southeast-2'];
  }

  private isProduction(): boolean {
    return environment.production;
  }
}
