import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalComponent } from '@site-mate/global-web-ui';

import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { AddWorkspaceComponent } from './add-workspace.component';
import { OrgService } from './org.service';
import { WorkspaceLoaderDialogComponent } from './workspace-loader/workspace-loader-dialog.component';
import { WorkspaceLoaderComponent } from './workspace-loader/workspace-loader.component';
import { WorkspaceSwitcherComponent } from './workspace-switcher/workspace-switcher.component';
import { WorkspaceSwitcherService } from './workspace-switcher/workspace-switcher.service';
import { SharedModule } from '../shared/shared.module';

const components = [
  WorkspaceSwitcherComponent,
  AddWorkspaceComponent,
  WorkspaceLoaderDialogComponent,
  WorkspaceLoaderComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    UIComponentsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbModule,
    ModalComponent,
    DialogModule,
  ],
  declarations: components,
  providers: [OrgService, WorkspaceSwitcherService],
  exports: components,
})
export class OrgModule {}
