import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { environment } from 'environments/environment';

import { ConfirmService } from './shared/service/confirm.service';
import { SurvicateService } from './shared/service/survicate.service';

@Component({
  selector: 'cc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  displayIENotification: boolean;
  notificationClosedKey = 'ie-notification-closed-on';
  isAuthorized = false;
  appLoadedClass = 'app-loaded';

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly segmentService: SegmentService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly confirmService: ConfirmService,
    private readonly survicateService: SurvicateService,
  ) {}

  ngOnInit() {
    this.window.document.title = `${environment.environmentIdentifier}${this.window.document.title}`;
    this.window.document.body.classList.add(this.appLoadedClass);
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.confirmService.closeSwalIfExists();
    });
    this.trackEvent();

    this.displayIENotification = this.deviceDetectorService.browser === 'IE';
    void this.survicateService.initialize();
  }

  trackEvent() {
    const params = this.route.snapshot.queryParams;
    if (params.fromEmail && this.router.url === '/') {
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.UserEmailLoginLinkClicked),
        this.segmentService,
      );
    }
  }
}
