<ng-container *ngIf="activeTrial; else TrialExpired">
  <button
    type="button"
    placement="bottom"
    triggers="mouseenter:mouseleave"
    [ngbPopover]="popoverMessage"
    popoverClass="upgrade-account-popover"
    container="body"
    id="upgrade-account"
    class="button-regular button-white"
    (click)="showAccountUpgrade()"
  >
    <i class="cc-icon cc-icon-zap sm-text-default-blue" aria-hidden="true"></i>
    <span i18n>Upgrade</span>
    <i
      *ngIf="trialExpirationPeriod <= sevenDays"
      class="cc-icon cc-icon-warning sm-text-default-red"
      aria-hidden="true"
    ></i>
  </button>
</ng-container>

<ng-template #TrialExpired>
  <button
    type="button"
    id="upgrade-account"
    class="button-regular button-white trial-ended"
    (click)="showAccountUpgrade()"
  >
    <i class="cc-icon cc-icon-warning sm-text-default-red" aria-hidden="true"></i>
    <span i18n>{{ upgradeButtonText }}</span>
  </button>
</ng-template>
