import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { getTranslationProviders } from './app/i18n/i18n-provider';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

void getTranslationProviders().then((providers) => {
  const options = { providers, preserveWhitespaces: true };
  void platformBrowserDynamic().bootstrapModule(AppModule, options);
  return true;
});
