import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { DeployModePopover } from 'app/folders/deploy-mode-popover.component';
import { FolderNavbarComponent } from 'app/layout/folder-navbar/folder-navbar.component';
import { LayoutModule } from 'app/layout/layout.module';
import { NavigateUserHomeService } from 'app/shared/service/navigate-user-home.service';
import { SharedModule } from 'app/shared/shared.module';

import { OverlayComponent } from './overlay.component';
import { OverlayService } from './overlay.service';
import { SecureRoutingModule } from './secure-routing.module';
import { SecureComponent } from './secure.component';
import { UIComponentsModule } from '../ui-components/ui-components.module';

@NgModule({
  declarations: [SecureComponent, FolderNavbarComponent, OverlayComponent, DeployModePopover],
  imports: [
    CommonModule,
    LayoutModule,
    NgbPopoverModule,
    SecureRoutingModule,
    SharedModule,
    UIComponentsModule,
  ],
  exports: [FolderNavbarComponent],
  providers: [NavigateUserHomeService, OverlayService],
})
export class SecureModule {}
