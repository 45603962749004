{
  "name": "dashpivot-web",
  "version": "24.4.0",
  "license": "MIT",
  "engineStrict": true,
  "engines": {
    "npm": ">=8.0.0",
    "node": ">=18.0.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "node --max-old-space-size=8192 node_modules/@angular/cli/bin/ng serve --configuration=dev-local",
    "start:es": "ng serve --configuration=dev-local --force-esbuild",
    "start:no-cache": "ng cache clean & node --max-old-space-size=8192 node_modules/@angular/cli/bin/ng serve --configuration=dev-local",
    "build": "exit 0",
    "cache:clean": "ng cache clean",
    "configure": "node ./node_modules/dotenv-cli/cli.js -e ../.env.overrides -e ../.env -- cross-env TS_NODE_PROJECT=tsconfig.helpers.json node -r ts-node/register ./scripts/configure.ts",
    "build-ui": "node --max-old-space-size=8192 node_modules/@angular/cli/bin/ng build --configuration production --source-map true",
    "build-ui-cf": "node node_modules/@angular/cli/bin/ng build --configuration cloudflare",
    "test": "exit 0",
    "test-ui": "node --max-old-space-size=8192 node_modules/jest/bin/jest.js --maxWorkers=50% --colors --verbose --forceExit",
    "cypress:local:run": "node --max_old_space_size=8048 ./node_modules/cypress/bin/cypress run -C ui-tests/config/local.config.js",
    "cypress:local:open": "node --max_old_space_size=8048 ./node_modules/cypress/bin/cypress open -C ui-tests/config/local.config.js",
    "cypress:dev:run": "node --max_old_space_size=8048 ./node_modules/cypress/bin/cypress run -C ui-tests/config/dev.config.js",
    "cypress:dev:open": "node --max_old_space_size=8048 ./node_modules/cypress/bin/cypress open -C ui-tests/config/dev.config.js",
    "cypress:github:run": "node --max_old_space_size=8048 ./node_modules/cypress/bin/cypress run -C ui-tests/config/github.config.js",
    "fixt:dev:deploy": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/dev.config.js deployKit",
    "fixt:dev:delete": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/dev.config.js deleteKit",
    "fixt:local:deploy": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/local.config.js deployKit",
    "fixt:local:delete": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/local.config.js deleteKit",
    "fixt:github:deploy": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/github.config.js deployKit",
    "fixt:github:delete": "ts-node -P ./ui-tests/fixture-kits/tsconfig.fixture-kits.json ./ui-tests/fixture-kits/cli.ts -C ui-tests/config/github.config.js deleteKit",
    "fixt:build": "tsc --project ui-tests/fixture-kits/tsconfig.fixture-kits.json",
    "lint": "eslint --ext .js,.ts,.html ./src ./scripts && rushx betterer:ci",
    "i18n": "ng xi18n --output-path src/assets/locale &&  xliffmerge --profile xliffmerge.json en de ",
    "bundle-report": "webpack-bundle-analyzer dist/stats.json",
    "gen-stats": "ng build --configuration production --stats-json",
    "strictFunctionTypes:check": "tsc -p ./tsconfig.strictFunctionTypes.json",
    "noImplicitAny:check": "tsc -p ./tsconfig.noImplicitAny.json",
    "strictNullChecks:check": "tsc -p ./tsconfig.strictNullChecks.json",
    "strictPropertyInitialization:check": "tsc -p ./tsconfig.strictPropertyInitialization.json",
    "strict:check": "rushx strictFunctionTypes:check && rushx noImplicitAny:check && rushx strictNullChecks:check && rushx strictPropertyInitialization:check",
    "storybook": "ng run cc-front-end:storybook",
    "build-storybook": "ng run cc-front-end:build-storybook",
    "betterer": "betterer --config ./.betterer.ts --results ./.betterer.results",
    "betterer:watch": "betterer watch",
    "betterer:ci": "betterer ci",
    "betterer:toggle": "node ../common/scripts/toggle-strict-flag.mjs",
    "bon": "node ../common/scripts/toggle-strict-flag.mjs on",
    "boff": "node ../common/scripts/toggle-strict-flag.mjs off"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.11",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/localize": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/platform-server": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@bugsnag/browser": "^7.25.0",
    "@bugsnag/browser-performance": "^2.7.0",
    "@bugsnag/js": "^7.25.0",
    "@bugsnag/plugin-angular": "^7.25.0",
    "@angular/google-maps": "^17.3.10",
    "@ng-bootstrap/ng-bootstrap": "^15.1.2",
    "@ng-select/ng-select": "12.0.7",
    "@popperjs/core": "2.11.6",
    "@site-mate/global-web-ui": "17.2.0",
    "@site-mate/sitemate-global-shared": "~1.0.104",
    "@site-mate/dashpivot-shared-library": "workspace:*",
    "@segment/analytics-next": "^1.51.7",
    "@swimlane/ngx-charts": "20.5.0",
    "@tweenjs/tween.js": "^23.1.1",
    "@uiowa/digit-only": "^3.2.1",
    "@xmldom/xmldom": "0.8.8",
    "angular-auth-oidc-client": "17.1.0",
    "angular-google-tag-manager": "1.9.0",
    "awesome-bootstrap-checkbox": "1.0.1",
    "batch-promises": "0.0.3",
    "bootstrap": "4.0.0-alpha.6",
    "bson": "4.7.2",
    "canvas-confetti": "^1.6.0",
    "core-js": "^3.12.1",
    "dayjs": "~1.11.11",
    "dayjs-plugin-utc": "~0.1.2",
    "d3-scale": "4.0.2",
    "d3-shape": "2.1.0",
    "dom-autoscroller": "^2.3.4",
    "dom-parser": "^0.1.6",
    "dotenv-cli": "~7.2.1",
    "dompurify": "^3.1.6",
    "dotenv": "^16.0.3",
    "dragula": "^3.7.2",
    "dropzone": "^5.9.3",
    "escape-string-regexp": "^5.0.0",
    "fabric": "^3.6.3",
    "file-saver": "^2.0.5",
    "font-awesome": "4.7.0",
    "hot-formula-parser": "^3.0.2",
    "intersection-observer": "^0.12.0",
    "is-utf8": "^0.2.1",
    "jest-mock": "^29.7.0",
    "jquery": "3.7.1",
    "launchdarkly-js-client-sdk": "^3.1.3",
    "libphonenumber-js": "^1.10.37",
    "loader-utils": "^3.2.0",
    "lodash-es": "^4.17.21",
    "lz-string": "^1.4.4",
    "moment": "^2.19.3",
    "moment-range": "^4.0.2",
    "moment-timezone": "^0.5.35",
    "ng-lazyload-image": "^9.1.2",
    "ng-recaptcha": "^13.2.1",
    "ng2-dragula": "^5.1.0",
    "ngx-color-picker": "^16.0.0",
    "ngx-device-detector": "^7.0.0",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-moment": "^6.0.0",
    "ngx-quill": "^25.3.2",
    "ngx-segment-analytics": "^17.0.0",
    "ngx-toastr": "15.2.2",
    "@iharbeck/ngx-virtual-scroller": "17.0.2",
    "quill": "^2.0.2",
    "rxjs": "7.4.0",
    "sweetalert2": "11.12.2",
    "tslib": "^2.6.3",
    "url-search-params-polyfill": "^8.1.1",
    "uuid": "^9.0.0",
    "world-flags-sprite": "0.0.2",
    "zone.js": "~0.14.7",
    "signature_pad": "^5.0.2",
    "@angular/youtube-player": "^17.3.10",
    "@survicate/survicate-web-package": "^24.5.1-npm",
    "country-to-currency": "~1.1.5",
    "ua-parser-js": "1.0.38"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@aws-sdk/client-secrets-manager": "~3.352.0",
    "@babel/core": "^7.8.3",
    "@bugsnag/core": "^7.25.0",
    "@bugsnag/source-maps": "^2.3.3",
    "@compodoc/compodoc": "^1.1.21",
    "@hudsontavares/jest-angular-import-meta": "1.0.2",
    "@jest/globals": "~29.7.0",
    "@storybook/addon-actions": "^8.1.11",
    "@storybook/addon-docs": "^8.1.11",
    "@storybook/addon-essentials": "^8.1.11",
    "@storybook/addon-interactions": "^8.1.11",
    "@storybook/addon-links": "^8.1.11",
    "@storybook/addons": "^7.6.17",
    "@storybook/blocks": "^8.1.11",
    "@storybook/angular": "^8.1.11",
    "@storybook/testing-library": "0.2.2",
    "@storybook/theming": "^8.1.11",
    "@tailwindcss/aspect-ratio": "^0.4.2",
    "@tailwindcss/forms": "^0.5.3",
    "@tailwindcss/line-clamp": "^0.4.4",
    "@tailwindcss/typography": "^0.5.9",
    "@types/dom-parser": "^0.1.0",
    "@types/dragula": "^2.1.34",
    "@types/dropzone": "^5.7.4",
    "@types/escape-string-regexp": "^2.0.1",
    "@types/googlemaps": "3.43.3",
    "@types/jest": "^29.5.12",
    "@types/lodash": "^4.14.192",
    "@types/node": "^18.0.0",
    "@types/react": "^16.9.49",
    "@types/yargs": "^17.0.24",
    "@typescript-eslint/eslint-plugin": "~6.13.2",
    "@typescript-eslint/parser": "~6.13.2",
    "babel-loader": "^8.0.6",
    "canvas": "2.11.2",
    "codelyzer": "^6.0.2",
    "cross-env": "^7.0.3",
    "esbuild": "^0.15.9",
    "eslint": "^8.57.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-airbnb-typescript": "^17.0.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jest": "^28.6.0",
    "eslint-plugin-prettier": "~5.1.3",
    "eslint-plugin-promise": "^6.4.0",
    "eslint-plugin-storybook": "^0.8.0",
    "globals": "^13.24.0",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.1.1",
    "lodash": "4.17.21",
    "ng-mocks": "^14.13.0",
    "ngx-spec": "^2.1.6",
    "node-fetch": "^2.6.9",
    "postcss": "^8.4.24",
    "postcss-scss": "^4.0.6",
    "prettier": "~3.2.5",
    "prettier-plugin-tailwindcss": "~0.5.14",
    "raw-loader": "^4.0.1",
    "storybook": "~8.1.11",
    "stream-browserify": "^3.0.0",
    "stylelint": "^15.9.0",
    "stylelint-config-standard": "^33.0.0",
    "stylelint-config-standard-scss": "^10.0.0",
    "tailwindcss": "^3.3.2",
    "ts-jest": "29.1.5",
    "ts-loader": "^9.4.2",
    "ts-mockito": "^2.6.1",
    "ts-node": "~10.9.1",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5",
    "yargs": "^17.4.0",
    "react": "~18.2.0",
    "react-dom": "^18.2.0",
    "@angular-eslint/builder": "~17.5.2",
    "@angular-eslint/eslint-plugin": "~17.5.2",
    "@angular-eslint/eslint-plugin-template": "~17.5.2",
    "@angular-eslint/schematics": "~17.5.2",
    "@angular-eslint/template-parser": "~17.5.2",
    "@types/d3-shape": "3.1.6",
    "@types/d3-scale": "4.0.8",
    "@site-mate/sitemate-infra-shared": "1.0.37",
    "@betterer/cli": "^5.4.0",
    "@betterer/typescript": "^5.4.0",
    "@betterer/angular": "^5.4.0"
  }
}
