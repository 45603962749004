import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { OverlayService } from './overlay.service';

@Component({
  selector: 'cc-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  isVisible$: Observable<boolean>;

  constructor(private readonly overlayService: OverlayService) {
    this.isVisible$ = this.overlayService.overlayToggled$;
  }

  overlayClicked() {
    this.overlayService.onOverlayClicked();
  }
}
