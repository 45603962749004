<div class="plan-preview sm-flex sm-flex-col sm-gap-y-4">
  <div class="sm-flex sm-flex-col sm-gap-y-2">
    <h2 class="plan-preview__name sm-text-2xl sm-font-bold">
      {{ plan.name }}
    </h2>

    <p class="plan-preview__summary sm-text-sm sm-text-secondary-text">{{ plan.summary }}</p>
  </div>

  <div class="plan-preview__feature-highlights sm-flex sm-flex-col sm-gap-2">
    <div data-test="limits-container" *ngIf="limits.length > 0" class="sm-flex sm-flex-col sm-gap-y-1">
      <div class="sm-text-xs sm-font-bold sm-text-secondary-text" i18n>Limits</div>

      <cc-plan-preview-feature-highlight
        data-test="plan-preview-feature-highlight"
        *ngFor="let featureHighlight of limits"
        [featureHighlight]="featureHighlight"
      />
    </div>

    <div
      data-test="inclusions-container"
      *ngIf="inclusions.length > 0"
      class="sm-flex sm-flex-col sm-gap-y-1"
    >
      <div class="sm-text-xs sm-font-bold sm-text-secondary-text" i18n>Inclusions</div>
      <cc-plan-preview-feature-highlight
        data-test="plan-preview-feature-highlight"
        *ngFor="let featureHighlight of inclusions"
        [featureHighlight]="featureHighlight"
      />
    </div>
  </div>
</div>
