<div *ngIf="!loading; else loadingPage" class="activate-account-container" ccHideIntercom>
  <div *ngIf="!company.id" class="sm-mb-4 sm-text-center">
    <img src="./assets/images/dashpivot-logo.png" alt="Dashpivot logo" class="logo" />
  </div>
  <div class="activate-form-container">
    <div *ngIf="!activated" class="activation-content">
      <div>
        <div *ngIf="company?.logo" class="sm-mb-4 sm-text-center">
          <img src="{{ company.logo }}" alt="Dashpivot logo" class="company-logo" />
        </div>
        <div>
          <form
            #formBasicUserInfo="ngForm"
            name="formBasicUserInfo"
            class="form"
            (ngSubmit)="setSignatureNameInfo()"
            [hidden]="currentForm !== 1"
          >
            <h5 i18n class="h5 sm-mb-0 sm-text-center">Activate your Dashpivot account</h5>
            <div *ngIf="company?.name" class="sm-text-center" data-test="activation-context">
              <p i18n class="sm-m-0 sm-text-sm sm-text-default-text">
                Once your account is created, you will gain
                {{ isContributor ? 'internal' : 'external' }} access to the
                <span class="sm-font-bold">{{ company.name }}</span> workspace as a Dashpivot
                {{ isContributor ? 'Contributor' : 'Visitor' }}.
              </p>
            </div>
            <div>
              <input
                [value]="email"
                type="text"
                class="form-control sm-mb-4 sm-mt-6"
                data-test="activation-email"
                disabled
              />
            </div>
            <div class="form-group" [class.has-feedback]="firstName.valid">
              <input
                [(ngModel)]="model.firstName"
                [pattern]="doNotAcceptWithoutAlphanumeric"
                [class.error-state]="hasFormError(formBasicUserInfo, firstName)"
                type="text"
                class="form-control sm-capitalize"
                placeholder="First name"
                i18n-placeholder="First name"
                #firstName="ngModel"
                name="firstName"
                data-test="first-name"
                required
                ccFocus
              />
              <i class="input-feedback fa fa-check-circle text-success"></i>
              <div
                class="sm-mt-1 sm-text-xs sm-text-default-red"
                i18n
                *ngIf="hasFormError(formBasicUserInfo, firstName, 'pattern')"
                data-test="first-name-error"
              >
                First name is required
              </div>
            </div>
            <div class="form-group" [class.has-feedback]="lastName.valid">
              <input
                [(ngModel)]="model.lastName"
                [pattern]="doNotAcceptWithoutAlphanumeric"
                [class.error-state]="hasFormError(formBasicUserInfo, lastName)"
                type="text"
                class="form-control sm-capitalize"
                placeholder="Last name"
                i18n-placeholder="Lastname"
                #lastName="ngModel"
                name="lastName"
                data-test="last-name"
                required
              />
              <i class="input-feedback fa fa-check-circle text-success"></i>
              <div
                class="sm-mt-1 sm-text-xs sm-text-default-red"
                i18n
                *ngIf="hasFormError(formBasicUserInfo, lastName, 'pattern')"
                data-test="last-name-error"
              >
                Last name is required
              </div>
            </div>
            <div class="form-group" [class.has-feedback]="mobile.valid">
              <ng-select
                id="activate-country"
                class="mb-3 activate-country"
                name="country-list"
                [items]="countries"
                bindValue="value"
                bindLabel="name"
                placeholder="Select your country"
                i18n-placeholder="Select your country"
                [(ngModel)]="selectedCountry"
                filterPlaceholder="Type to search..."
                i18n-filterPlaceholder="Type to search..."
                (close)="handleCountrySelect()"
                (change)="populateContinentCode()"
                [clearable]="false"
                [selectOnTab]="true"
              >
                <ng-template ng-label-tmp let-item="item">
                  <div class="country-option f16">
                    <div class="flag-icon flag {{ item.ccode | lowercase }}"></div>
                    <div>{{ item.name }}</div>
                  </div>
                </ng-template>
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  <div class="country-option f16">
                    <div class="flag-icon flag {{ item.ccode | lowercase }}"></div>
                    <div>{{ item.name }}</div>
                  </div>
                </ng-template>
              </ng-select>
              <div class="input-group">
                <div class="input-group-addon">
                  <span data-test="area-code-input" class="input-group-text"
                    >({{ selectedCountryAreaCode }})</span
                  >
                </div>
                <input
                  [(ngModel)]="model.mobile"
                  type="tel"
                  class="form-control mobile-input"
                  [class.error-state]="!hasValidPhoneNumber"
                  id="mobile"
                  placeholder="Work mobile (e.g 401234567)"
                  i18n-placeholder="Work mobile (e.g 401234567)"
                  #mobileInput
                  #mobile="ngModel"
                  name="mobile"
                  required
                  pattern="[0-9]*"
                  digitOnly
                  (ngModelChange)="validatePhoneNumber()"
                />
                <i class="input-feedback fa fa-check-circle text-success"></i>
              </div>
              <div
                class="sm-mt-1 sm-text-xs sm-text-default-red"
                *ngIf="!hasValidPhoneNumber"
                data-test="mobile-danger"
              >
                <div *ngIf="mobile.errors?.required" i18n>Mobile is required</div>
                <div *ngIf="!mobile.errors?.required && !hasValidPhoneNumber" i18n>
                  Invalid mobile number - incorrect length.
                </div>
              </div>
            </div>

            <div class="form-group" [class.has-feedback]="signUpTerms.valid">
              <div class="checkbox abc-checkbox abc-checkbox-primary sm-flex sm-items-center">
                <input
                  [(ngModel)]="model.termsAndConditions"
                  class="styled form-checkbox"
                  type="checkbox"
                  id="signup-terms"
                  name="signup-terms"
                  required
                  #signUpTerms="ngModel"
                />
                <label
                  class="default-label checkbox-label sm-cursor-pointer sm-font-normal"
                  for="signup-terms"
                  i18n
                >
                  I agree to the
                  <a
                    [href]="
                      selectedCountryContinentCode === continentCodes.Europe
                        ? urlMap[continentCodes.Europe].privacyPolicy
                        : urlMap.default.privacyPolicy
                    "
                    target="_blank"
                    rel="noopener"
                    class="sm-underline"
                    data-test="privacy-policy"
                  >
                    Privacy Policy</a
                  >
                  &amp;
                  <a
                    [href]="
                      selectedCountryContinentCode === continentCodes.Europe
                        ? urlMap[continentCodes.Europe].termsOfService
                        : urlMap.default.termsOfService
                    "
                    target="_blank"
                    rel="noopener"
                    class="sm-underline"
                    data-test="terms-of-service"
                    >Terms of Service</a
                  >
                </label>
              </div>
              <div
                class="sm-text-xs sm-text-default-red"
                i18n
                *ngIf="hasFormError(formBasicUserInfo, signUpTerms)"
              >
                Please accept the Privacy Policy and Terms of Service.
              </div>
            </div>

            <div class="form-group">
              <button
                type="submit"
                class="button-large button-blue"
                i18n
                ccSegmentCTA
                ctaContext="Invited Next 1"
                data-test="form-one-next-button"
                (click)="next(formBasicUserInfo)"
                [disabled]="!formBasicUserInfo?.valid || !hasValidPhoneNumber"
              >
                Next
              </button>
            </div>
          </form>

          <cc-activate-account-signature
            *ngIf="currentForm === 2"
            [form]="formSignature"
            [userInfo]="signatureNameInfo"
            [ssoSignUp]="{ isSSO: isSSOSignUp, isActivating: isActivatingSSOSignup }"
            (onSubmit)="next(formSignature)"
          ></cc-activate-account-signature>

          <cc-activate-account-password
            *ngIf="currentForm === 3"
            [form]="formPassword"
            [working]="working"
            (onSubmit)="activate($event)"
          ></cc-activate-account-password>
        </div>
      </div>
      <div class="text-center nav-pill-container">
        <a
          class="nav-pill sm-cursor-pointer"
          [class.nav-pill-inactive]="currentForm !== 1"
          (click)="goToForm(1)"
        >
          <i class="fa fa-circle"></i
        ></a>
        <a
          class="nav-pill sm-cursor-pointer"
          [class.nav-pill-inactive]="currentForm !== 2"
          (click)="goToForm(2)"
        >
          <i class="fa fa-circle"></i
        ></a>
        <a
          *ngIf="!isSSOSignUp"
          class="nav-pill sm-cursor-pointer"
          [class.nav-pill-inactive]="currentForm !== 3"
          (click)="goToForm(3)"
        >
          <i class="fa fa-circle"></i
        ></a>
      </div>
    </div>
    <div *ngIf="activated" class="d-flex flex-column align-items-center">
      <cc-activate-account-welcome [isMobile]="isMobile"></cc-activate-account-welcome>
    </div>
  </div>

  <div *ngIf="company?.id" class="sm-my-8 sm-w-100 sm-text-center">
    <img src="./assets/images/dashpivot-logo-dark.png" alt="Dashpivot logo" class="footer-logo" />
    <p i18n class="sm-m-0 sm-pt-2 sm-text-secondary-text">
      Powered By <span class="sm-font-bold">Sitemate</span>
    </p>
  </div>
</div>

<ng-template #loadingPage>
  <div class="app-loading-spinner">
    <img src="./assets/images/dashpivot-load.gif" alt="dashpivot loading animation" width="50px" />
    <div i18n>Loading Dashpivot</div>
  </div>
</ng-template>
