import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-activate-account-welcome',
  templateUrl: './activate-account-welcome.component.html',
  styleUrls: ['./activate-account-welcome.component.scss'],
})
export class ActivateAccountWelcomeComponent {
  @Input() isMobile: boolean = false;
}
