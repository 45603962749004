import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';
import { environment } from 'environments/environment';

import { SignUpService } from '../sign-up-simplified/sign-up.service';

@Component({
  selector: 'cc-human-verification',
  templateUrl: './human-verification.component.html',
  styleUrls: ['./human-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HumanVerificationComponent implements OnInit {
  isCaptchaResolved = false;
  isSubmitted = false;
  isProcessing = false;
  unSubscribe = new Subject<void>();

  constructor(
    private readonly signupService: SignUpService,
    private readonly eventsService: EventsService,
  ) {}

  reCaptchaSiteKey = environment.reCaptchaSiteKey;
  captcha: string;

  ngOnInit(): void {
    this.subscribeToSignupProcessing();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  recaptchaSolved(event: string) {
    this.signupService.updateSignupModel({ reCaptchaResponse: event });
    this.isCaptchaResolved = true;
  }

  subscribeToSignupProcessing() {
    this.signupService.isProcessing$.pipe(takeUntil(this.unSubscribe)).subscribe((isProcessing) => {
      this.isProcessing = isProcessing;
    });
  }

  async submit() {
    this.isSubmitted = true;
    if (this.isCaptchaResolved) {
      this.eventsService.trackEvent(EventTypes.CtaClicked, {
        Context: 'Signup Human Verification',
      });
      this.signupService.updateSignupModel({
        reCaptchaSubmission: {
          v2: true,
          v3: true,
        },
      });
      await this.signupService.onSubmit();
    }
  }
}
