import { SubscriptionStatuses } from '@site-mate/sitemate-global-shared/lib';

export const AccountUpgradeWidgetContextModel = {
  [SubscriptionStatuses.ExpiredTrial]: {
    title: 'Your 30-day free trial of Dashpivot has ended',
    subtitle: `We hope you had a great experience!<br /> You will need to subscribe to continue usage`,
    imageSrc: './assets/images/user/trial-expiration-background.svg',
    imageAlt: 'Clock image',
    isCloseButtonVisible: false,
  },
  [SubscriptionStatuses.Churned]: {
    title: 'Thank you for using Dashpivot!',
    subtitle: `Your Dashpivot subscription has ended. But we've saved all your work, making it easy to get started again. If you'd like to <strong>reactivate your subscription</strong> or discuss your needs, please get in touch.`,
    imageSrc: './assets/images/user/character-illustration.svg',
    imageAlt: 'Construction workers pointing at infographics',
    isCloseButtonVisible: false,
  },
  [SubscriptionStatuses.Paused]: {
    title: 'Your Dashpivot subscription has been paused',
    subtitle: `Your Dashpivot subscription has been paused. But we've saved all your work, making it easy to resume whenever you're ready. To <strong>resume your subscription</strong>, please get in touch.`,
    imageSrc: './assets/images/user/trial-expiration-background.svg',
    imageAlt: 'Clock image',
    isCloseButtonVisible: false,
  },
  [SubscriptionStatuses.OverdueInvoice]: {
    title: 'You’ve temporarily lost access',
    subtitle: `You’ve temporarily lost access to this workspace as there are overdue invoices. Don't worry, it's quick and easy to get back up and running. Please <strong>contact your billing admin</strong> to get any outstanding invoices paid.`,
    imageSrc: './assets/images/user/lock.svg',
    imageAlt: 'lock',
    isCloseButtonVisible: false,
  },
};
