import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of, switchMap, throwError } from 'rxjs';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { environment } from 'environments/environment';

import { FirewallIssueComponent } from './firewall-issue/firewall-issue.component';
import { DebugErrorAngular } from '../shared/model/debug-error-angular.model';
import { ErrorHandler } from '../shared/service/error-handler.service';
import { TeamService } from '../shared/service/team.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly DASHPIVOT_UNREACHABLE = 'DASHPIVOT_UNREACHABLE';
  private readonly GLOBAL_API_UNREACHABLE = 'GLOBAL_API_UNREACHABLE';

  private isDashpivotApiCalled: boolean;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly modal: NgbModal,
    private readonly segmentService: SegmentService,
    private readonly errorHandlerService: ErrorHandler,
    private readonly teamService: TeamService,
  ) {}

  /**
   * Testing network connection by pinging the Global API and Dashpivot API:
   1. Ping the Global API if it is accessible, if yes continue with usual operation
   2. If it failed , ping dashpivot.com. If the ping failed show toastr error
   3. If it succeeded, ping the Global API
   4. If the Global API is still unreachable on the 2nd attempt, show a non-dismissible modal
   */
  testNetwork(isAuthenticated = false) {
    this.isDashpivotApiCalled = false;

    this.pingGlobalApi()
      .pipe(
        catchError(() => {
          // First Global API call failed, try Dashpivot API
          return this.pingDashpivotApi();
        }),
        switchMap(() => {
          // condition is to avoid calling Global API twice if it succeeded in the first attempt
          if (this.isDashpivotApiCalled) {
            return this.pingGlobalApi();
          }

          return of(null);
        }),
        catchError((error) => {
          if (error.message === this.DASHPIVOT_UNREACHABLE) {
            this.errorHandlerService.showDashpivotApiUnreachableToastr(error);
          }

          if (error.message === this.GLOBAL_API_UNREACHABLE) {
            this.showGlobalApiUnreachableModal(error);
            this.raiseFirewallErrorEvent(isAuthenticated);
          }

          throw error;
        }),
      )
      .subscribe({
        next: () => {
          // Global API is reachable
        },
        error: (err) => {
          // eslint-disable-next-line no-console
          console.error('An error occurred:', err.message);
        },
      });
  }

  private pingGlobalApi() {
    return this.httpClient
      .get(`${environment.global.apiUrl}/user/server-ping`, { responseType: 'text' })
      .pipe(
        catchError(() => {
          return throwError(() => new Error(this.GLOBAL_API_UNREACHABLE));
        }),
      );
  }

  private pingDashpivotApi() {
    this.isDashpivotApiCalled = true;
    return this.httpClient.get(`${environment.apiUrl}server-ping`).pipe(
      catchError(() => {
        return throwError(() => new Error(this.DASHPIVOT_UNREACHABLE));
      }),
    );
  }

  private showGlobalApiUnreachableModal(error: any) {
    this.modal.open(FirewallIssueComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
      keyboard: false,
      backdrop: 'static',
    });
    const context = { 'Sitemate Global API Status': DebugErrorAngular.ApiUnreachable };
    Bugsnag.leaveBreadcrumb('Error on Global API Service', { context });
    Bugsnag.notify(error, (event) => {
      event.addMetadata('metadata', context);
      event.addMetadata('extraMetadata', { context });
    });
  }

  private raiseFirewallErrorEvent(isAuthenticated: boolean) {
    this.teamService.getCurrentTeam$().subscribe((team) => {
      if (!isAuthenticated) {
        this.raiseEvent();
      }

      if (isAuthenticated && team.companyMetadata) {
        const { name: workspaceName, workspaceId, accountId: sitemateStartAccountId } = team.companyMetadata;
        this.raiseEvent({
          workspaceName,
          workspaceId,
          sitemateStartAccountId,
        });
      }
    });
  }

  private raiseEvent(metadata?: any) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FirewallErrorOccurred, metadata),
      this.segmentService,
    );
  }
}
