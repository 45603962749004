/* eslint-disable max-lines */
import { Platform } from '@angular/cdk/platform';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Observable, Subject, combineLatest, firstValueFrom, forkJoin } from 'rxjs';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';

import {
  Company,
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  ItemStates,
  PathService,
} from '@site-mate/dashpivot-shared-library';

import { TemplateDeploymentService } from 'app/apps/template-deployment.service';
import { FolderSelectionService } from 'app/folders/folder-selection.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListDeploymentService } from 'app/lists/list-deployment.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { NoWorkspaceFoundService } from 'app/org/no-workspace-found/no-workspace-found.service';
import { OrgService } from 'app/org/org.service';
import { WorkspaceLoaderService } from 'app/org/workspace-loader/workspace-loader.service';
import { WorkspaceSwitcherService } from 'app/org/workspace-switcher/workspace-switcher.service';
import { SegmentService } from 'app/segment/segment.service';
import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';
import { FolderWeb } from 'app/shared/model/folder.model';
import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { LastVisitedHomeService } from 'app/shared/service/last-visited-home.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { SearchInputComponent } from 'app/ui-components/search-input/search-input.component';
import { StateToggleComponent } from 'app/ui-components/state-toggle/state-toggle.component';
import { UIComponentsTheme } from 'app/ui-components/ui-components-theme.enum';

import { FolderNavBarService } from './folder-navbar.service';
import { CompanyWeb } from '../../shared/model/company.model';
import { IUserControllerMap, UserService } from '../../user/user.service';

@Component({
  selector: 'cc-folder-navbar',
  templateUrl: './folder-navbar.component.html',
  styleUrls: ['./folder-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderNavbarComponent implements OnInit, OnDestroy {
  ItemStates = ItemStates;
  currentWorkspace?: Company;
  currentWorkspaceId?: string;
  activeFolder: TeamWeb | ProjectWeb | null = null;
  companyFolderTree: { [key in ItemStates]?: ProjectWeb[] } = {
    [ItemStates.Active]: [],
    [ItemStates.Archived]: [],
  };

  isNavigateToTemplateEditorFlow = false;
  isSafari = false;
  isLoading = true;
  userControllerMap: IUserControllerMap;
  singleWorkspaceWarning: string;
  i18nMessages: { [key: string]: string } = {};
  selectedFolderType: ItemStates;
  isDeployModeActive = false;

  readonly bannerKeys = BannerKeys;
  readonly bannerMetadata = BannerMetadata;
  readonly archivedToggleTheme = UIComponentsTheme.Dark;
  readonly searchInputTheme = UIComponentsTheme.Dark;
  readonly searchDebounce = 250;
  readonly wholeTreeMapKey = 'root';
  private readonly destroy$ = new Subject<void>();
  private unfilteredCompanyFolderTree: { [key in ItemStates]?: ProjectWeb[] } = {
    [ItemStates.Active]: [],
    [ItemStates.Archived]: [],
  };

  private searchValue: string;

  @ViewChild('searchInput') searchInput: SearchInputComponent;
  @ViewChild('stateToggle') stateToggle: StateToggleComponent;

  constructor(
    private readonly folderNavBarService: FolderNavBarService,
    private readonly routeParamsService: RouteParamsService,
    private readonly router: Router,
    private readonly segmentService: SegmentService,
    private readonly userService: UserService,
    private readonly i18nService: TmpI18NService,
    private readonly platform: Platform,
    private readonly lastVisitedHomeService: LastVisitedHomeService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly workspaceSwitcherService: WorkspaceSwitcherService,
    private readonly noWorkspaceFoundService: NoWorkspaceFoundService,
    private readonly pathService: PathService,
    private readonly teamService: TeamService,
    private readonly listDeploymentService: ListDeploymentService,
    private readonly templateDeploymentService: TemplateDeploymentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly folderSelectionService: FolderSelectionService,
    private readonly workspaceLoaderService: WorkspaceLoaderService,
    private readonly orgService: OrgService,
    private readonly toastrService: ToastrService,
    private readonly errorHandler: ErrorHandler,
  ) {}

  ngOnInit(): void {
    const navigateOnSignUpToFormModal = this.activatedRoute.snapshot.queryParamMap.get(
      'navigateOnSignUpToFormModal',
    );
    this.isNavigateToTemplateEditorFlow = navigateOnSignUpToFormModal === 'true';

    this.setupI18nMessages();

    this.userControllerMap = this.userService.getUserControllerMap();
    this.isSafari = this.platform.SAFARI;

    this.setActiveCompany();
    this.setActiveFolderObserver();
    this.setSearchListener();

    combineLatest([
      this.listDeploymentService.activeDeployMode$,
      this.templateDeploymentService.activeDeployMode$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([listDeployMode, templateDeployMode]) => {
        this.isDeployModeActive = Boolean(listDeployMode) || Boolean(templateDeployMode);
        this.changeDetectorRef.markForCheck();
      });
  }

  private setActiveFolderObserver() {
    this.navigationService.activeFolder
      .pipe(
        filter((folder) => !isEmpty(folder)),
        takeUntil(this.destroy$),
      )
      .subscribe((folder) => {
        this.activeFolder = folder;
        this.selectedFolderType =
          folder.state === ItemStates.Archived ? ItemStates.Archived : ItemStates.Active;
        this.navigationService.displayArchiveToastIfNeeded(folder);
        this.setStateInToggleWhenChangingActiveFolders();

        const companyId = folder.isCompany ? folder._id : folder.companyId;

        if (this.currentWorkspaceId !== companyId) {
          this.currentWorkspaceId = companyId;
          this.clearCompanyFolderTree();
          this.switchFolderType(this.selectedFolderType);
        } else if (folder.state === ItemStates.Deleted) {
          this.updateFolders();
        }
      });
  }

  private setSearchListener() {
    this.folderNavBarService.searchValue.pipe(takeUntil(this.destroy$)).subscribe(this.filterTree.bind(this));
  }

  private setStateInToggleWhenChangingActiveFolders() {
    if (!this.stateToggle?.items) {
      return;
    }

    this.stateToggle.selectState(this.selectedFolderType ?? ItemStates.Active);
    this.changeDetectorRef.markForCheck();
  }

  private clearCompanyFolderTree() {
    this.unfilteredCompanyFolderTree = { active: [], archived: [] };
    this.resetTree();
  }

  private setActiveCompany() {
    this.routeParamsService
      .get('company')
      .pipe(
        takeUntil(this.destroy$),
        filter((company) => !!company),
      )
      .subscribe((company) => {
        // Note: We only use this in the header to load name + logo
        // We also pass it to cc-folders, but want to remove it and instead pass just the currentWorkspaceId
        this.currentWorkspace = company;
        this.changeDetectorRef.markForCheck();
      });
  }

  loadFolderTree() {
    this.loadSpecificFolderTree(this.selectedFolderType).subscribe(() => {
      this.isLoading = false;
      this.workspaceLoaderService.setSideNavLoaded(true);
      this.resetOrFilterTree();
    });
  }

  private loadSpecificFolderTree(folderType: ItemStates): Observable<any> {
    return this.getCompanyTree(folderType).pipe(
      tap((folderTree) => {
        this.unfilteredCompanyFolderTree[folderType] = folderTree;
      }),
      takeUntil(this.destroy$),
      // @ts-expect-error implicitly has an 'any' type.
      catchError(this.handleTreeError.bind(this)),
    );
  }

  updateFolders(currentTeamHadTheStateUpdated?: boolean) {
    forkJoin([
      this.loadSpecificFolderTree(ItemStates.Active),
      this.loadSpecificFolderTree(ItemStates.Archived),
    ]).subscribe({
      next: () => {
        this.isLoading = false;
        if (currentTeamHadTheStateUpdated) {
          this.navigationService.activeFolder.pipe(take(1)).subscribe((folder) => {
            this.selectedFolderType =
              folder.state === ItemStates.Archived ? ItemStates.Archived : ItemStates.Active;
            this.setStateInToggleWhenChangingActiveFolders();
            this.switchFolderType(this.selectedFolderType);
          });
        } else {
          this.resetOrFilterTree();
        }
      },
    });
  }

  private handleTreeError(err: HttpErrorResponse) {
    const badRequestError = 400;
    const forbiddenError = 403;

    if ([badRequestError, forbiddenError].includes(err.status)) {
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
      if (!this.isNavigateToTemplateEditorFlow) {
        void this.lastVisitedHomeService.goToFirstPossibleTeam(this.router, true).toPromise();
      }
    }
  }

  async addTeam(projectId: string, name: string) {
    try {
      const team = await firstValueFrom(this.orgService.addTeam(projectId, name.trim()));
      team.isNew = true;

      this.toastrService.successByKey('folderAdded');

      if (this.userControllerMap?.teams) {
        this.userControllerMap.teams[team._id] = true;
      }

      await this.navigateToTeam(team._id);

      this.loadFolderTree();

      return team._id;
    } catch (error) {
      return this.errorHandler.handle(error);
    }
  }

  async addProject(workspaceId: string, name: string) {
    try {
      const project = await firstValueFrom(this.orgService.addProject(workspaceId, name.trim()));

      this.toastrService.successByKey('folderAdded');

      if (this.userControllerMap?.projects) {
        this.userControllerMap.projects[project._id] = true;
      }

      await firstValueFrom(this.userService.updateCurrentUser());

      await this.navigateToProject(project._id);

      this.loadFolderTree();

      return project._id;
    } catch (error) {
      return this.errorHandler.handle(error);
    }
  }

  async renameProject(project: ProjectWeb, name: string) {
    const newName = name.trim();

    if (project.name === newName) {
      return;
    }

    try {
      await firstValueFrom(this.orgService.updateProject(project._id, newName));

      this.toastrService.successByKey('folderRenamed');

      const projectToUpdate = this.unfilteredCompanyFolderTree[this.selectedFolderType].find(
        ({ _id }) => _id === project._id,
      );
      projectToUpdate.name = newName;

      this.sortFolderByName(this.unfilteredCompanyFolderTree[this.selectedFolderType]);

      if (this.teamService.getCurrentTeam()._id === project._id) {
        this.teamService.updateCurrentEntityName(newName);
      }
    } catch (error) {
      this.errorHandler.handle(error);
    }

    this.folderSelectionService.deselectAllFolders();
    this.resetOrFilterTree();
  }

  async renameTeam(team: TeamWeb, name: string) {
    const newName = name.trim();

    if (team.name === newName) {
      return;
    }

    try {
      const updatedTeam = await firstValueFrom(this.orgService.updateTeam(team._id, newName));

      this.toastrService.successByKey('folderRenamed');

      const projectId = this.pathService.extractFolderIds(team.path)[1];
      const unfilteredProject = this.unfilteredCompanyFolderTree[this.selectedFolderType].find(
        ({ _id }) => _id === projectId,
      );
      const teamToUpdateIndex = unfilteredProject.teams.findIndex(({ _id }) => _id === team._id);
      unfilteredProject.teams[teamToUpdateIndex] = updatedTeam;

      this.sortFolderByName(unfilteredProject.teams);

      if (this.teamService.getCurrentTeam()._id === team._id) {
        this.teamService.updateCurrentEntityName(newName);
      }
    } catch (error) {
      this.errorHandler.handle(error);
    }

    this.folderSelectionService.deselectAllFolders();
    this.resetOrFilterTree();
  }

  async openWorkspaceSwitcher() {
    this.userService
      .updateCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.userControllerMap = this.userService.getUserControllerMap();
      });
    const { event, context } = await this.workspaceSwitcherService.openWorkspaceSwitcher();
    const handlers: Record<typeof event, any> = {
      addNewCompany: this.handleAddNewCompany.bind(this),
      navigatedToExistingCompany: this.handleNavigateToCompany.bind(this),
      dismissed: this.handleDismissed.bind(this),
      noWorkspaceFound: this.noWorkspaceFoundService.openModal,
    };
    handlers[event](context);
  }

  private handleAddNewCompany(newCompany: CompanyWeb) {
    this.searchInput.clearInput();

    if (!this.userControllerMap.isAdmin) {
      this.userControllerMap.companies[newCompany._id] = true;
    }
  }

  private handleNavigateToCompany() {
    this.searchInput.clearInput();
  }

  private handleDismissed() {
    // Do nothing
  }

  async navigateToOrganisation(orgId: string) {
    await this.navigationService.navigateToOrganisation(orgId);
    this.searchInput.clearInput();

    this.notifyNavigationClicked('Organisation', orgId);
  }

  async navigateToProject(projectId: string) {
    await this.navigationService.navigateToProject(projectId);

    this.notifyNavigationClicked('Project', projectId);
  }

  async navigateToTeam(teamId: string) {
    await this.navigationService.navigateToTeam(teamId);

    this.notifyNavigationClicked('Team', teamId);
  }

  sortFolderByName(folders: (TeamWeb | ProjectWeb)[]) {
    folders.sort((firstFolder, secondFolder) =>
      firstFolder.name.toLowerCase().localeCompare(secondFolder.name.toLowerCase()),
    );
  }

  private notifyNavigationClicked(hierarchy: string, id: string) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.NavigationClicked, {
        Context: 'Nav Bar',
        Hierarchy: hierarchy,
        id,
      }),
      this.segmentService,
    );
  }

  updateSearchParam(searchValue: string) {
    this.folderNavBarService.searchValue.next(searchValue);
  }

  resetOrFilterTree() {
    if (this.searchValue) {
      this.filterTree(this.searchValue);
    } else {
      this.resetTree();
    }
  }

  filterTree(searchValue: string) {
    this.searchValue = searchValue;

    if (!searchValue) {
      this.resetTree();
      return;
    }

    const folderTree = cloneDeep(this.unfilteredCompanyFolderTree[this.selectedFolderType]);
    this.companyFolderTree[this.selectedFolderType] = folderTree.filter(this.projectMatchesSearch.bind(this));
    this.changeDetectorRef.markForCheck();
  }

  resetTree() {
    this.companyFolderTree.active = this.unfilteredCompanyFolderTree.active.slice();
    this.companyFolderTree.archived = this.unfilteredCompanyFolderTree.archived.slice();

    if (!this.selectedFolderType && this.stateToggle?.items) {
      this.selectedFolderType = ItemStates.Active;
      this.stateToggle.selectState(this.selectedFolderType);
    }
    this.changeDetectorRef.markForCheck();
  }

  private projectMatchesSearch(project: ProjectWeb) {
    if (this.nameMatchesSearch(project)) {
      return true;
    }
    project.teams = project.teams.filter(this.teamMatchesSearch.bind(this));

    return project.teams.length !== 0;
  }

  private teamMatchesSearch(team: TeamWeb) {
    return this.nameMatchesSearch(team);
  }

  private nameMatchesSearch(folder: FolderWeb) {
    return folder?.name?.toLowerCase().includes(this.searchValue?.toLowerCase());
  }

  switchFolderType(folderType: ItemStates) {
    this.isLoading = true;
    this.selectedFolderType = folderType;
    // NOTE: This reloads a menu if it's empty
    if (!this.companyFolderTree[folderType].length) {
      this.loadFolderTree();
    } else {
      this.isLoading = false;
      this.resetOrFilterTree();
    }
  }

  getCompanyTree(folderState: ItemStates) {
    return this.folderNavBarService
      .getCompanyTree(this.currentWorkspaceId, folderState)
      .pipe(take(1), takeUntil(this.destroy$));
  }

  setupI18nMessages() {
    const messages = ['singleWorkspaceWarning', 'HomeFolderInArchivedViewWarning'];

    messages.forEach((message) => {
      this.i18nMessages[message] = this.i18nService.getMessage(message);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
