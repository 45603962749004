import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from 'app/auth/auth.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly timeout = 2000;

  constructor(
    private readonly authService: AuthService,
    private readonly toastr: ToastrService,
    private readonly i18nService: TmpI18NService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const forceLogoutForSSOError = error.status === 401 && error.error?.metadata?.ssoEnabled;

        if (forceLogoutForSSOError) {
          const message = this.i18nService.getMessage('unauthorizedSSO');

          this.toastr.error(message);

          setTimeout(() => {
            void this.authService.logout();
          }, this.timeout);
        }

        return throwError({ ...error, hideToastr: forceLogoutForSSOError });
      }),
    );
  }
}
