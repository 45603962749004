/* eslint-disable max-lines */
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NgForm,
  NgModel,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { startCase } from 'lodash-es';
import moment from 'moment-timezone';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  IUser,
  UserTypes,
} from '@site-mate/dashpivot-shared-library';

import { AuthService } from 'app/auth/auth.service';
import { GlobalApiService } from 'app/global-api/global-api.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { MenuService } from 'app/layout/menu.service';
import { NavService } from 'app/layout/nav.service';
import { NetworkService } from 'app/network/network.service';
import { SegmentService } from 'app/segment/segment.service';
import { IPasswordValidation } from 'app/shared/model/password-validation.model';
import { RegexpMatchers } from 'app/shared/model/regexp-matches.model';
import { TimingMarker } from 'app/shared/model/timing-marker.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';

import { ContinentCodes } from '../shared/continents.enum';
import Countries from '../shared/countries.json';
import { ICountry } from '../shared/country.model';
import MappedTimeZones from '../shared/mappedTimezones.json';
import { SignUpService } from '../sign-up-simplified/sign-up.service';

@Component({
  selector: 'cc-activate-account',
  templateUrl: 'activate-account.component.html',
  styleUrls: ['activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  @ViewChild('formBasicUserInfo')
  formBasicUserInfo: NgForm;

  @ViewChild('mobileInput')
  mobileInput: ElementRef;

  @HostBinding('class.loading')
  loading = true;

  formSignature: FormGroup;
  formPassword: FormGroup;

  signatureNameInfo = { firstName: '', lastName: '' };
  signatureFile: File;

  currentForm = 1;
  totalForms = 3;
  model = {
    firstName: '',
    lastName: '',
    mobile: '',
    termsAndConditions: false,
  };

  activationId: string;
  activated: boolean;
  companyId: string;
  email = '';
  working: boolean;
  AustraliaCode = '61';
  hasValidPhoneNumber = true;
  selectedCountry: string;
  continentCodes = ContinentCodes;
  selectedCountryContinentCode: string;
  userTimeZone: string;
  user: IUser;
  company: { id: string; name: string; logo: string } = { id: '', name: '', logo: '' };
  isContributor = false;
  isSSOSignUp = false;
  isActivatingSSOSignup = false;

  unsubscribe = new Subject<void>();

  passwordValidation: IPasswordValidation;
  combinedPasswordValidationRegex: RegExp;

  urlMap = {
    [this.continentCodes.Europe]: {
      privacyPolicy: 'https://sitemate.com/uk/resources/privacy-policy',
      termsOfService: 'https://sitemate.com/uk/resources/terms-of-service',
    },
    default: {
      privacyPolicy: 'https://sitemate.com/resources/privacy-policy',
      termsOfService: 'https://sitemate.com/resources/terms-of-service',
    },
  };

  readonly countries = Countries;
  readonly doNotAcceptWithoutAlphanumeric = RegexpMatchers.doNotAcceptWithoutAlphanumeric;
  readonly showFormValidators = {
    1: () => this.canShowBasicUserInfoForm,
    2: () => this.canShowSignatureForm,
    3: () => this.canShowPasswordForm,
  };

  public get isMobile() {
    return this.appUtilService.isMobile;
  }

  constructor(
    private readonly appUtilService: AppUtilService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly navService: NavService,
    private readonly menuService: MenuService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService,
    private readonly errorHandler: ErrorHandler,
    private readonly authService: AuthService,
    private readonly timingMarkerService: TimingMarkerService,
    private readonly segmentService: SegmentService,
    private readonly globalApiService: GlobalApiService,
    private readonly companyService: CompanyService,
    private readonly i18nService: TmpI18NService,
    private readonly signUpService: SignUpService,
    private readonly formBuilder: FormBuilder,
    private readonly networkService: NetworkService,
  ) {}

  ngOnInit(): void {
    this.networkService.testNetwork();
    this.populateCountryCode();
    this.navService.hide();
    this.menuService.hide();
    let timingMarker: TimingMarker;
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.UserAccountActivatedByEmail),
      this.segmentService,
    );
    this.combinedPasswordValidationRegex = this.signUpService.getCombinedPasswordValidationRegex();

    this.route.params.subscribe((params) => {
      this.activationId = params.id;
      this.company.id = params.companyId;
      timingMarker = this.timingMarkerService.start(new DashpivotEvent(EventTypes.ActivationIdVerified));
      this.userService.verifyActivationId(this.activationId).subscribe(
        (user) => {
          this.timingMarkerService.finish(timingMarker);
          this.user = user;
          this.email = user.email;
          this.verifySSODomain();

          if (params.companyId) {
            this.getInvitedUserContext();
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.timingMarkerService.finishWithFailure(timingMarker, error);

          if (error?.status === 403) {
            this.toastr.errorByKey('activationIdRevoked');
          } else if (error?.status === 404) {
            this.toastr.errorByKey('activationIdInvalid');
          } else {
            this.errorHandler.handle(error);
          }
          void this.router.navigate(['/']);
        },
      );
    });

    this.formSignature = this.formBuilder.group({
      name: ['', Validators.required],
      signature: ['', Validators.required],
    });

    this.formPassword = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(this.combinedPasswordValidationRegex),
          Validators.minLength(10),
          this.checkPassword(),
        ],
      ],
      confirmPassword: ['', [Validators.required, this.checkConfirmPassword()]],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.navService.show();
    this.menuService.show();
  }

  private verifySSODomain() {
    this.companyService
      .isSSODomain(this.email)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ({ isSSODomain }) => {
          this.isSSOSignUp = isSSODomain;
        },
        (error) => this.errorHandler.handle(error),
      );
  }

  private getInvitedUserContext() {
    this.userService.getInvitedUserContext(this.user.id, this.company.id).subscribe(
      ({ companyName, companyLogo, userType }) => {
        this.company.logo = companyLogo;
        this.company.name = companyName;
        this.isContributor = userType === UserTypes.Contributor;
        this.loading = false;
      },
      (error) => this.errorHandler.handle(error),
    );
  }

  next(form: NgForm | FormGroup) {
    if (form.invalid) {
      return;
    }

    const isSignatureForm = this.currentForm === 2;
    if (isSignatureForm) {
      this.signatureFile = this.appUtilService.dataUrlToFile(
        this.formSignature.controls.signature?.value,
        'signature',
      );
    }

    if (isSignatureForm && this.isSSOSignUp) {
      void this.skipPasswordInputAndActivate(form);
      return;
    }

    if (this.currentForm < this.totalForms) {
      this.currentForm += 1;
    }
  }

  async skipPasswordInputAndActivate(form: NgForm | FormGroup) {
    const placeHolderPassword = 'ssosignup';
    this.formPassword.setValue({ password: placeHolderPassword, confirmPassword: placeHolderPassword });
    this.isActivatingSSOSignup = true;
    await this.activate(form);
  }

  goToForm(formNumber: number) {
    const isDifferentForm = formNumber !== this.currentForm;
    const isNumberValid = formNumber <= this.totalForms && formNumber >= 1;

    if (isDifferentForm && isNumberValid && this.showFormValidators[formNumber]()) {
      this.setFormAsCurrent(formNumber);
    }
  }

  private setFormAsCurrent(index: number) {
    this.currentForm = index;
  }

  get isBasicUserInfoFormValid() {
    return this.formBasicUserInfo?.valid && this.hasValidPhoneNumber;
  }

  get canShowBasicUserInfoForm() {
    return true;
  }

  get canShowSignatureForm() {
    const isForm1Valid = this.currentForm === 1 && this.isBasicUserInfoFormValid;
    return isForm1Valid || this.currentForm === 3;
  }

  get canShowPasswordForm() {
    return this.isBasicUserInfoFormValid && this.formSignature?.valid;
  }

  populateCountryCode(): void {
    this.userTimeZone = (moment as any).tz.guess();
    this.selectedCountry = MappedTimeZones[this.userTimeZone]?.mcode;

    this.populateContinentCode();
  }

  populateContinentCode(): void {
    this.selectedCountryContinentCode = this.userContinent;
  }

  async activate(form: NgForm | FormGroup) {
    if (form.invalid) {
      return;
    }
    try {
      this.working = true;
      const formattedPhoneNumber = parsePhoneNumber(this.model.mobile, this.selectedISOCountryCode).number;

      const activationResponse = await lastValueFrom(
        this.userService.activateUser(this.activationId, {
          firstName: this.model.firstName.trim(),
          lastName: this.model.lastName.trim(),
          mobile: formattedPhoneNumber,
          newPassword: this.formPassword.controls.password.value,
          newPasswordConfirmed: this.formPassword.controls.password.value,
          timezone: (moment as any).tz.guess(),
          continent: this.userContinent,
          isSSOSignUp: this.isSSOSignUp,
        }),
      );

      await this.authService.storeAuthTokens(activationResponse.loginData);

      const { url } = await lastValueFrom(
        this.globalApiService.uploadSignature<{ url: string }>(
          this.signatureFile,
          this.formSignature.controls.signature?.value,
        ),
      );

      const currentUser = this.userService.getCurrentUser();
      this.userService.updateProfile({ ...currentUser, signature: url }).subscribe({
        next: () => {
          this.userService.setCurrentUser({ ...currentUser, signature: url });
        },
        error: (error) => this.errorHandler.handle(error),
      });
    } catch (error) {
      this.working = false;
    } finally {
      this.redirectAfterActivation();
    }
  }

  private checkPassword(): ValidatorFn {
    return (control): ValidationErrors => {
      const { value } = control;
      if (!value) {
        return null;
      }

      const { hasLowercase, hasUppercase, hasNumber, hasMinLength } =
        this.signUpService.validatePassword(value);

      const passwordValid = hasLowercase && hasUppercase && hasNumber && hasMinLength;

      return !passwordValid ? { hasLowercase, hasUppercase, hasNumber, hasMinLength } : null;
    };
  }

  private checkConfirmPassword(): ValidatorFn {
    return (control): ValidationErrors => {
      const { value } = control;
      const unmatched = value !== this.formPassword?.controls.password.value;

      if (!value || !unmatched) {
        return null;
      }

      return { unmatched };
    };
  }

  handleCountrySelect() {
    setTimeout(() => {
      if (this.mobileInput) {
        this.mobileInput.nativeElement.focus();
      }
    }, 0);

    this.validatePhoneNumber();
  }

  get userCountry(): ICountry {
    return this.countries.find((country) => country.value === this.selectedCountry);
  }

  get selectedCountryAreaCode(): string {
    return this.userCountry?.mcode || null;
  }

  get selectedISOCountryCode(): CountryCode {
    return this.userCountry?.ccode as CountryCode;
  }

  get userContinent(): string {
    return this.userCountry?.continentCode;
  }

  get userPhoneNumber() {
    return this.selectedCountryAreaCode + this.model.mobile;
  }

  validatePhoneNumber() {
    this.hasValidPhoneNumber =
      this.model?.mobile &&
      this.selectedISOCountryCode &&
      isValidPhoneNumber(this.userPhoneNumber, this.selectedISOCountryCode);
  }

  hasFormError(form: NgForm, field: NgModel): boolean {
    const requiredValidation = form?.submitted && field.untouched && !field.dirty;

    return (field.invalid && (field.dirty || field.touched)) || requiredValidation;
  }

  setSignatureNameInfo() {
    this.signatureNameInfo = {
      firstName: startCase(this.model.firstName),
      lastName: startCase(this.model.lastName),
    };
  }

  private redirectAfterActivation() {
    if (this.isSSOSignUp) {
      const message = this.i18nService.getMessage('ssoRedirectionMessage');

      this.isActivatingSSOSignup = false;
      this.authService.showRedirectionModal(message);
    } else if (this.isMobile) {
      this.activated = true;
    } else {
      void this.router.navigate(['/']);
    }
  }
}
