import { NgModule } from '@angular/core';

import { AccountSubscriptionService } from '@site-mate/dashpivot-shared-library';

@NgModule({
  providers: [
    {
      provide: AccountSubscriptionService,
      useFactory: () => AccountSubscriptionService.instance,
    },
  ],
})
export class AccountSubscriptionModule {}
