<ng-container *ngIf="!active; else input">
  <button
    (click)="setActive(true)"
    type="button"
    class="sm-flex sm-w-full sm-items-center sm-gap-x-1 sm-rounded sm-p-1 hover:sm-bg-grey-8"
  >
    <i class="cc-icon cc-icon-plus sm-text-default-green" aria-hidden="true"></i>
    <span i18n class="sm-text-sm sm-text-grey-5">{{ label }}</span>
  </button>
</ng-container>
<ng-template #input>
  <cc-folder-inline-input
    #folderInlineInput
    (onBlur)="setActive(false)"
    [label]="label"
    [placeholder]="placeholder"
    [submit]="submit"
    class="sm-block sm-px-1"
  >
    <i left class="cc-icon cc-icon-plus sm-text-default-green" aria-hidden="true"></i>
  </cc-folder-inline-input>
</ng-template>
