<img class="sm-self-center" alt="magnifying-glass" src="/assets/images/magnifying-glass.svg" />

<p class="sm-text-xl sm-font-bold" i18n>This email is already in use</p>

<p class="sm-text-sm sm-font-normal" i18n>We found an existing account with the email {{ email }}.</p>

<p class="sm-text-sm sm-font-normal" i18n>
  Please log back into Dashpivot. If you were recently invited, please check your inbox for an invitation
  link.
</p>

<button i18n type="button" class="button-large button-blue" (click)="onLoginClick()">Login</button>

<button i18n type="button" class="button-regular button-grey-2" (click)="onForgetPasswordClick()">
  I forgot my password
</button>
