<header class="modal-header">
  <h1 class="modal-title" i18n>Switch Workspace</h1>
  <button
    type="button"
    class="modal-close-btn"
    *ngIf="!isCompanyArchived"
    (click)="dismiss()"
    aria-label="Close modal"
  >
    <i class="cc-icon cc-icon-close"></i>
  </button>
</header>
<div class="sm-flex sm-flex-col sm-space-y-2">
  <section class="sm-rounded-t sm-border sm-border-grey-3">
    <div class="search-section sm-rounded-t sm-bg-grey-2">
      <cc-search-input
        [eventDebounceInMilliseconds]="searchDebounce"
        placeholder="Search workspaces..."
        [theme]="searchInputTheme"
        (valueChanged)="filterCompanies($event)"
      ></cc-search-input>
    </div>
    <div class="search-section sm-border-t sm-border-grey-3 sm-bg-grey-1">
      <p class="sm-text-sm sm-font-bold sm-text-default-text" i18n>Workspace</p>
    </div>
    <div class="search-section sm-justify-center sm-border-t sm-border-grey-3" *ngIf="loading">
      <p class="sm-flex sm-items-center sm-space-x-1">
        <i class="cc-icon cc-icon-spinner"></i>
        <span class="sm-font-regular sm-text-sm sm-text-default-text" i18n>Loading...</span>
      </p>
    </div>
    <div class="sm-border-t sm-border-grey-3 sm-p-2" *ngIf="!loading && filtered && !companyList.length">
      <div class="sm-flex sm-h-full sm-flex-col sm-items-center sm-justify-between sm-space-y-2 sm-p-2">
        <img src="/assets/images/user/no-results.svg" alt="No results" />
        <p i18n>Oops! No results</p>
      </div>
    </div>
    <div
      class="search-section sm-justify-center sm-border-t sm-border-grey-3"
      *ngIf="!loading && !filtered && !companyList.length"
    >
      <p class="sm-font-regular sm-text-sm sm-text-default-text" i18n>Please search for a workspace</p>
    </div>
    <ol class="company-list" *ngIf="!loading && companyList.length">
      <li data-test="company-list-entry" *ngFor="let company of companyList">
        <button type="button" class="select-company-button" (click)="selectCompany(company)">
          <img
            class="company-logo"
            [src]="company.logo || defaultLogo"
            [alt]="'Logo for workspace ' + company.name"
          />
          <p data-test="company-name" class="sm-text-sm sm-font-bold sm-text-default-text">
            {{ company.name }}
          </p>
        </button>
      </li>
    </ol>
  </section>
</div>
<section class="sm-flex sm-flex-col sm-gap-4">
  <button
    type="button"
    data-test="create-workspace-button"
    class="submit-button sm-w-fit"
    [class.disabled]="!isCreateWorkspaceEnabled"
    (click)="createCompany()"
    i18n
  >
    Create Workspace
  </button>

  <p class="sm-text-sm sm-text-secondary-text" i18n>
    Only users with Organisation Controller permissions can create additional workspaces.
  </p>

  <cc-banner
    *ngIf="isBannerVisible"
    data-test="visitor-user-plan-type-banner"
    type="warning"
    icon="warning"
    alignment="center"
    width="fit-content"
    [bannerText]="bannerText"
  >
    <button
      type="button"
      data-test="upgrade-plan-button"
      class="sm-text-default-blue sm-underline"
      (click)="openUpgradeToUnlockMoreWorkspace()"
    >
      {{ upgradePlanMessage }}
    </button>
  </cc-banner>
</section>
