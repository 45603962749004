<form [formGroup]="form" (ngSubmit)="onSubmit.emit(form)" class="sm-flex sm-flex-col sm-gap-4">
  <div class="sm-text-center">
    <h5 i18n class="h5 sm-mb-0 sm-text-center">Almost there!</h5>

    <p i18n class="sm-text-sm sm-text-default-text">
      Create and confirm your Dashpivot account password below.
    </p>
  </div>
  <div
    data-test="password-container"
    [class.has-danger]="form.controls.password.invalid && form.controls.password.dirty"
    [class.has-feedback]="form.controls.password.valid"
  >
    <input
      type="password"
      class="form-control"
      placeholder="Password"
      i18n-placeholder="Password"
      formControlName="password"
      name="password"
      data-test="password"
      [class.error-state]="form.controls.password.invalid && form.controls.password.dirty"
    />
    <i *ngIf="form.controls.password.valid" class="input-feedback fa fa-check-circle text-success"></i>
    <cc-password-validation-list
      *ngIf="form.controls.password.invalid && form.controls.password.errors"
      [passwordValidation]="passwordValidation"
    ></cc-password-validation-list>
  </div>
  <div
    data-test="confirm-password-container"
    [class.has-danger]="form.controls.confirmPassword.dirty && form.controls.confirmPassword.invalid"
    [class.has-feedback]="form.controls.confirmPassword.valid"
  >
    <input
      type="password"
      class="form-control"
      placeholder="Confirm password"
      i18n-placeholder="Confirm password"
      formControlName="confirmPassword"
      name="confirmPassword"
      data-test="confirm-password"
      [class.error-state]="form.controls.confirmPassword.dirty && form.controls.confirmPassword.invalid"
    />
    <i *ngIf="form.controls.confirmPassword.valid" class="input-feedback fa fa-check-circle text-success"></i>
    <ng-container *ngIf="form.controls.confirmPassword.dirty">
      <p
        data-test="confirm-password-required-error"
        class="password-feedback-messages sm-pt-1 sm-text-default-green"
        [class.sm-text-default-red]="form.controls.confirmPassword.errors?.required"
        i18n
      >
        <i
          class="cc-icon cc-icon-check sm-text-xl"
          [class.cc-icon-close]="form.controls.confirmPassword.errors?.required"
        ></i>
        Confirm password is required
      </p>
      <p
        data-test="confirm-password-unmatched-error"
        class="password-feedback-messages sm-mt-0 sm-text-default-green"
        [class.sm-text-default-red]="form.controls.confirmPassword.invalid"
        i18n
      >
        <i
          class="cc-icon cc-icon-check sm-text-xl"
          [class.cc-icon-close]="form.controls.confirmPassword.invalid"
        ></i>
        Confirm password matches the password
      </p>
    </ng-container>
  </div>
  <div class="form-group">
    <button
      [disabled]="form.invalid || working"
      type="submit"
      class="button-large button-blue"
      ccSegmentCTA="Activate Button"
      data-test="form-three-activate-button"
    >
      <span i18n>Activate your account</span> <i *ngIf="working" class="cc-icon cc-icon-spinner-fw"></i>
    </button>
  </div>
</form>
