<form
  data-test="form-signature"
  class="form sm-flex sm-flex-col sm-gap-4"
  [formGroup]="form"
  (ngSubmit)="onSubmit.emit(form)"
>
  <div class="sm-text-center">
    <h5 i18n class="h5 sm-mb-0 sm-text-center">Add your signature</h5>

    <p i18n class="sm-m-0 sm-text-sm sm-text-default-text">
      You'll use your signature to sign-off and approve forms in Dashpivot. Don’t worry, you can change your
      signature later in your profile.
    </p>
  </div>
  <cc-digital-font-signature
    [userInfo]="userInfo"
    [options]="signatureCanvasOptions"
    [form]="form"
  ></cc-digital-font-signature>
  <div class="form-group">
    <button
      type="submit"
      class="button-large button-blue"
      i18n
      ccSegmentCTA
      ctaContext="Invited Next 2"
      [disabled]="form.invalid || ssoSignUp.isActivating"
    >
      <span>{{ ssoSignUp.isSSO ? 'Activate' : 'Next' }}</span>
      <i *ngIf="ssoSignUp.isActivating" class="cc-icon cc-icon-spinner-fw"></i>
    </button>
  </div>
</form>
