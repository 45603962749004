/* eslint-disable max-lines */
import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { isEqual } from 'lodash-es';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, Subject, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  AccountSubscriptionService,
  Company,
  EventTypes,
  ItemStates,
  Modules,
  FolderKinds,
  User,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes, SubscriptionStatuses } from '@site-mate/sitemate-global-shared';

import { AddActionComponent } from 'app/actions/add-action-modal.component';
import { TemplatesService } from 'app/apps/templates.service';
import { AuthService } from 'app/auth/auth.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { MenuService } from 'app/layout/menu.service';
import { WorkspaceLoaderService } from 'app/org/workspace-loader/workspace-loader.service';
import { TeamWeb } from 'app/shared/model/team.model';
import { AccountService } from 'app/shared/service/account.service';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { TeamService, IFolderNavigatedState } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';

import { CustomTitleService } from '../../shared/service/custom-title/custom-title.service';
import { NavService } from '../nav.service';

export interface IFolderNavigatedStateWithKindFlags extends IFolderNavigatedState {
  isCompany: boolean;
  isProject: boolean;
  isTeam: boolean;
}
function addFolderKindFlags(folderInfo: IFolderNavigatedState): IFolderNavigatedStateWithKindFlags {
  return {
    ...folderInfo,
    isCompany: folderInfo.kind === FolderKinds.Company,
    isProject: folderInfo.kind === FolderKinds.Project,
    isTeam: folderInfo.kind === FolderKinds.Team,
  };
}

@Component({
  selector: 'cc-top-nav',
  templateUrl: 'top-nav.component.html',
  styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  currentFolder: TeamWeb;
  navigatedFolder: IFolderNavigatedStateWithKindFlags;
  loading: boolean;
  selectedApp;
  show: boolean;
  title: string;
  user: User;
  unSubscribe = new Subject<void>();
  isAccountOnFreeTrial: boolean;
  canUpgradeSubscription: boolean;
  company: Company;
  team: TeamWeb;
  handler;
  passwordChangeLink: string;
  isSSOUser: boolean;
  disabledPasswordChangeTooltipText: string;
  trialExpiration: string;
  subscriptionStatus: SubscriptionStatuses;
  canAddAction: boolean;
  actionTemplateId: string;

  constructor(
    public readonly userService: UserService,
    private readonly teamService: TeamService,
    private readonly menuService: MenuService,
    private readonly location: PlatformLocation,
    private readonly customTitleService: CustomTitleService,
    private readonly navService: NavService,
    private readonly profileService: ProfileService,
    private readonly eventsService: EventsService,
    private readonly authService: AuthService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly companyService: CompanyService,
    private readonly errorHandler: ErrorHandler,
    private readonly i18nService: TmpI18NService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    private readonly workspaceLoaderService: WorkspaceLoaderService,
    private readonly accountSubscriptionService: AccountSubscriptionService,
    private readonly accountService: AccountService,
    private readonly modal: NgbModal,
    private readonly templatesService: TemplatesService,
    protected readonly toastr: ToastrService,
  ) {}

  isProjectControllerOrAbove$ = this.userService.isProjectControllerOrAbove$();
  isCompanyControllerOrAbove$ = this.userService.isCompanyControllerOrAbove$();
  isTeamControllerOrAbove$ = this.userService.isTeamControllerOrAbove$();
  isCompanyMemberOrAbove$ = this.userService.isCompanyMemberOrAbove$();
  isProjectMemberOrAbove$ = this.userService.isProjectMemberOrAbove$();

  ngOnInit() {
    this.location.onPopState(this.handler);

    this.onCompanyUpdate();
    this.onCurrentTeamUpdate();
    this.onAccountUpdated();

    this.menuService.updateSelectedAppEvent.subscribe((app) => {
      this.selectedApp = app;
    });

    this.menuService.loadingEvent.subscribe(() => {
      this.loading = true;
    });

    this.menuService.toggleEvent.subscribe((show) => {
      this.show = show;
    });

    this.teamService.currentTeam.subscribe((team) => {
      this.currentFolder = team;
      this.loading = false;
      this.workspaceLoaderService.setTopNavLoaded(true);
    });

    this.teamService.navigatedFolder$.subscribe((folderInfo) => {
      this.navigatedFolder = addFolderKindFlags(folderInfo);
    });

    this.setCurrentUser();
    this.disabledPasswordChangeTooltipText = this.i18nService.getMessage('disabledChangePasswordForSSO');

    this.loadActionTemplateId();
  }

  private setCurrentUser() {
    this.userService.currentUser
      .pipe(
        takeUntil(this.unSubscribe),
        filter((user) => Object.keys(user).length > 0),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        tap((user) => {
          this.user = user;
        }),
        switchMap((user) => this.verifySSODomain(user.email)),
        switchMap(() => this.getPasswordChangeLink()),
        catchError((error) => {
          this.errorHandler.handle(error);
          return of([]);
        }),
      )
      .subscribe();
  }

  onCurrentTeamUpdate(): void {
    this.teamService.currentTeam
      .pipe(
        takeUntil(this.unSubscribe),
        filter((team: TeamWeb) => !!team.id),
      )
      .subscribe((team: TeamWeb) => {
        this.team = team;
        this.setUpCompany(team.companyMetadata);
        this.canAddAction = team.companyMetadata?.featureAccess?.actions?.enabled;
      });
  }

  onCompanyUpdate(): void {
    this.navService.onCompanyMetadataUpdate.pipe(takeUntil(this.unSubscribe)).subscribe((company: any) => {
      this.team = { ...company, isCompany: true };
      this.setUpCompany(company);
    });
  }

  private setUpCompany(company: Company): void {
    this.company = company;
  }

  onAccountUpdated() {
    this.accountService.currentAccount.pipe(takeUntil(this.unSubscribe)).subscribe((account) => {
      this.canUpgradeSubscription = this.accountSubscriptionService.canUpgradeSubscription();
      // TODO: extracting these properties shouldn't be necessary
      // the logic on why these properties are needed should be in the AccountSubscriptionService
      // however, the logic cc-account-upgrade-widget and  cc-nav-helper-widget are complex
      // this should be addressed in a separate ticket and simplify those components
      this.trialExpiration = account.billing?.dashpivot?.trialEndDate;
      this.subscriptionStatus = account.billing?.dashpivot?.subscriptionStatus;
      this.isAccountOnFreeTrial = account.billing?.dashpivot?.planType === CompanyPlanTypes.FreeTrial;
    });
  }

  onItemClick(tabName: string) {
    const { isCompany, isProject } = this.navigatedFolder;

    // eslint-disable-next-line no-nested-ternary
    const hierarchy = isCompany ? 'Organisation' : isProject ? 'Project' : 'Team';

    this.eventsService.trackEvent(EventTypes.NavigationClicked, { Context: tabName, Hierarchy: hierarchy });
    this.customTitleService.setTitle(`${tabName} - Dashpivot`);
  }

  getOrg() {
    if (this.navigatedFolder.isCompany) {
      return 'companies';
    }
    if (this.navigatedFolder.isProject) {
      return 'projects';
    }
    return 'teams';
  }

  private getPasswordChangeLink() {
    return from(this.oidcSecurityService.getConfiguration()).pipe(
      tap((oidcConfig) => {
        const params = new URLSearchParams({
          client_id: oidcConfig.clientId,
          redirect_uri: oidcConfig.redirectUrl,
          email: this.user.email,
        });

        this.passwordChangeLink = `${oidcConfig.authority}/password/forgot?${params}`;
      }),
    );
  }

  getUsersPageLink() {
    return `/users/${this.getOrg()}/${this.navigatedFolder._id}`;
  }

  getHomePageLink() {
    return `/home/${this.getOrg()}/${this.navigatedFolder._id}/activity`;
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  editProfile() {
    void this.profileService.showEditProfile();
    this.callRecaptchaV3();
  }

  logout() {
    this.eventsService.trackEvent(EventTypes.UserLoggedOut);
    void this.authService.logout();
    this.teamService.clearCurrentTeam();
  }

  onChangePasswordClick(event: MouseEvent) {
    if (this.isSSOUser) {
      event.preventDefault();
    }
  }

  callRecaptchaV3() {
    const editProfile = 'editProfile';
    this.reCaptchaV3Service.execute(editProfile).pipe(takeUntil(this.unSubscribe));
  }

  private verifySSODomain(email: string): Observable<any> {
    return this.companyService.isSSODomain(email).pipe(
      takeUntil(this.unSubscribe),
      tap(({ isSSODomain }) => {
        this.isSSOUser = isSSODomain;
      }),
    );
  }

  openAddActionModal() {
    const modalRef = this.modal.open(AddActionComponent, {
      windowClass: 'actions-modal',
      container: '.form-modal-container',
      animation: false,
    });
    modalRef.componentInstance.templateId = this.actionTemplateId;
  }

  loadActionTemplateId() {
    this.teamService
      .getCurrentTeam$()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((team) => {
        if (!team.companyMetadata) {
          return;
        }

        const companyId = team.companyMetadata._id;
        this.templatesService
          .getOrganisationApps(companyId, ItemStates.Active, Modules.Action)
          .subscribe((templates) => {
            if (!templates.length) {
              if (this.canAddAction) this.toastr.error('No action templates found');
              return;
            }
            this.actionTemplateId = templates[0]._id;
          });
      });
  }
}
