import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { TooltipDirective } from '@site-mate/global-web-ui';

import { IPlanFeatureHighlight } from '../plan-preview-data';

export enum PlanFeatureHighlightIconStatus {
  Valid = 0,
  Warning = 1,
  Disabled = 2,
}

@Component({
  selector: 'cc-plan-preview-feature-highlight',
  templateUrl: './plan-preview-feature-highlight.component.html',
  styleUrls: ['./plan-preview-feature-highlight.component.scss'],
  standalone: true,
  imports: [CommonModule, TooltipDirective],
})
export class PlanPreviewFeatureHighlightComponent {
  @Input() featureHighlight: IPlanFeatureHighlight;

  PlanFeatureHighlightIconStatus = PlanFeatureHighlightIconStatus;

  get iconStatus() {
    let status = PlanFeatureHighlightIconStatus.Valid;

    if (this.featureHighlight.hasExceededLimit) {
      status = PlanFeatureHighlightIconStatus.Warning;
    } else if (this.featureHighlight.isExcluded) {
      status = PlanFeatureHighlightIconStatus.Disabled;
    }

    return status;
  }
}
