import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ButtonComponent } from '@site-mate/global-web-ui';
import { SubscriptionTerms } from '@site-mate/sitemate-global-shared/lib';

export type AllowedSubscriptionTerms = SubscriptionTerms.Monthly | SubscriptionTerms.OneYear;

@Component({
  selector: 'cc-subscription-term-state-toggle',
  templateUrl: './subscription-term-state-toggle.component.html',
  styleUrls: ['./subscription-term-state-toggle.component.scss'],
  standalone: true,
  imports: [ButtonComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubscriptionTermStateToggleComponent),
      multi: true,
    },
  ],
})
export class SubscriptionTermStateToggleComponent implements ControlValueAccessor {
  @Input() subscriptionTerm: AllowedSubscriptionTerms;

  onChange: (value: AllowedSubscriptionTerms) => void = () => {};
  onTouched: () => void = () => {};

  SubscriptionTerms = SubscriptionTerms;

  writeValue(value: AllowedSubscriptionTerms): void {
    this.subscriptionTerm = value;
  }

  registerOnChange(fn: (value: AllowedSubscriptionTerms) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setSubscriptionTerm(subscriptionTerm: AllowedSubscriptionTerms): void {
    this.subscriptionTerm = subscriptionTerm;
    this.onChange(subscriptionTerm);
  }
}
