<img class="sm-self-center" alt="magnifying-glass" src="/assets/images/magnifying-glass.svg" />

<p class="sm-flex-nowrap sm-text-xl sm-font-bold" i18n>A workspace is already using &#64;{{ domain }}</p>

<p class="sm-text-sm sm-font-normal" i18n>
  A Dashpivot workspace already exists using the <strong>&#64;{{ domain }}</strong> domain. By signing up you
  will not gain access to any existing workspaces.
</p>

<p class="sm-text-sm sm-font-normal" i18n>Are you sure you want to continue?</p>

<button type="button" class="button-large button-blue" (click)="onCreateWorkspaceClick()">
  Yes, I want my own workspace
</button>

<button type="button" class="button-regular button-grey-2" (click)="onJoinExistingWorkspaceClick()">
  No, I need to join an existing workspace
</button>
