import { DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ButtonComponent, ModalComponent } from '@site-mate/global-web-ui';
import { CompanyPlanTypes, PaymentCurrencyTypes, SubscriptionTerms } from '@site-mate/sitemate-global-shared';

import { AccountService } from 'app/shared/service/account.service';

import {
  ISelectLicensesStepForm,
  SelectLicensesStepComponent,
} from './select-licenses-step/select-licenses-step.component';
import { SelectPlanStepComponent } from './select-plan-step/select-plan-step.component';
import { AllowedSubscriptionTerms } from './subscription-term-state-toggle/subscription-term-state-toggle.component';

// TODO: Replace this interface with the request DTO from SMS-837 once that ticket starts
interface IGeneratePaymentLinkRequestDto {
  sitemateStartAccountId: string;
  paymentCurrency: PaymentCurrencyTypes;
  dashpivot?: {
    planType: CompanyPlanTypes;
    subscriptionTerm: SubscriptionTerms.Monthly | SubscriptionTerms.OneYear;
    contributorUsersPaid: number;
    sitematePaidUsersPaid: number;
  };
  redirectSuccessUrl?: string;
}

export interface ISelfServiceCheckoutStep {
  title: string;
}

@Component({
  selector: 'cc-self-service-checkout-stepper',
  templateUrl: './self-service-checkout-stepper.component.html',
  styleUrls: ['./self-service-checkout-stepper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SelectPlanStepComponent,
    SelectLicensesStepComponent,
    ModalComponent,
    ButtonComponent,
  ],
})
export class SelfServiceCheckoutStepperComponent implements OnInit {
  constructor(
    public overlay: Overlay,
    public dialogRef: DialogRef,
    private accountService: AccountService,
  ) {}

  form = new FormGroup({
    planType: new FormControl<CompanyPlanTypes>(null, { nonNullable: true }),
    paymentCurrency: new FormControl<PaymentCurrencyTypes>(null, { nonNullable: true }),
    subscriptionTerm: new FormControl<AllowedSubscriptionTerms>(null, {
      nonNullable: true,
    }),
    dashpivotContributorUsersPaid: new FormControl<number>(null, {
      nonNullable: true,
      validators: [Validators.min(0)],
    }),
    sitematePaidUsersPaid: new FormControl<number>(null, {
      nonNullable: true,
      validators: [Validators.min(0)],
    }),
  });

  currentStep = 0;
  steps: ISelfServiceCheckoutStep[] = [
    {
      title: 'Which plan best suits your needs?',
    },
    {
      title: 'How many licenses do you need?',
    },
  ];

  get currentStepDetails() {
    return this.steps[this.currentStep];
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.overlayRef.updatePositionStrategy(
      this.overlay.position().global().top('130px').centerHorizontally(),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  goPreviousStep() {
    this.currentStep = Math.max(0, this.currentStep - 1);
  }

  goNextStep() {
    this.currentStep = Math.min(this.steps.length - 1, this.currentStep + 1);
  }

  planSelected(plan: CompanyPlanTypes) {
    this.form.controls.planType.setValue(plan);
    this.goNextStep();
  }

  handleSelectLicensesStep(form: ISelectLicensesStepForm) {
    this.form.patchValue({
      paymentCurrency: form.paymentCurrency,
      subscriptionTerm: form.subscriptionTerm,
      dashpivotContributorUsersPaid: form.dashpivotContributorUsersPaid,
      sitematePaidUsersPaid: form.sitematePaidUsersPaid,
    });
    this.submit();
  }

  submit() {
    if (!this.form.valid) {
      return;
    }

    const account = this.accountService.getCurrentAccount();

    const requestDto: IGeneratePaymentLinkRequestDto = {
      sitemateStartAccountId: account._id,
      paymentCurrency: this.form.value.paymentCurrency,
      dashpivot: {
        planType: this.form.value.planType,
        subscriptionTerm: this.form.value.subscriptionTerm,
        contributorUsersPaid: this.form.value.dashpivotContributorUsersPaid,
        sitematePaidUsersPaid: this.form.value.sitematePaidUsersPaid,
      },
    };

    // eslint-disable-next-line no-console
    console.info(JSON.stringify(requestDto, null, 2));
  }
}
