<ngb-alert *ngIf="displayIENotification" [dismissible]="false" type="warning" i18n>
  <i class="cc-icon cc-icon-warning sm-align-middle sm-text-3xl"></i>
  <span class="sm-align-middle">
    We have detected that you use Internet Explorer. For better experience with Dashpivot we suggest you use a
    modern browser such as:
    <a href="https://www.google.com/chrome/">Google Chrome</a>,
    <a href="https://www.mozilla.org/firefox/">Mozilla Firefox</a>, or
    <a href="https://www.microsoft.com/edge">Microsoft Edge</a>
  </span>
</ngb-alert>
<cc-tmp-i18n></cc-tmp-i18n>
<router-outlet></router-outlet>
