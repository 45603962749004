import { Directive, ElementRef, OnInit } from '@angular/core';

import { TmpI18NService } from './tmp-i18n.service';

@Directive({
  selector: '[ccTmpI18N]',
})
export class TmpI18NDirective implements OnInit {
  constructor(
    public elementRef: ElementRef,
    public i18nService: TmpI18NService,
  ) {}

  ngOnInit() {
    const element = this.elementRef.nativeElement;
    for (let index = 0; index < element.children.length; index++) {
      const id = element.children[index].getAttribute('id');
      const value = element.children[index].innerHTML.trim();
      this.i18nService.addMessage(id, value);
    }
  }
}
