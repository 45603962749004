import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { environment } from 'environments/environment';

import { AuthService } from '../auth.service';

@Component({
  selector: 'cc-login',
  template: '',
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly meta: Meta,
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    }

    const encodedParam = this.route.snapshot.queryParamMap.get('url');

    if (encodedParam) {
      this.authService.setCustomAuthRedirectURL(encodedParam);
    }

    this.oidcSecurityService.authorize();
  }
}
