import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbstractSecurityStorage, AuthModule as OidcAuthModule } from 'angular-auth-oidc-client';

import { SharedModule } from 'app/shared/shared.module';
import { environment } from 'environments/environment';

import { AuthLocalStorageService } from './auth-local-storage.service';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';

const routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'oauth-redirect',
    component: LoginRedirectComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    OidcAuthModule.forRoot({
      config: {
        authority: environment.fusionAuth.authority,
        redirectUrl: `${window.location.origin}/oauth-redirect`,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.fusionAuth.clientId,
        scope: 'openid offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 21600,
        logLevel: environment.fusionAuth.logLevel,
        triggerAuthorizationResultEvent: true,
        triggerRefreshWhenIdTokenExpired: false,
        disableIatOffsetValidation: true,
      },
    }),
  ],
  exports: [OidcAuthModule],
  declarations: [LoginRedirectComponent, LoginComponent],
  providers: [AuthService, { provide: AbstractSecurityStorage, useClass: AuthLocalStorageService }],
})
export class AuthModule {}
