<img
  src="./assets/images/activate-account/celebrate.svg"
  alt="people celebrating your account activation"
  id="celebration-image"
/>
<h5 class="h5 sm-text-center sm-font-bold" id="welcome-message" i18n>Welcome to Dashpivot</h5>
<span>
  <p class="default-paragraph sm-text-left" i18n>
    Your profile has been created successfully! You can now use the Dashpivot website on your computer, or
    download the Dashpivot app to start taking photos and filling out forms on your mobile and tablet devices.
  </p>
</span>
<a
  *ngIf="isMobile"
  href="https://api.dashpivot.com/mobile/download-mobile-app"
  class="button-regular button-blue"
  i18n
  id="download-mobile-app"
  _target="top"
  rel="noopener"
  ccSegmentCTA="Download App Link"
  ctaContext="Invited User"
>
  Download the Dashpivot app
</a>
