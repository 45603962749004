/* eslint-disable no-console */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import Bugsnag from '@bugsnag/browser';
import { isEqual } from 'lodash-es';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';

import { Project, Company, PathService } from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { AuthService } from 'app/auth/auth.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { NetworkService } from 'app/network/network.service';
import { NoWorkspaceFoundService } from 'app/org/no-workspace-found/no-workspace-found.service';
import { WorkspaceSwitcherService } from 'app/org/workspace-switcher/workspace-switcher.service';
import { TeamWeb } from 'app/shared/model/team.model';
import { AccountService } from 'app/shared/service/account.service';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { FileUploaderModalService } from 'app/shared/service/file-uploader-modal.service';
import { LastVisitedHomeService } from 'app/shared/service/last-visited-home.service';
import { NavigateUserHomeService } from 'app/shared/service/navigate-user-home.service';
import { ProjectService } from 'app/shared/service/project.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { TeamService } from 'app/shared/service/team.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
})
export class SecureComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private templateRoutingCache = new Map<string, Observable<Record<string, string>>>();

  uniqueByUrl = (entry: { url: string }) => entry.url;

  constructor(
    private readonly fileUploaderModalService: FileUploaderModalService,
    private readonly errorHandler: ErrorHandler,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly pathService: PathService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly companyService: CompanyService,
    private readonly projectService: ProjectService,
    private readonly teamService: TeamService,
    private readonly templatesService: TemplatesService,
    private readonly navigateUserHomeService: NavigateUserHomeService,
    private readonly routeParamsService: RouteParamsService,
    private readonly lastVisitedHomeService: LastVisitedHomeService,
    private readonly noWorkspaceFoundService: NoWorkspaceFoundService,
    private readonly workspaceSwitcherService: WorkspaceSwitcherService,
    private readonly authService: AuthService,
    private readonly i18nService: TmpI18NService,
    private readonly networkService: NetworkService,
    private readonly accountService: AccountService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
        switchMap(this.parseRouteParams.bind(this)),
        distinctUntilChanged(isEqual),
      )
      .subscribe(this.setupEntities.bind(this));
  }

  ngOnInit(): void {
    const { queryParamMap } = this.activatedRoute.snapshot;
    const isInSSOSignupFlow = queryParamMap.get('isSSOSignUp') === 'true';
    const forceLogoutFlag = this.authService.getForceLogoutFlagForSSOSignUp();
    const shouldShowSSORedirectionModal = forceLogoutFlag === 'true' && !isInSSOSignupFlow;

    if (shouldShowSSORedirectionModal) {
      const message = this.i18nService.getMessage('ssoRedirectionMessage');
      this.authService.showRedirectionModal(message);
    } else {
      this.handleUserWithoutTeam();
      this.handleModalTriggers();
    }

    this.networkService.testNetwork(true);
  }

  handleUserWithoutTeam() {
    this.userService
      .updateCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          const [teamId] = user.standardUserOf.teams;
          const [projectId] = user.standardUserOf.projects;
          const [companyId] = user.standardUserOf.companies;
          if (!teamId) {
            if (projectId) {
              void this.router.navigate(['/', 'home', 'projects', projectId]);
            } else if (companyId) {
              void this.router.navigate(['/', 'home', 'companies', companyId]);
            } else {
              this.noWorkspaceFoundService.openModal();
            }
          }
        },
        error: (error) => {
          this.errorHandler.handle(error);
        },
      });
  }

  handleModalTriggers() {
    this.activatedRoute.queryParams
      .pipe(
        filter((params) => params.view === 'switchWorkspace' && !this.workspaceSwitcherService.isModalOpen),
      )
      .subscribe(this.workspaceSwitcherService.openWorkspaceSwitcher.bind(this.workspaceSwitcherService));
  }

  private setupEntities(params) {
    this.resolveParameters(params)
      .pipe(
        first(),
        switchMap((data) => {
          return data.company?.accountId
            ? this.accountService.initializeAccount(data.company.accountId).pipe(map(() => data))
            : of(data);
        }),
      )
      .subscribe(
        (data) => {
          if (data.team) {
            void this.teamService.setCurrentTeam(data.team, data.project, data.company);
            this.routeParamsService.set('team', data.team);
            this.routeParamsService.set('project', data.project);
            this.routeParamsService.set('company', data.company);
          } else if (data.project) {
            void this.teamService.setCurrentProject(data.project);
            this.routeParamsService.set('team', null);
            this.routeParamsService.set('project', data.project);
            this.routeParamsService.set('company', data.company);
          } else if (data.company) {
            this.teamService.setCurrentCompany(data.company);
            this.routeParamsService.set('team', null);
            this.routeParamsService.set('project', null);
            this.routeParamsService.set('company', data.company);
          } else if (this.shouldSelfResolveParent(this.activatedRoute.snapshot)) {
            // no-op
          } else {
            this.navigateUserHomeService.navigateUserToDefaultHome();
          }
        },
        (error) => {
          this.errorHandler.handleForDebug(error);
          this.notifyOnAccountNotLoaded();
          void this.lastVisitedHomeService.goToFirstPossibleTeam(this.router, true).toPromise();
        },
      );
  }

  private resolveParameters(params): Observable<{ team?: TeamWeb; project?: Project; company: Company }> {
    let company$: Observable<Company> = of(null);
    let project$: Observable<Project> = of(null);
    let team$: Observable<TeamWeb> = of(null);

    if (params.companyId) {
      company$ = this.companyService.getCompany(params.companyId);
    }

    if (params.projectId) {
      project$ = this.projectService.getProject(params.projectId);
      company$ = this.projectService.getCompanyByProjectId(params.projectId);
    }

    if (params.teamId) {
      team$ = this.teamService.getTeam(params.teamId);
      project$ = this.teamService.getProjectByTeamId(params.teamId);
      company$ = this.teamService.getCompanyByTeamId(params.teamId);
    }

    return forkJoin({
      company: company$,
      project: project$,
      team: team$,
    });
  }

  private shouldSelfResolveParent(activatedRoute: ActivatedRouteSnapshot): boolean {
    let { selfResolveParent } = activatedRoute.data;

    const { firstChild } = activatedRoute;

    if (!selfResolveParent && firstChild) {
      selfResolveParent = this.shouldSelfResolveParent(firstChild);
    }

    return !!selfResolveParent;
  }

  private notifyOnAccountNotLoaded() {
    const currentAccount = this.accountService.getCurrentAccount();
    if (!currentAccount?._id) {
      const error = new Error('Account not loaded');
      Bugsnag.notify(error, (event) => {
        event.addMetadata('metadata', { context: 'Error on Account Service' });
      });
    }
  }

  private parseRouteParams() {
    const { companyId, projectId, teamId, templateId } = this.collectRouteParams();
    return templateId ? this.getTemplateRouteParams(templateId) : of({ companyId, projectId, teamId });
  }

  private getTemplateRouteParams(templateId) {
    if (this.templateRoutingCache.has(templateId)) {
      return this.templateRoutingCache.get(templateId);
    }

    return this.templatesService.byId(templateId, { templateLightweight: true }).pipe(
      switchMap((template) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
        const [companyIdFromPath, _, teamFromPath] = this.pathService.extractFolderIds(template.path);
        const result = template.isOrganisationTemplate
          ? { companyId: companyIdFromPath, projectId: null, teamId: null }
          : { companyId: null, projectId: null, teamId: teamFromPath };
        this.templateRoutingCache.set(templateId, of(result));
        return of(result);
      }),
    );
  }

  collectRouteParams(): Record<string, string> {
    let params: Record<string, string> = {};

    const stack: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];

    while (stack.length > 0) {
      const route = stack.pop();
      if (route === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      params = { ...params, ...route.params };

      if (route.children) {
        stack.push(...route.children);
      } else {
        stack.push(undefined);
      }
    }

    return params;
  }

  get currentUploads() {
    return this.fileUploaderModalService.currentInstances;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
