<div class="actions-modal">
  <div class="sm-flex sm-w-full sm-justify-center sm-px-6 sm-pb-4">
    <div class="sm-flex sm-flex-1 sm-align-middle">
      <button
        gw-button
        type="button"
        color="white"
        size="medium"
        leftIcon="delete"
        class="delete-button"
        (click)="deleteAction()"
        [disabled]="!isFormReady || !hasFormBeenChanged()"
        data-test="delete-action-button"
      ></button>
    </div>
    <div
      class="sm-m-auto sm-flex sm-flex-1 sm-justify-center sm-text-2xl sm-font-medium sm-text-default-text"
      ngbAutofocus
      i18n
      data-test="action-modal-title"
    >
      {{ this.form?.counter ? 'Action ' + this.form.counter : 'New Action' }}
    </div>
    <div class="sm-flex sm-flex-1 sm-items-center sm-justify-end sm-gap-6">
      <button
        gw-button
        type="button"
        text="Save Action"
        color="green"
        size="medium"
        data-test="save-action-button"
        (click)="saveAction()"
        [disabled]="!isFormReady || !hasFormBeenChanged()"
      ></button>
      <button
        gw-button
        type="button"
        color="white"
        size="medium"
        data-test="close-action-button"
        leftIcon="close"
        (click)="closeModal()"
      ></button>
    </div>
  </div>
  <div class="sm-h-0.4 sm-w-full sm-bg-grey-3"></div>
  <div class="actions-fields-container sm-overflow-y-scroll sm-px-6">
    <div *ngIf="isFormReady">
      <ng-container *ngFor="let field of form.items">
        <cc-edit-form-field
          *ngIf="!field.dependsOn?.fieldId"
          [field]="field"
          [invalid]="!!field._invalid"
          [isLandscapeMode]="false"
          [instanceName]="template?.instanceName"
          [hierarchy]="template"
          [form]="form"
          [metadataList]="metadataList$"
          [parentId]="parentId"
          (onSign)="onSign($event)"
          (onResetSignature)="onResetSignature($event)"
          (handleApprovalSign)="handleApprovalSign($event)"
          (handleNormalSign)="handleNormalSign($event)"
          (handleManualSign)="handleManualSign($event)"
          (resetFields)="resetFormFields($event)"
        ></cc-edit-form-field>
      </ng-container>
    </div>
    <div *ngIf="!isFormReady" class="sm-flex sm-h-full sm-w-full sm-items-center sm-justify-center">
      <div class="cc-icon cc-icon-spinner-3x"></div>
    </div>
  </div>
</div>
