import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash-es';
import moment from 'moment-timezone';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';
import { AccountTypes, SubscriptionStatuses } from '@site-mate/sitemate-global-shared';

import { WorkspaceSwitcherService } from 'app/org/workspace-switcher/workspace-switcher.service';
import { SegmentService } from 'app/segment/segment.service';
import { AccountService } from 'app/shared/service/account.service';
import { UserService } from 'app/user/user.service';

import { AccountUpgradeButtonContent } from './account-upgrade-button-content.model';
import { AccountUpgradeWidgetContextModel } from './account-upgrade-widget-context.model';
import { AccountUpgradeModalComponent } from '../account-upgrade-modal/account-upgrade-modal.component';

@Component({
  selector: 'cc-account-upgrade-widget',
  templateUrl: './account-upgrade-widget.component.html',
  styleUrls: ['./account-upgrade-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountUpgradeWidgetComponent implements OnChanges {
  readonly sevenDays = 7 * 24 * 60 * 60 * 1000;
  @Input() companyId: string;
  @Input() trialExpiration: Date;
  @Input() companySubscriptionStatus: SubscriptionStatuses;

  readonly subscriptionStatuses = SubscriptionStatuses;
  trialDaysLeft: string;
  trialExpirationPeriod: number;
  expirationInMilliSeconds: number;
  popoverMessage: string;
  activeTrial: boolean;
  upgradeButtonText = 'Trial Ended';
  readonly upgradeAccountEvent = 'Upgrade account';

  constructor(
    private readonly modal: NgbModal,
    private readonly userService: UserService,
    private readonly segmentService: SegmentService,
    private readonly workspaceSwitcherService: WorkspaceSwitcherService,
    private readonly accountService: AccountService,
  ) {}

  private calculateTrialExpirationPeriod() {
    this.expirationInMilliSeconds = new Date(this.trialExpiration).getTime();
    moment.relativeTimeThreshold('d', 31);
    this.trialDaysLeft = moment(this.expirationInMilliSeconds).fromNow();
    this.trialExpirationPeriod = this.expirationInMilliSeconds - new Date().getTime();
    this.activeTrial =
      this.trialExpirationPeriod >= 0 && this.companySubscriptionStatus === SubscriptionStatuses.FreeTrial;

    if (!this.activeTrial) {
      this.upgradeButtonText = AccountUpgradeButtonContent[this.companySubscriptionStatus];
    }

    const expirationDateInIso = moment(this.expirationInMilliSeconds).format('MMM DD, YYYY');
    this.popoverMessage = `Your free trial ends in ${this.trialDaysLeft} on ${expirationDateInIso}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasChanges(changes)) {
      this.calculateTrialExpirationPeriod();
      void this.showUpgradeModalForExpiredAccounts();
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.NavLinkClicked, { Context: this.upgradeAccountEvent }),
        this.segmentService,
      );
    }
  }

  async showAccountUpgrade() {
    if (!this.modal.hasOpenModals()) {
      const modalRef = this.modal.open(AccountUpgradeModalComponent, {
        windowClass: 'modal-500',
        modalDialogClass: 'modal-dialog-top',
        beforeDismiss: () => !this.shouldForceModal(),
      });

      const { title, subtitle, imageSrc, imageAlt, isCloseButtonVisible } =
        this.getContextualModalDataAccordingToSubscription();

      modalRef.componentInstance.title = title;
      modalRef.componentInstance.subtitle = subtitle;
      modalRef.componentInstance.imageSrc = imageSrc;
      modalRef.componentInstance.imageAlt = imageAlt;
      modalRef.componentInstance.isCloseButtonVisible = isCloseButtonVisible;

      try {
        await modalRef.result;
        await this.openWorkspaceSwitcher();
      } catch {
        await this.showUpgradeModalForExpiredAccounts();
      }
    }
  }

  getContextualModalDataAccordingToSubscription() {
    const trialDaysLeft = moment(this.expirationInMilliSeconds).fromNow();
    const expiredAccount =
      this.trialExpirationPeriod <= 0 && this.companySubscriptionStatus === SubscriptionStatuses.FreeTrial;
    const contextualSubscriptionStatus = this.getContextualSubscriptionStatus(trialDaysLeft);

    return expiredAccount
      ? contextualSubscriptionStatus[SubscriptionStatuses.ExpiredTrial]
      : contextualSubscriptionStatus[this.companySubscriptionStatus];
  }

  private getContextualSubscriptionStatus(trialDaysLeft: string) {
    const managedAccountContext = {
      [SubscriptionStatuses.FreeTrial]: {
        title: `Your free trial of Dashpivot ends ${trialDaysLeft}`,
        subtitle: `To upgrade your account or if you have questions about pricing<br /> please contact us via livechat, or email us at <strong>info@sitemate.com</strong>`,
        imageSrc: './assets/images/user/trial-expiration-background.svg',
        imageAlt: 'Clock image',
        isCloseButtonVisible: true,
      },
      ...AccountUpgradeWidgetContextModel,
    };

    const selfServiceAccountContext = {
      ...managedAccountContext,
      [SubscriptionStatuses.FreeTrial]: {
        title: `Your free trial of Dashpivot ends ${trialDaysLeft}`,
        subtitle: `Ready to upgrade? Click the button below to get started, or reach out to us via email at <strong>info@sitemate.com</strong> if you have any questions.`,
        imageSrc: './assets/images/user/trial-expiration-background.svg',
        imageAlt: 'Clock image',
        isCloseButtonVisible: true,
      },
      [SubscriptionStatuses.ExpiredTrial]: {
        title: 'Your 30-day free trial of Dashpivot has ended',
        subtitle: `We hope you had a great experience!<br /> You will need to subscribe to continue usage.<br /><br /> Click the button below to get started, or reach out to us via email at <strong>info@sitemate.com</strong> if you have any questions.`,
        imageSrc: './assets/images/user/trial-expiration-background.svg',
        imageAlt: 'Clock image',
        isCloseButtonVisible: false,
      },
    };

    const contextualSubscriptionStatus = {
      [AccountTypes.ManagedAccount]: managedAccountContext,
      [AccountTypes.SelfServiceAccount]: selfServiceAccountContext,
    };

    const accountType = this.accountService.getCurrentAccount()?.accountType || AccountTypes.ManagedAccount;
    return contextualSubscriptionStatus[accountType];
  }

  async showUpgradeModalForExpiredAccounts() {
    if (this.shouldForceModal()) {
      await this.showAccountUpgrade();
    }
  }

  private shouldForceModal() {
    if (this.userService.isAdmin()) {
      return false;
    }

    const hasTrialEnded = this.trialExpirationPeriod < 0;
    const isFreeTrial = this.companySubscriptionStatus === SubscriptionStatuses.FreeTrial;

    const isExpiredSubscription = [
      SubscriptionStatuses.Paused,
      SubscriptionStatuses.Churned,
      SubscriptionStatuses.OverdueInvoice,
      SubscriptionStatuses.ExpiredTrial,
    ].includes(this.companySubscriptionStatus);

    const shouldShowModal = isExpiredSubscription || (isFreeTrial && hasTrialEnded);
    return shouldShowModal;
  }

  async openWorkspaceSwitcher() {
    const { event, context } = await this.workspaceSwitcherService.openWorkspaceSwitcher();
    if (event === 'dismissed' || context === this.companyId) {
      await this.showUpgradeModalForExpiredAccounts();
    }
  }

  private hasChanges(changes: SimpleChanges) {
    return Object.keys(changes).some(
      (key) => !isEqual(changes[key].previousValue, changes[key].currentValue),
    );
  }
}
