<cc-popover [placement]="'right'" [autoClose]="false" containerClass="sm-h-full">
  <div class="popover-container" content>
    <ng-container *ngIf="activeTemplateDeployMode === null">
      <p *ngIf="activeListDeployMode === deployModes.Management" i18n>
        Select which active projects the selected list should be deployed to.
      </p>
      <p *ngIf="activeListDeployMode === deployModes.BulkDeploy" i18n>
        Select which active projects you want to bulk deploy the {{ listNames.length }} selected lists.
      </p>
      <p *ngIf="activeListDeployMode === deployModes.BulkUndeploy" i18n>
        Select which active projects you want to bulk undeploy the {{ listNames.length }} selected lists.
      </p>
      <p *ngIf="activeListDeployMode === null">
        Select which active projects the selected list should be deployed to.
      </p>
    </ng-container>

    <ng-container *ngIf="activeListDeployMode === null">
      <p *ngIf="activeTemplateDeployMode === deployModes.Management" i18n>
        Select which active teams the selected template should be deployed to.
      </p>
      <p *ngIf="activeTemplateDeployMode === deployModes.BulkDeploy" i18n>
        Select which active teams you want to bulk deploy the {{ templateNames.length }} selected templates.
      </p>
      <p *ngIf="activeTemplateDeployMode === deployModes.BulkUndeploy" i18n>
        Select which active teams you want to bulk undeploy the {{ templateNames.length }} selected templates.
      </p>
      <p *ngIf="activeTemplateDeployMode === null">
        Select which active teams the selected template should be deployed to.
      </p>
    </ng-container>

    <div class="button-container">
      <button class="cancel-button" type="button" (click)="cancelChanges()" [disabled]="applyingChanges">
        Cancel
      </button>
      <button
        class="apply-changes-button"
        [ngClass]="{
          'apply-changes-button-active': haveChangesBeenMade
        }"
        [disabled]="!haveChangesBeenMade || applyingChanges"
        type="button"
        (click)="applyChanges()"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</cc-popover>
