import { Component } from '@angular/core';

import { SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { SignUpService } from '../../sign-up.service';

@Component({
  selector: 'cc-sign-up-used-domain',
  templateUrl: 'sign-up-used-domain.component.html',
  styleUrls: ['../../sign-up.component.scss'],
})
export class SignUpUsedDomainComponent {
  domain: string;

  constructor(private readonly signUpService: SignUpService) {}

  ngOnInit() {
    const model = this.signUpService.getSignUpModel();
    this.domain = model.email.split('@')[1];
  }

  onCreateWorkspaceClick() {
    this.signUpService.setSignUpUserInfoFieldsVisibility(true);
    this.signUpService.setActiveStep(SignUpSteps.UserInfo);
  }

  onJoinExistingWorkspaceClick() {
    this.signUpService.setActiveStep(SignUpSteps.JoinExistingWorkspace);
  }
}
