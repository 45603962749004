import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SegmentModule as NGXSegmentModule } from 'ngx-segment-analytics';
import { ToastrModule } from 'ngx-toastr';

import { CustomNgbDateParserFormatter } from 'app/option/dateformatter.config';
import { CustomNgbDatepickerConfig } from 'app/option/datepicker.config';
import { bugsnagErrorHandlerFactory } from 'app/shared/factory/bugsnag.factory';
import { DOCUMENT, documentFactory } from 'app/shared/factory/document.factory';
import { WINDOW, windowFactory } from 'app/shared/factory/window.factory';
import { environment } from 'environments/environment';

import { AccountSubscriptionModule } from './account-subscription/account-subscription.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { DownloaderModule } from './downloader/downloader.module';
import { I18NModule } from './i18n/i18n.module';
import { IntercomModule } from './intercom/intercom.module';
import { OrgModule } from './org/org.module';
import { RedirectModule } from './redirect/redirect.module';
import { SecureModule } from './secure/secure.module';
import { SegmentModule } from './segment/segment.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { IntroVideoService } from './user/sign-up-simplified/sign-up-steps/sign-up-intro-video-dialog/intro-video.service';
import { UserModule } from './user/user.module';
import { UserService } from './user/user.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 3500,
      preventDuplicates: true,
    }),
    NGXSegmentModule.forRoot({
      apiKey: environment.segment.key || undefined,
      loadOnInitialization: true,
    }),
    LazyLoadImageModule,
    SharedModule,
    I18NModule,
    SegmentModule,
    UserModule,
    OrgModule,
    IntercomModule,
    DownloaderModule,
    SecureModule,
    RedirectModule,
    AuthModule,
    AppRoutingModule,
    RecaptchaV3Module,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
    }),
    AccountSubscriptionModule,
  ],
  providers: [
    UserService,
    IntroVideoService,
    { provide: ErrorHandler, useFactory: bugsnagErrorHandlerFactory },
    { provide: NgbDatepickerConfig, useClass: CustomNgbDatepickerConfig },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    { provide: DOCUMENT, useFactory: documentFactory },
    { provide: WINDOW, useFactory: windowFactory },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaV3SiteKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
