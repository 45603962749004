import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ColorPickerModule } from 'ngx-color-picker';

import { ButtonComponent, ModalComponent } from '@site-mate/global-web-ui';

import { NoAuthGuard } from 'app/auth/no-auth-guard.service';
import { TableModule } from 'app/form-fields/table/table.module';
import { SegmentModule } from 'app/segment/segment.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';

import { AccountUpgradeLimitComponent } from './account-upgrade/account-upgrade-limit/account-upgrade-limit.component';
import { AccountUpgradeModalComponent } from './account-upgrade/account-upgrade-modal/account-upgrade-modal.component';
import { AccountUpgradeWidgetComponent } from './account-upgrade/account-upgrade-widget/account-upgrade-widget.component';
import { ActivateAccountPasswordComponent } from './activate-account/activate-account-password/activate-account-password.component';
import { ActivateAccountSignatureComponent } from './activate-account/activate-account-signature/activate-account-signature.component';
import { ActivateAccountWelcomeComponent } from './activate-account/activate-account-welcome/activate-account-welcome.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { BusinessAttributesSelectorComponent } from './business-attributes-selector/business-attributes-selector.component';
import { VerticalRoleTypeaheadComponent } from './business-attributes-selector/vertical-role-typeahead/vertical-role-typeahead.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditSignatureComponent } from './edit-profile/edit-signature/edit-signature.component';
import { HumanVerificationComponent } from './human-verification/human-verification.component';
import { ProfileService } from './profile.service';
import { SignUpDebugModeComponent } from './sign-up-simplified/helpers/sign-up-debug-mode/sign-up-debug-mode.component';
import { SignUpConstructionBackgroundComponent } from './sign-up-simplified/sign-up-steps/sign-up-background/sign-up-construction-background/sign-up-construction-background.component';
import { SignUpFeaturesComponent } from './sign-up-simplified/sign-up-steps/sign-up-background/sign-up-features/sign-up-features.component';
import { SignUpSidebarBackgroundComponent } from './sign-up-simplified/sign-up-steps/sign-up-background/sign-up-sidebar-background/sign-up-sidebar-background.component';
import { SignUpCompanyBrandingComponent } from './sign-up-simplified/sign-up-steps/sign-up-company-branding/sign-up-company-branding.component';
import { SignUpIntroVideoDialogComponent } from './sign-up-simplified/sign-up-steps/sign-up-intro-video-dialog/sign-up-intro-video-dialog.component';
import { YoutubeVideoPlayerComponent } from './sign-up-simplified/sign-up-steps/sign-up-intro-video-dialog/youtube-video-player/youtube-video-player.component';
import { SignUpJoinExistingWorkspaceComponent } from './sign-up-simplified/sign-up-steps/sign-up-join-existing-workspace/sign-up-join-existing-workspace.component';
import { SignUpUsedDomainComponent } from './sign-up-simplified/sign-up-steps/sign-up-used-domain/sign-up-used-domain.component';
import { SignUpUsedEmailComponent } from './sign-up-simplified/sign-up-steps/sign-up-used-email/sign-up-used-email.component';
import { SignUpUserInfoComponent } from './sign-up-simplified/sign-up-steps/sign-up-user-info/sign-up-user-info.component';
import { SignUpUserPhoneComponent } from './sign-up-simplified/sign-up-steps/sign-up-user-phone/sign-up-user-phone.component';
import { SignUpUserSignatureComponent } from './sign-up-simplified/sign-up-steps/sign-up-user-signature/sign-up-user-signature.component';
import { SignUpWelcomeMobileComponent } from './sign-up-simplified/sign-up-steps/sign-up-welcome-mobile/sign-up-welcome-mobile.component';
import { SignUpComponent } from './sign-up-simplified/sign-up.component';
import { UserService } from './user.service';
import { SharedModule } from '../shared/shared.module';

const userRoutes = [
  {
    path: 'users/activation/:id/company/:companyId',
    component: ActivateAccountComponent,
  },
  {
    path: 'users/activation/:id',
    component: ActivateAccountComponent,
  },
  {
    path: 'signup',
    component: SignUpComponent,
    canActivate: [NoAuthGuard],
  },
];
const userRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(userRoutes);

@NgModule({
  imports: [
    CommonModule,
    DigitOnlyModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    ColorPickerModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SegmentModule,
    VirtualScrollerModule,
    TableModule,
    userRouting,
    GoogleTagManagerModule,
    UIComponentsModule,
    ModalComponent,
    ButtonComponent,
    DialogModule,
    YouTubePlayerModule,
  ],
  declarations: [
    ActivateAccountComponent,
    ActivateAccountPasswordComponent,
    ActivateAccountSignatureComponent,
    ActivateAccountWelcomeComponent,
    SignUpComponent,
    EditProfileComponent,
    EditSignatureComponent,
    AccountUpgradeModalComponent,
    AccountUpgradeWidgetComponent,
    SignUpFeaturesComponent,
    BusinessAttributesSelectorComponent,
    VerticalRoleTypeaheadComponent,
    AccountUpgradeLimitComponent,
    HumanVerificationComponent,
    SignUpDebugModeComponent,
    SignUpUserInfoComponent,
    SignUpJoinExistingWorkspaceComponent,
    SignUpUserPhoneComponent,
    SignUpUserSignatureComponent,
    SignUpCompanyBrandingComponent,
    SignUpWelcomeMobileComponent,
    SignUpConstructionBackgroundComponent,
    SignUpSidebarBackgroundComponent,
    SignUpUsedEmailComponent,
    SignUpUsedDomainComponent,
    SignUpIntroVideoDialogComponent,
    YoutubeVideoPlayerComponent,
  ],
  providers: [UserService, ProfileService, NgbActiveModal],
  exports: [EditProfileComponent, AccountUpgradeWidgetComponent],
})
export class UserModule {}
