/* eslint-disable import/no-import-module-exports */
// this is a temporary solution before angular officially support i18n in ts code. see https://github.com/angular/angular/issues/11405
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cc-tmp-i18n',
  templateUrl: 'tmp-i18n.component.html',
  styleUrls: ['tmp-i18n.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TmpI18NComponent {}
