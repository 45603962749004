<div class="sm-flex sm-flex-col sm-gap-y-2">
  <div class="sm-flex sm-flex-row sm-justify-between">
    <div class="sm-flex sm-flex-row sm-items-center sm-gap-x-1">
      <span class="sm-text-sm">{{ label }}</span>
      <i
        class="cc-icon cc-icon-question tooltip-icon"
        [gwTooltip]="tooltipMessage"
        gwTooltipIndicatorPosition="top"
      >
      </i>
    </div>

    <div class="sm-flex sm-flex-row sm-gap-x-2">
      <button
        data-test="decrement-button"
        [disabled]="value <= 0 || disabled"
        gw-button
        type="button"
        color="grey"
        leftIcon="minus"
        (click)="decrement()"
      ></button>

      <input
        data-test="license-input"
        [disabled]="disabled"
        min="0"
        type="number"
        class="license-input"
        [(ngModel)]="value"
        (input)="onInput($event)"
        (keydown)="onKeydown($event)"
        [ngClass]="{ 'license-input--error': formControl?.errors?.min }"
      />

      <button
        data-test="increment-button"
        [disabled]="disabled"
        gw-button
        type="button"
        color="grey"
        leftIcon="plus"
        (click)="increment()"
      ></button>
    </div>
  </div>
  <div
    data-test="min-error"
    *ngIf="formControl?.errors?.min"
    class="sm-w-full sm-text-center sm-text-xs/[18px] sm-text-default-red"
    i18n
  >
    Number must be equal or higher than the users currently in your account.
  </div>
</div>
