<label #labelRef class="sm-relative sm-flex sm-w-full sm-items-center sm-gap-x-1">
  <ng-content select="[left]"></ng-content>
  <input
    #inputRef
    autofocus
    [placeholder]="placeholder"
    class="sm-leading-sm sm-h-min sm-w-full sm-self-stretch sm-rounded sm-bg-grey-9 sm-p-1 sm-text-sm sm-text-grey-5 sm-outline-none sm-ring-2 sm-ring-inset sm-ring-grey-7 placeholder:sm-text-grey-5 invalid:sm-ring-default-red focus:sm-ring-default-blue focus-visible:sm-ring-default-blue disabled:sm-cursor-not-allowed disabled:sm-text-grey-5/70"
    [class.sm-pr-6]="loading"
    [(ngModel)]="inputValue"
    (keydown.esc)="handleBlur()"
    (keydown.enter)="handleSubmission()"
    (focus)="handleInputFocus()"
    [disabled]="loading"
    [pattern]="inputValueValidator"
  />
  <div class="sm-absolute sm-right-2 sm-top-1/2 sm-grid -sm-translate-y-1/2 sm-place-items-center">
    <span *ngIf="loading" class="cc-icon cc-icon-spinner-lg" data-test="forms-loading-icon"></span>
    <span
      *ngIf="invalidInput"
      gwTooltip="Name should have one or more alphanumeric characters."
      gwTooltipIndicatorPosition="left"
      gwTooltipWindowClass="sm-w-[220px]"
      class="cc-icon cc-icon-warning sm-text-default-red"
      data-test="forms-error-icon"
    ></span>
  </div>
  {{ '' }}
</label>
