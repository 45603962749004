<form
  data-test="user-info-form"
  class="form animate-fade-in"
  name="userInfoForm"
  #userInfoForm="ngForm"
  (ngSubmit)="submitDetails()"
>
  <h1 data-test="heading-text" class="heading-text" i18n *ngIf="isTemplateReferral">
    Use your smart template for free
  </h1>
  <h1 data-test="heading-text" class="heading-text" i18n *ngIf="isNonTemplateReferral">
    Create your free Dashpivot account
  </h1>
  <p class="subtitle-text sm-text-center" i18n>No credit card required</p>

  <div class="form-group">
    <div class="email-container">
      <input
        type="email"
        i18n-placeholder="Email"
        placeholder="Email"
        class="form-control form-input"
        autocomplete="off"
        #email="ngModel"
        id="signup-email"
        name="email"
        ccUniqueEmail
        [applyValidationStyleAndIcons]="false"
        [expectedPattern]="emailRegex"
        (loading)="onUniqueEmailDirectiveLoadingUpdate($event)"
        [class.error-state]="isEmailInvalid && hasEmailError(email)"
        [disabled]="shouldShowUserInfoFields"
        [(ngModel)]="model.email"
        required
        email
      />
    </div>
    <div
      *ngIf="isEmailInvalid && hasEmailError(email)"
      class="sm-mt-1 sm-text-xs sm-text-default-red"
      id="email-danger"
    >
      Please enter a valid email address in the format name&#64;example.com.
    </div>
  </div>

  <div data-test="user-details" [hidden]="!shouldShowUserInfoFields" [@slideInOut]="shouldShowUserInfoFields">
    <div class="form-group">
      <div class="row">
        <div class="pr-1 col">
          <input
            type="text"
            i18n-placeholder="First name"
            placeholder="First name"
            class="form-control form-input sm-capitalize"
            [class.error-state]="firstName.value && firstName.invalid && !firstName.focus"
            #firstName="ngModel"
            name="firstName"
            id="signup-first-name"
            required
            autofocus
            [(ngModel)]="model.firstName"
            [pattern]="doNotAcceptWithoutAlphanumeric"
            (focus)="firstName.focus = true"
            (blur)="firstName.focus = false"
          />
          <div
            class="sm-mt-1 sm-text-xs sm-text-default-red"
            data-test="name-danger"
            i18n
            *ngIf="firstName.value && firstName.invalid && !firstName.focus"
          >
            Required
          </div>
        </div>
        <div class="pl-1 col">
          <input
            type="text"
            i18n-placeholder="Last name"
            placeholder="Last name"
            class="form-control form-input sm-capitalize"
            [class.error-state]="lastName.value && lastName.invalid && !lastName.focus"
            #lastName="ngModel"
            name="lastName"
            id="signup-last-name"
            required
            [(ngModel)]="model.lastName"
            [pattern]="doNotAcceptWithoutAlphanumeric"
            (focus)="lastName.focus = true"
            (blur)="lastName.focus = false"
          />
          <div
            class="sm-mt-1 sm-text-xs sm-text-default-red"
            data-test="name-danger"
            i18n
            *ngIf="lastName.value && lastName.invalid && !lastName.focus"
          >
            Required
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input
        type="text"
        i18n-placeholder="Company name"
        placeholder="Company name"
        #companyName="ngModel"
        class="form-control form-input"
        [class.error-state]="companyName.value && companyName.invalid && !companyName.focus"
        id="signup-company"
        name="company"
        required
        [(ngModel)]="model.companyName"
        [pattern]="doNotAcceptWithoutAlphanumeric"
        (focus)="companyName.focus = true"
        (blur)="companyName.focus = false"
      />

      <div
        class="sm-mt-1 sm-text-xs sm-text-default-red"
        i18n
        *ngIf="companyName.value && companyName.invalid && !companyName.focus"
        data-test="company-danger"
      >
        Company name should have one or more alphanumeric characters.
      </div>
    </div>
    <div class="form-group">
      <cc-tool-tip
        *ngIf="model.isSSOSignUp; else passwordContainer"
        [contentText]="disabledPasswordToolTip"
        containerClass="sm-inline-block sm-w-full"
      >
        <ng-container *ngTemplateOutlet="passwordContainer"></ng-container>
      </cc-tool-tip>

      <ng-template #passwordContainer>
        <div class="password-container" [class.disabled]="model.isSSOSignUp">
          <input
            [type]="showPassword ? 'text' : 'password'"
            i18n-placeholder="Password"
            placeholder="Password"
            class="form-control password form-input"
            id="signup-password"
            name="password"
            [pattern]="combinedPasswordValidationRegex"
            [disabled]="model.isSSOSignUp"
            [required]="!model.isSSOSignUp"
            [(ngModel)]="model.password"
            #password="ngModel"
            autocomplete="current-password"
            (input)="onPasswordChange($event.target.value)"
          />
          <span class="password-toggle" (click)="showPassword = !showPassword">
            <i class="fa fa-eye-slash" [class.fa-eye-slash]="!showPassword" [class.fa-eye]="showPassword"></i>
          </span>
        </div>
        <div *ngIf="!model.isSSOSignUp">
          <cc-password-validation-list
            [passwordValidation]="passwordValidation"
          ></cc-password-validation-list>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="btn-submit-container">
    <button
      #nextButton
      type="submit"
      id="btn-submit-form-1"
      class="button-large button-blue"
      (click)="handleNextClick($event)"
      [ccBusyButton]="isLoading"
      [shouldDisable]="false"
      [disabled]="
        isLoading ||
        email?.errors?.required ||
        isEmailInvalid ||
        (this.shouldShowUserInfoFields && this.userInfoForm?.invalid)
      "
    >
      <span class="sm-text-white" i18n>{{ this.shouldShowUserInfoFields ? 'Get Started' : 'Next' }}</span>
    </button>
  </div>
</form>
