<div class="sm-grid sm-w-[960px] sm-grid-cols-4 sm-rounded sm-border sm-border-grey-3">
  <div
    [attr.data-test]="'plan-container-' + plan.planType"
    *ngFor="let plan of plans"
    class="sm-flex sm-flex-col sm-flex-wrap sm-content-between sm-gap-4 sm-border sm-border-grey-3 sm-p-4"
    [ngClass]="{ 'sm-bg-grey-1': plan.isDisabled }"
  >
    <cc-plan-preview class="sm-flex-grow" [plan]="plan" />

    <div
      [attr.data-test]="'plan-disabled-message-' + plan.planType"
      *ngIf="hasDisabledPlans"
      [ngClass]="{ 'sm-cursor-default sm-opacity-0': !plan.isDisabled }"
      class="sm-rounded-10 sm-bg-default-light-orange sm-p-2 sm-text-xs sm-text-default-dark-orange"
    >
      <span i18n
        >You've exceeded the limits of this plan already. Check your Home Folder > Settings for more
        info.</span
      >
    </div>

    <button
      [attr.data-test]="'select-plan-button-' + plan.planType"
      [disabled]="plan.isDisabled"
      gw-button
      type="button"
      (click)="selectPlan(plan)"
      color="green"
      text="Select"
    ></button>
  </div>
</div>
