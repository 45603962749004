import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { PathService } from '@site-mate/dashpivot-shared-library';
import { ButtonComponent, TooltipDirective } from '@site-mate/global-web-ui';

import { AddActionComponent } from 'app/actions/add-action-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';
import { UserModule } from 'app/user/user.module';

import { FolderInlineCreatorComponent } from './folder-navbar/folder-inline-creator/folder-inline-creator.component';
import { FolderInlineInputComponent } from './folder-navbar/folder-inline-input/folder-inline-input.component';
import { FoldersComponent } from './folder-navbar/folders/folders.component';
import { MenuService } from './menu.service';
import { NavHelperWidgetComponent } from './nav-helper-widget/nav-helper-widget.component';
import { NavService } from './nav.service';
import { TopNavComponent } from './top-nav/top-nav.component';

@NgModule({
  declarations: [
    FoldersComponent,
    TopNavComponent,
    NavHelperWidgetComponent,
    FolderInlineCreatorComponent,
    FolderInlineInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbDropdownModule,
    NgbPopoverModule,
    SharedModule,
    UserModule,
    PlatformModule,
    UIComponentsModule,
    TooltipDirective,
    ButtonComponent,
    AddActionComponent,
  ],
  providers: [MenuService, NavService, PathService],
  exports: [FoldersComponent, TopNavComponent],
})
export class LayoutModule {}
