import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { IPlanFeatureHighlight, IPlanPreview, PlanFeatureHighlightTypes } from '../plan-preview-data';
import { PlanPreviewFeatureHighlightComponent } from '../plan-preview-feature-highlight/plan-preview-feature-highlight.component';

@Component({
  selector: 'cc-plan-preview',
  templateUrl: './plan-preview.component.html',
  styleUrls: ['./plan-preview.component.scss'],
  standalone: true,
  imports: [CommonModule, PlanPreviewFeatureHighlightComponent],
})
export class PlanPreviewComponent implements OnInit {
  @Input() plan: IPlanPreview;

  limits: IPlanFeatureHighlight[] = [];
  inclusions: IPlanFeatureHighlight[] = [];

  ngOnInit(): void {
    this.initFeatureHighlights();
  }

  initFeatureHighlights() {
    this.limits = this.plan.featureHighlights.filter(
      (feature) => feature.type === PlanFeatureHighlightTypes.Limit,
    );
    this.inclusions = this.plan.featureHighlights.filter(
      (feature) => feature.type === PlanFeatureHighlightTypes.Inclusion,
    );
  }
}
