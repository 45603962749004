import { Component, Input } from '@angular/core';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';

@Component({
  selector: 'cc-sign-up-join-existing-workspace',
  templateUrl: './sign-up-join-existing-workspace.component.html',
  styleUrls: ['./sign-up-join-existing-workspace.component.scss'],
})
export class SignUpJoinExistingWorkspaceComponent {
  @Input() domain: string;

  constructor(protected readonly eventsService: EventsService) {}

  ngOnInit(): void {
    this.eventsService.trackEvent(EventTypes.SignUpAverted, { Context: this.domain });
  }
}
