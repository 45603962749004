import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import countryToCurrency from 'country-to-currency';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Subject, takeUntil } from 'rxjs';

import { ButtonComponent } from '@site-mate/global-web-ui';
import {
  CompanyPlanTypes,
  DashpivotPricingPlans,
  LicenseTypes,
  PaymentCurrencyTypes,
  PricingService,
  SubscriptionTerms,
} from '@site-mate/sitemate-global-shared';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { AccountService } from 'app/shared/service/account.service';
import { UserService } from 'app/user/user.service';

import { LicenseInputSpinnerComponent } from '../license-input-spinner/license-input-spinner.component';
import { PlanPreviewComponent } from '../plan-preview/plan-preview.component';
import { IPlanPreview, generatePlanPreviews } from '../plan-preview-data';
import {
  AllowedSubscriptionTerms,
  SubscriptionTermStateToggleComponent,
} from '../subscription-term-state-toggle/subscription-term-state-toggle.component';

export interface ISelectLicensesStepForm {
  paymentCurrency: PaymentCurrencyTypes;
  subscriptionTerm: AllowedSubscriptionTerms;
  dashpivotContributorUsersPaid: number;
  sitematePaidUsersPaid: number;
}

@Component({
  selector: 'cc-select-licenses-step',
  templateUrl: './select-licenses-step.component.html',
  styleUrls: ['./select-licenses-step.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PlanPreviewComponent,
    SubscriptionTermStateToggleComponent,
    LicenseInputSpinnerComponent,
    ButtonComponent,
  ],
})
export class SelectLicensesStepComponent implements OnInit {
  @Input() selectedPlanType: DashpivotPricingPlans;
  @Output() submitForm = new EventEmitter<ISelectLicensesStepForm>();

  pricingService = new PricingService();
  planPreview: IPlanPreview;
  totalCost: number;

  SubscriptionTerms = SubscriptionTerms;

  tooltipMessages = {
    dashpivotContributors: '',
    sitematePaidUsers: '',
  };

  private unsubscribe = new Subject<void>();

  form = new FormGroup({
    paymentCurrency: new FormControl<PaymentCurrencyTypes>(PaymentCurrencyTypes.USD, {
      nonNullable: true,
      validators: [
        (control) => (this.isSupportedCurrency(control.value) ? null : { unsupportedCurrency: true }),
      ],
    }),
    subscriptionTerm: new FormControl<AllowedSubscriptionTerms>(SubscriptionTerms.Monthly, {
      nonNullable: true,
      validators: [
        (control) =>
          [SubscriptionTerms.Monthly, SubscriptionTerms.OneYear].includes(control.value)
            ? null
            : { invalidSubscriptionTerm: true },
      ],
    }),
    dashpivotContributorUsersPaid: new FormControl<number>(0, { nonNullable: true }),
    sitematePaidUsersPaid: new FormControl<number>(0, { nonNullable: true }),
  });

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private i18nService: TmpI18NService,
  ) {}

  ngOnInit() {
    this.initTooltipMessages();
    this.initCalculator();
    this.initCurrency();
    this.initPlanPreview();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initTooltipMessages() {
    this.tooltipMessages = {
      dashpivotContributors: this.i18nService.getMessage(
        'selfCheckoutLicenseStepDashpivotContributorsTooltip',
      ),
      sitematePaidUsers: this.i18nService.getMessage('selfCheckoutLicenseStepSitematePaidUsersTooltip'),
    };
  }

  initPlanPreview() {
    const planPreviews = generatePlanPreviews();
    this.planPreview = planPreviews.find((preview) => preview.planType === this.selectedPlanType);
  }

  initForm() {
    const account = this.accountService.getCurrentAccount();
    const { contributorUsersUsed = 0, sitematePaidUsersUsed = 0 } = account?.billing?.dashpivot || {};

    this.form.patchValue({
      dashpivotContributorUsersPaid: contributorUsersUsed,
      sitematePaidUsersPaid: sitematePaidUsersUsed,
    });

    this.form.controls.dashpivotContributorUsersPaid.setValidators(Validators.min(contributorUsersUsed));
    this.form.controls.sitematePaidUsersPaid.setValidators(Validators.min(sitematePaidUsersUsed));

    if (this.selectedPlanType === CompanyPlanTypes.Standard) {
      this.form.controls.sitematePaidUsersPaid.disable();
    }
  }

  initCurrency() {
    const { mobile: userMobileNumber, continent: userContinent } = this.userService.getCurrentUser() || {};
    let currency: PaymentCurrencyTypes | undefined;

    if (userMobileNumber) {
      currency = this.deriveCurrencyFromMobileNumber(userMobileNumber);
    }

    if (!currency && userContinent) {
      currency = this.deriveCurrencyFromContinent(userContinent);
    }

    this.form.patchValue({ paymentCurrency: currency || PaymentCurrencyTypes.USD });
  }

  deriveCurrencyFromMobileNumber(mobileNumber: string): PaymentCurrencyTypes | undefined {
    try {
      const parsedPhoneNumber = parsePhoneNumberFromString(mobileNumber);
      const derivedCountry = parsedPhoneNumber?.country;
      const currency = countryToCurrency[derivedCountry];
      return this.isSupportedCurrency(currency) ? currency : undefined;
    } catch (e) {
      return undefined;
    }
  }

  deriveCurrencyFromContinent(continent: string): PaymentCurrencyTypes | undefined {
    const continentToCurrencyMapping = {
      OC: PaymentCurrencyTypes.AUD,
      SA: PaymentCurrencyTypes.USD,
      AS: PaymentCurrencyTypes.USD,
      NA: PaymentCurrencyTypes.USD,
      EU: PaymentCurrencyTypes.EUR,
      AF: PaymentCurrencyTypes.ZAR,
    };
    return continentToCurrencyMapping[continent];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isSupportedCurrency(currency: any): boolean {
    return Object.values(PaymentCurrencyTypes).includes(currency);
  }

  initCalculator() {
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      const dashpivotTotalCost = this.calculateDashpivotContributorsTotalCost(value);
      const sitemateAddOnTotalCost = this.calculateSitematePaidUsersAddOnTotalCost(value);

      this.totalCost = dashpivotTotalCost + sitemateAddOnTotalCost;
    });
  }

  calculateDashpivotContributorsTotalCost(value: typeof this.form.value) {
    const dashpivotContributorsPricing = this.pricingService.getPricing({
      amountOfPaidLicenses: value.dashpivotContributorUsersPaid ?? 0,
      licenseType: LicenseTypes.DashpivotContributor,
      currency: value.paymentCurrency,
      planType: this.selectedPlanType,
      subscriptionTerm: value.subscriptionTerm,
    });
    const dashpivotTotalCost = dashpivotContributorsPricing * value.dashpivotContributorUsersPaid;

    return dashpivotTotalCost;
  }

  calculateSitematePaidUsersAddOnTotalCost(value: typeof this.form.value) {
    if (this.selectedPlanType === CompanyPlanTypes.Standard) {
      return 0;
    }

    const sitematePaidUsersPricing = this.pricingService.getPricing({
      amountOfPaidLicenses: value.sitematePaidUsersPaid ?? 0,
      licenseType: LicenseTypes.SitematePaidUser,
      currency: value.paymentCurrency,
      planType: this.selectedPlanType,
      subscriptionTerm: value.subscriptionTerm,
    });
    const sitemateAddOnTotalCost = sitematePaidUsersPricing * value.sitematePaidUsersPaid;

    return sitemateAddOnTotalCost;
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const formValue: ISelectLicensesStepForm = {
      paymentCurrency: this.form.value.paymentCurrency,
      subscriptionTerm: this.form.value.subscriptionTerm,
      dashpivotContributorUsersPaid: this.form.value.dashpivotContributorUsersPaid,
      sitematePaidUsersPaid: this.form.value.sitematePaidUsersPaid,
    };

    this.submitForm.emit(formValue);
  }
}
