import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, iif, of } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Company, AccountSubscriptionService } from '@site-mate/dashpivot-shared-library';
import { IGetWorkspaceSwitcherResponseDto } from '@site-mate/sitemate-global-shared';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { WorkspaceLinkService } from 'app/org/workspace-switcher/workspace-link.service';
import { ExternalLinkService } from 'app/redirect/external-link.service';
import { LocalStorageKeys } from 'app/shared/model/local-storage-keys.enum';
import { FeatureFlagService } from 'app/shared/service/feature-flag.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UIComponentsTheme } from 'app/ui-components/ui-components-theme.enum';
import { AccountUpgradeLimitModalContext } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit-context.enum';
import { AccountUpgradeLimitService } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit.service';
import { IUserControllerMap, UserService } from 'app/user/user.service';

import { IWorkspaceSwitcherItem } from './workspace-switcher-item.interface';
import { WorkspaceSwitcherModalCloseParams } from './workspace-switcher-modal-close-params.interface';
import { WorkspaceLoaderService } from '../workspace-loader/workspace-loader.service';

@Component({
  selector: 'cc-workspace-switcher',
  templateUrl: './workspace-switcher.component.html',
  styleUrls: ['./workspace-switcher.component.scss'],
})
export class WorkspaceSwitcherComponent implements OnDestroy, OnInit {
  companyList: IWorkspaceSwitcherItem[] = [];
  filtered = false;
  loading = true;
  unfilteredCompanyList: IWorkspaceSwitcherItem[] = [];
  userControllerMap: IUserControllerMap;
  isCompanyArchived = true;
  isCreateWorkspaceEnabled = false;
  isBannerVisible: boolean;
  bannerText: string;
  upgradePlanMessage: string;
  activeCompany?: Company;

  private closeParams: WorkspaceSwitcherModalCloseParams;
  readonly searchInputTheme = UIComponentsTheme.White;
  readonly searchDebounce = 250;
  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly folderNavbarService: FolderNavBarService,
    private readonly modal: NgbActiveModal,
    private readonly userService: UserService,
    private readonly routeParamsService: RouteParamsService,
    private readonly toastService: ToastrService,
    private readonly accountUpgradeLimitService: AccountUpgradeLimitService,
    private readonly i18nService: TmpI18NService,
    private readonly externalLinkService: ExternalLinkService,
    private readonly workspaceLinkService: WorkspaceLinkService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly workspaceLoaderService: WorkspaceLoaderService,
    private readonly accountSubscriptionService: AccountSubscriptionService,
  ) {}

  ngOnInit(): void {
    this.userControllerMap = this.userService.getUserControllerMap();
    if (this.userControllerMap.isAdmin) {
      this.resetCompanyList();
      this.loading = false;
    } else {
      this.setUserCompanyList();
    }

    this.routeParamsService
      .get('company')
      .pipe(
        takeUntil(this.unsubscribe),
        filter((company) => !!company),
      )
      .subscribe((company) => {
        this.activeCompany = company;
        this.isCompanyArchived = company.archived;
        this.configureBannerAndSubmitButton();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.complete();
  }

  resetCompanyList() {
    this.companyList = this.userControllerMap.isAdmin ? [] : this.unfilteredCompanyList;
    this.filtered = false;
  }

  dismiss() {
    this.modal.dismiss();
  }

  createCompany() {
    if (!this.isCreateWorkspaceEnabled) {
      return;
    }

    this.closeParams = {
      addNewCompany: true,
      selectedCompanyId: null,
    };
    this.modal.close(this.closeParams);
  }

  selectCompany(company: IWorkspaceSwitcherItem) {
    const companyId = (company as IWorkspaceSwitcherItem).legacyId || (company as Company)._id;
    if (this.globalFeatureFlagEnabled()) {
      this.externalLinkService.redirect(
        this.getWorkspaceSwitcherItemLink(company as IGetWorkspaceSwitcherResponseDto),
      );
    }

    this.closeParams = {
      addNewCompany: false,
      selectedCompanyId: companyId,
    };
    this.modal.close(this.closeParams);

    if (companyId !== this.activeCompany?._id) {
      this.workspaceLoaderService.open();
    }
  }

  filterCompanies(searchTerm: string) {
    this.resetCompanyList();

    const searchTermTrimmed = searchTerm.trim();
    if (!searchTermTrimmed.length) {
      return;
    }

    if (this.userControllerMap.isAdmin) {
      this.superUserSearchCompany(searchTermTrimmed);
    } else {
      this.defaultUserSearchCompany(searchTermTrimmed);
    }
  }

  openUpgradeToUnlockMoreWorkspace() {
    this.accountUpgradeLimitService.openModal(AccountUpgradeLimitModalContext.AdditionalWorkspaces);
  }

  private setUserCompanyList() {
    iif(
      () => this.globalFeatureFlagEnabled(),
      this.folderNavbarService.getAccessibleWorkspaces(),
      this.folderNavbarService.getUserCompanies(),
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((companies) => {
        if (!companies.length) {
          this.loading = false;
          this.modal.close({ noCompaniesFound: true });
          this.toastService.clear();
        }
        this.unfilteredCompanyList = companies as Company[];
        this.resetCompanyList();
        this.loading = false;
      });
  }

  private getWorkspaceSwitcherItemLink(workspace: IGetWorkspaceSwitcherResponseDto) {
    return this.workspaceLinkService.getWorkspaceSwitcherItemLink(workspace);
  }

  private superUserSearchCompany(searchTerm: string) {
    const minSearchCharacters = 2;

    of(searchTerm.length >= minSearchCharacters)
      .pipe(
        filter((isSearchLongEnough) => isSearchLongEnough),
        tap(() => this.loading === true),
        switchMap(() =>
          iif(
            () => this.globalFeatureFlagEnabled(),
            this.folderNavbarService.globalSuperUserWorkspaceSearch(searchTerm),
            this.folderNavbarService.superUserCompanySearch(searchTerm),
          ),
        ),
        takeUntil(this.unsubscribe),
      )
      .subscribe({
        next: (data) => {
          this.companyList = data;
          this.filtered = true;
          this.loading = false;
        },
      });
  }

  private defaultUserSearchCompany(searchTerm: string) {
    this.companyList = this.unfilteredCompanyList.filter((company) =>
      (company as Company).name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    this.filtered = true;
  }

  private configureBannerAndSubmitButton() {
    this.isBannerVisible = !this.accountSubscriptionService.canCreateAdditionalWorkspaces();
    this.isCreateWorkspaceEnabled = this.setCreateWorkspaceButtonEnabled();

    this.bannerText = this.i18nService.getMessage('additionalWorkspaceNotAllowedOnStandard');
    this.upgradePlanMessage = this.i18nService.getMessage('upgradePlanToUnlockFeature');
  }

  private setCreateWorkspaceButtonEnabled() {
    const isAdmin = this.userService.isAdmin();
    const isOrgController = this.userService.isCompanyController();

    if (isAdmin) {
      return true;
    }

    if (this.isBannerVisible) {
      return false;
    }

    return isOrgController;
  }

  private globalFeatureFlagEnabled() {
    return this.featureFlagService.getLocalStorageFlag(LocalStorageKeys.GlobalSearchFeatureFlag) === 'true';
  }
}
