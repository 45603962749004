import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';

import { DateFormatService } from 'app/shared/service/date-format.service';

@Injectable({ providedIn: 'root' })
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  constructor(private readonly dateFormatService: DateFormatService) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      const d = moment(value, this.dateFormatService.getDateFormat());
      if (d.isValid()) {
        return { year: d.year(), month: d.month() + 1, day: d.date() };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date) {
      const result = moment()
        .year(date.year)
        .month(date.month - 1)
        .date(date.day)
        .format(this.dateFormatService.getDateFormat());
      return result;
    }
    return '';
  }
}
