import { Component, Input, OnInit } from '@angular/core';

import SignUpFeatureList from 'app/user/sign-up-simplified/sign-up-steps/sign-up-background/sign-up-features/sign-up-feature-list';

import { SignUpService } from '../../../sign-up.service';

@Component({
  selector: 'cc-sign-up-construction-background',
  templateUrl: './sign-up-construction-background.component.html',
  styleUrls: ['./sign-up-construction-background.component.scss'],
})
export class SignUpConstructionBackgroundComponent implements OnInit {
  readonly signupFeatures = SignUpFeatureList;

  isTemplateReferral: boolean;
  isNonTemplateReferral: boolean;

  @Input() hideLoginText = false;

  constructor(private readonly signUpService: SignUpService) {}

  ngOnInit(): void {
    this.setSignupReferralType();
  }

  setSignupReferralType(): void {
    this.isTemplateReferral = this.signUpService.isTemplateReferral;
    this.isNonTemplateReferral = this.signUpService.isNonTemplateReferral;
  }
}
