import Bugsnag from '@bugsnag/browser';
import BugsnagPerformance from '@bugsnag/browser-performance';

import { environment } from 'environments/environment';

import { DebugErrorAngular } from '../model/debug-error-angular.model';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appVersion = require('../../../../package.json').version;

enum ErrorStatuses {
  UNKNOWN_ERROR = 0,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  INTERNAL_SERVER_ERROR = 500,
}

// This is not reloading, we'll monitor first and then enable reloading
const reloadIfChunkFailedError = (event) => {
  const groupingHash = 'ChunkFailedError';
  const ERROR_FLAG_KEY = 'chunkErrorFlag';
  const message = event?.errors?.[0]?.errorMessage ?? '';

  // Check if the timestamp flag is older than 5 minutes to avoid a reloading loop
  const isExpired = (timestamp) => {
    const twoMinutes = 5 * 60 * 1000;
    const currentTime = new Date().getTime();
    const lastErrorTimestamp = new Date(Number(timestamp)).getTime();

    return currentTime - lastErrorTimestamp > twoMinutes;
  };

  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  if (chunkFailedMessage.test(message)) {
    const lastErrorFlagTimestamp = window.sessionStorage.getItem(ERROR_FLAG_KEY);

    if (!lastErrorFlagTimestamp || isExpired(lastErrorFlagTimestamp)) {
      window.sessionStorage.setItem(ERROR_FLAG_KEY, String(new Date().getTime()));
      event.errors[0].errorClass = `${groupingHash} - reload - ${event.errors[0].errorClass}`;
      event.groupingHash = groupingHash;
      // window.location.reload(); // should be enabled after testing
    } else {
      event.errors[0].errorClass = `${groupingHash} - ${event.errors[0].errorClass}`;
      event.groupingHash = groupingHash;
    }
  }
};

const groupHTTPStatusError = (event, errorStatus: number) => {
  const indexHTML = 'index.html';
  if (!ErrorStatuses[errorStatus]) {
    return;
  }

  let groupingHash = ErrorStatuses[errorStatus];
  if (event?.errors?.[0]?.errorMessage?.includes(indexHTML)) {
    groupingHash = `ReleaseUpdateGuard - ${ErrorStatuses[errorStatus]}`;
  }

  event.errors[0].errorClass = `${groupingHash} - ${event.errors?.[0]?.errorClass}`;
  event.groupingHash = groupingHash;
};

const getStatus = (event) => {
  const possibleStatuses = [
    event?.status,
    event?.originalError?.status,
    event?.originalError?.rejection?.status,
  ];

  return possibleStatuses.find((status) => status !== undefined && status !== null);
};

export const onErrorBeforeNotify = async (event) => {
  const extraMetadata = { isOnline: window.navigator.onLine } as Record<string, string | boolean>;
  const errorStatus = getStatus(event);

  if (errorStatus === ErrorStatuses.UNKNOWN_ERROR) {
    try {
      await fetch(`${environment.apiUrl}server-ping`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      extraMetadata['Dashpivot API Status'] = DebugErrorAngular.ApiReachable;
    } catch (fetchError) {
      extraMetadata['Dashpivot API Status'] = DebugErrorAngular.ApiUnreachable;

      // @ts-expect-error implicitly has an 'any' type
      extraMetadata.apiFetchError = fetchError;
    }
  }

  groupHTTPStatusError(event, errorStatus);
  reloadIfChunkFailedError(event);

  event.addMetadata('extraMetadata', extraMetadata);
};

export function initializeBugsnag() {
  const apiKey = environment.bugsnagKey;

  // eslint-disable-next-line
  console.log(`dashpivot web v${appVersion}`);

  const releaseStage = environment.bugsnagReleaseStage;

  Bugsnag.start({
    apiKey,
    appVersion,
    releaseStage,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    onError: (event) => onErrorBeforeNotify(event),
  });

  BugsnagPerformance.start({
    apiKey,
    appVersion,
    releaseStage,
  });
}
