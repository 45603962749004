import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';

import { FolderInlineInputComponent } from '../folder-inline-input/folder-inline-input.component';

@Component({
  selector: 'cc-folder-inline-creator',
  templateUrl: './folder-inline-creator.component.html',
  styleUrls: [],
})
export class FolderInlineCreatorComponent {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  active = false;

  @Input() parentId: string;
  @Input() label = 'Create/Update';
  @Input() placeholder = 'Name...';
  @Input() submit: (input: string) => Promise<void>;

  @ViewChild('folderInlineInput') folderInlineInput: FolderInlineInputComponent | null = null;

  setActive(value: boolean) {
    this.active = value;
    this.changeDetectorRef.detectChanges();
    if (value) {
      this.folderInlineInput?.focus();
    }
  }

  reset() {
    this.setActive(false);
  }
}
