import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-sign-up-features',
  templateUrl: './sign-up-features.component.html',
  styleUrls: ['./sign-up-features.component.scss'],
})
export class SignUpFeaturesComponent {
  @Input() vector: string;
  @Input() features: string[];
}
