import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth-guard.service';
import { PermissionDeniedComponent } from './shared/component/403.component';
import { PageNotFoundComponent } from './shared/component/404.component';

const routes = [
  {
    path: '404',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '403',
    component: PermissionDeniedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'embed',
    loadChildren: () =>
      import('./apps/embedded-templates-routed.module').then((m) => m.EmbeddedTemplatesRoutedModule),
  },
  {
    path: 'library-apps',
    loadChildren: () =>
      import('./apps/library-templates-routed.module').then((m) => m.LibraryTemplatesRoutedModule),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
