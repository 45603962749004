import { Injectable } from '@angular/core';
import { forkJoin, of, throwError } from 'rxjs';
import { concatMap, defaultIfEmpty } from 'rxjs/operators';

import { ItemStates, PathService } from '@site-mate/dashpivot-shared-library';

import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({ providedIn: 'root' })
export class FoldersService {
  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly pathService: PathService,
  ) {}

  changeItemStates(
    company: string,
    newFolderState: ItemStates,
    selectedFolders: Map<string, TeamWeb | ProjectWeb>,
    folderTree: ProjectWeb[],
  ) {
    const { selectedTeamFolderIds, selectedProjectFolderIds } = this.splitSelectedFolders(selectedFolders);
    if (!selectedTeamFolderIds.size && !selectedProjectFolderIds.length) {
      return throwError(new Error('Empty selection'));
    }

    return newFolderState === ItemStates.Active
      ? this.restoreFolders(company, selectedProjectFolderIds, selectedTeamFolderIds, folderTree)
      : this.deleteOrArchiveFolders(company, newFolderState, selectedProjectFolderIds, selectedTeamFolderIds);
  }

  splitSelectedFolders(selectedFolders: Map<string, TeamWeb | ProjectWeb>) {
    const selectedTeamFolderIds = new Map<string, string[]>();
    const selectedFoldersValues = Array.from(selectedFolders.values());

    const selectedProjectFolderIds = selectedFoldersValues
      .filter(({ kind }) => kind === 'project')
      .map(({ _id }) => _id);

    selectedFoldersValues
      .filter(({ kind }) => kind === 'team')
      .forEach((folder) => {
        const projectId = this.pathService.extractFolderIds(folder.path)[1];

        if (selectedTeamFolderIds.has(projectId)) {
          selectedTeamFolderIds.get(projectId).push(folder._id);
        } else {
          selectedTeamFolderIds.set(projectId, [folder._id]);
        }
      });

    return { selectedTeamFolderIds, selectedProjectFolderIds };
  }

  private restoreFolders(
    company: string,
    selectedProjectFolderIds: string[],
    selectedTeamFolderIds: Map<string, string[]>,
    folderTree: ProjectWeb[],
  ) {
    const uniqueProjectKeys = [...new Set([...selectedProjectFolderIds, ...selectedTeamFolderIds.keys()])];

    const archivedProjectKeys = uniqueProjectKeys.filter((projectId) => {
      const projectInTree = folderTree.find((project) => project._id === projectId);
      return projectInTree?.state === ItemStates.Archived;
    });

    const changeProjectsObservable = this.changeProjectsState(
      company,
      ItemStates.Active,
      archivedProjectKeys,
    );

    return changeProjectsObservable.pipe(
      defaultIfEmpty(null),
      concatMap(() => {
        const selectedTeamFolderEntries = [...selectedTeamFolderIds.entries()];
        const changeTeamsObservable = selectedTeamFolderEntries.map(([projectId, teamIds]) =>
          this.changeTeamsState(projectId, ItemStates.Active, teamIds),
        );
        return forkJoin(changeTeamsObservable);
      }),
    );
  }

  private deleteOrArchiveFolders(
    company: string,
    newFolderState: ItemStates,
    selectedProjectFolderIds: string[],
    selectedTeamFolderIds: Map<string, string[]>,
  ) {
    const selectedTeamFolderEntries = [...selectedTeamFolderIds.entries()];
    const changeTeamsObservable = selectedTeamFolderEntries.map(([projectId, teamIds]) =>
      this.changeTeamsState(projectId, newFolderState, teamIds),
    );
    return forkJoin(changeTeamsObservable).pipe(
      defaultIfEmpty(null),
      concatMap(() => {
        return this.changeProjectsState(company, newFolderState, selectedProjectFolderIds);
      }),
    );
  }

  private changeProjectsState(company: string, newFolderState: ItemStates, selectedFolders: string[]) {
    return selectedFolders.length
      ? this.httpClientService.put(`companies/${company}/folders-state`, {
          state: newFolderState,
          folders: selectedFolders,
        })
      : of(null);
  }

  private changeTeamsState(project: string, newFolderState: ItemStates, selectedFolders: string[]) {
    return selectedFolders.length
      ? this.httpClientService.put(`projects/${project}/folders-state`, {
          state: newFolderState,
          folders: selectedFolders,
        })
      : of(null);
  }
}
