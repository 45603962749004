<div class="nav navbar" *ngIf="show">
  <ng-container *ngIf="!loading && navigatedFolder._id">
    <div class="nav-item">
      <a
        id="activity"
        class="nav-link"
        [routerLink]="getHomePageLink()"
        routerLinkActive="selected"
        (click)="onItemClick('Activity')"
        data-cy="home-link"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-activity" aria-hidden="true"></i>
          <span i18n class="menu-text">Activity</span>
        </div></a
      >
    </div>
    <div class="nav-item">
      <a
        id="users"
        class="nav-link"
        [routerLink]="getUsersPageLink()"
        routerLinkActive="selected"
        (click)="onItemClick('Users')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-users" aria-hidden="true"></i>
          <span i18n class="menu-text">Users</span>
        </div></a
      >
    </div>
    <div
      data-test="settings-team"
      class="nav-item"
      *ngIf="
        navigatedFolder.isTeam && ((isTeamControllerOrAbove$ | async) || (isProjectMemberOrAbove$ | async))
      "
    >
      <a
        id="settings-team"
        class="nav-link settings-link"
        [routerLink]="['/', 'home', 'teams', navigatedFolder._id, 'settings', 'tag-groups']"
        routerLinkActive="selected"
        (click)="onItemClick('Settings')"
        ><div class="nav-button">
          <i class="cc-icon cc-icon-gear" aria-hidden="true"></i>
          <span i18n class="menu-text">Settings</span>
        </div></a
      >
    </div>
    <div
      data-test="settings-project"
      class="nav-item"
      *ngIf="
        navigatedFolder.isProject &&
        ((isProjectControllerOrAbove$ | async) || (isProjectMemberOrAbove$ | async))
      "
    >
      <a
        id="settings-project"
        class="nav-link settings-link"
        [routerLink]="['/', 'home', 'projects', navigatedFolder._id, 'settings']"
        routerLinkActive="selected"
        (click)="onItemClick('Settings')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-gear" aria-hidden="true"></i>
          <span i18n class="menu-text">Settings</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isCompany && (isCompanyMemberOrAbove$ | async)">
      <a
        id="settings-org"
        class="nav-link settings-link"
        [routerLink]="['/', 'home', 'companies', navigatedFolder._id, 'settings']"
        routerLinkActive="selected"
        (click)="onItemClick('Settings')"
        ><div class="nav-button">
          <i class="cc-icon cc-icon-gear" aria-hidden="true"></i>
          <span i18n class="menu-text">Settings</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isProject">
      <a
        id="lists-project"
        class="nav-link photo-link"
        [routerLink]="['/', 'lists', 'projects', navigatedFolder._id]"
        routerLinkActive="selected"
        (click)="onItemClick('Lists')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-list" aria-hidden="true"></i>
          <span i18n class="menu-text">Lists</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isCompany">
      <a
        id="lists-org"
        class="nav-link photo-link"
        [routerLink]="['/', 'lists', 'companies', navigatedFolder._id]"
        routerLinkActive="selected"
        (click)="onItemClick('List Library')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-list" aria-hidden="true"></i>
          <span i18n class="menu-text">List Library</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isTeam">
      <a
        id="photos"
        class="nav-link photo-link"
        [routerLink]="['/', 'photos', 'teams', navigatedFolder._id]"
        routerLinkActive="selected"
        (click)="onItemClick('Photos')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-photo" aria-hidden="true"></i>
          <span i18n class="menu-text">Photos</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isTeam">
      <a
        id="templates-team"
        class="nav-link"
        [routerLink]="['/', 'apps', 'teams', navigatedFolder._id]"
        routerLinkActive
        #isTemplatesSelected="routerLinkActive"
        [class.selected]="isTemplatesSelected.isActive || selectedApp"
        (click)="onItemClick('Templates')"
        ><div class="nav-button">
          <i class="cc-icon cc-icon-form-template" aria-hidden="true"></i>
          <span i18n class="menu-text">Templates</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isCompany">
      <a
        id="templates-org"
        class="nav-link"
        [routerLink]="['/', 'apps', 'companies', navigatedFolder._id]"
        routerLinkActive
        #isTemplatesSelected="routerLinkActive"
        [class.selected]="isTemplatesSelected.isActive || selectedApp"
        (click)="onItemClick('Template Library')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-form-template" aria-hidden="true"></i>
          <span i18n class="menu-text">Template Library</span>
        </div></a
      >
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isTeam">
      <a
        id="analytics"
        class="nav-link analytics-link"
        [routerLink]="['/', 'analytics', 'teams', navigatedFolder._id]"
        routerLinkActive="selected"
        (click)="onItemClick('Analytics')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-analytics" aria-hidden="true"></i>
          <span class="menu-text" i18n>Analytics</span>
        </div>
      </a>
    </div>
    <div class="nav-item" *ngIf="navigatedFolder.isCompany">
      <a
        id="analytics"
        class="nav-link analytics-link"
        [routerLink]="['/', 'analytics', 'companies', navigatedFolder._id]"
        routerLinkActive="selected"
        (click)="onItemClick('Analytics')"
      >
        <div class="nav-button">
          <i class="cc-icon cc-icon-analytics" aria-hidden="true"></i>
          <span class="menu-text" i18n>Analytics</span>
        </div>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && !navigatedFolder._id">
    <div class="nav-item">
      <div class="nav-link">Please select your team from the side bar</div>
    </div>
  </ng-container>
  <div class="nav-item" *ngIf="loading">
    <a class="nav-link" href="">
      <i class="cc-icon cc-icon-spinner-lg" aria-hidden="true"></i>
    </a>
  </div>
  <div class="navbar-collapse">
    <button
      *ngIf="canAddAction"
      gw-button
      type="button"
      text="Add Action"
      color="green"
      size="medium"
      leftIcon="plus"
      data-test="add-action-button"
      class="sm-mr-1"
      (click)="openAddActionModal()"
    ></button>
    <cc-account-upgrade-widget
      *ngIf="canUpgradeSubscription"
      [trialExpiration]="trialExpiration"
      [companyId]="company._id"
      [companySubscriptionStatus]="subscriptionStatus"
    ></cc-account-upgrade-widget>
    <cc-nav-helper-widget *ngIf="company" [companyHasFreePlan]="isAccountOnFreeTrial"></cc-nav-helper-widget>
    <ul class="navbar-nav">
      <li class="nav-item sm-flex sm-h-full sm-items-center">
        <div *ngIf="user" ngbDropdown class="position-relative sm-flex sm-h-full">
          <a id="user-avatar" class="avatar-nav-link sm-flex sm-items-center sm-rounded" ngbDropdownToggle>
            <cc-user-avatar [user]="user" class="includes-outline"></cc-user-avatar>
            <span class="ml-2 text-truncate sm-font-medium sm-text-default-text">{{ user.firstName }}</span>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="user-avatar">
            <a i18n id="user-edit-profile" class="dropdown-item sm-h-full" (click)="editProfile()">
              <i class="cc-icon cc-icon-user" aria-hidden="true"></i> <span>Edit Profile</span>
            </a>

            <div
              *ngIf="isSSOUser; else changePasswordContainer"
              [gwTooltip]="disabledPasswordChangeTooltipText"
              gwTooltipIndicatorPosition="right"
              id="user-change-password-tooltip"
            >
              <ng-container *ngTemplateOutlet="changePasswordContainer"></ng-container>
            </div>
            <ng-template #changePasswordContainer>
              <a
                i18n
                id="user-change-password"
                class="dropdown-item"
                [class.disabled]="isSSOUser"
                [attr.href]="isSSOUser ? null : passwordChangeLink"
                target="_blank"
                (click)="onChangePasswordClick($event)"
              >
                <i class="cc-icon cc-icon-lock-closed" aria-hidden="true"></i><span>Change Password</span>
              </a>
            </ng-template>

            <a i18n id="user-logout" class="dropdown-item logout" (click)="logout()" ccSegmentCTA="Logout">
              <i class="cc-icon cc-icon-logout" aria-hidden="true"></i>
              <span>Logout</span>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="isDev" class="dev-env-indicator"></div>
</div>
