<div class="verification-container animate-fade-in">
  <h1 i18n class="sm-text-xl sm-font-bold">Are you human?</h1>
  <p i18n>Please complete the challenge below.</p>
  <re-captcha
    #captchaRef="reCaptcha"
    id="reCaptcha"
    (resolved)="recaptchaSolved($event)"
    [(ngModel)]="captcha"
    name="captcha"
    required
    [siteKey]="reCaptchaSiteKey"
  ></re-captcha>
  <div
    data-test="verify-captcha-msg"
    class="sm-mt-1 sm-text-xs sm-text-default-red"
    i18n
    *ngIf="!isCaptchaResolved && isSubmitted"
  >
    Please verify the reCaptcha
  </div>
  <button
    type="submit"
    [class.disabled-button]="!isCaptchaResolved"
    class="button-regular button-blue sm-w-full"
    (click)="submit()"
    [ccBusyButton]="isProcessing"
  >
    Finish
  </button>
</div>
