import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'app/user/user.service';

import { LastVisitedHomeService } from './last-visited-home.service';

@Injectable()
export class NavigateUserHomeService {
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly lastVisitedHomeService: LastVisitedHomeService,
  ) {}

  navigateUserToDefaultHome(): void {
    const currentUser = this.userService.getCurrentUser();
    const teamId = currentUser.standardUserOf?.teams?.[0];
    const projectId = currentUser.standardUserOf?.projects?.[0];
    const companyId = currentUser.standardUserOf?.companies?.[0];

    if (teamId) {
      this.lastVisitedHomeService.goToLastOrDefault(this.router, teamId, null);
    } else if (projectId) {
      void this.router.navigate(['/', 'projects', projectId]);
    } else if (companyId) {
      void this.router.navigate(['/', 'companies', companyId]);
    } else {
      void this.router.navigate(['/']);
    }
  }
}
