import { NgModule } from '@angular/core';

import { TmpI18NComponent } from './tmp-i18n.component';
import { TmpI18NDirective } from './tmp-i18n.directive';
import { TmpI18NService } from './tmp-i18n.service';

@NgModule({
  declarations: [TmpI18NComponent, TmpI18NDirective],
  providers: [TmpI18NService],
  exports: [TmpI18NComponent],
})
export class I18NModule {}
