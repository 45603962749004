import { CommonModule } from '@angular/common';
import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import {
  NgControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  FormControl,
  FormGroupDirective,
  FormControlName,
  FormControlDirective,
} from '@angular/forms';

import { ButtonComponent, TooltipDirective } from '@site-mate/global-web-ui';

@Component({
  selector: 'cc-license-input-spinner',
  templateUrl: './license-input-spinner.component.html',
  styleUrls: ['./license-input-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonComponent, TooltipDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicenseInputSpinnerComponent),
      multi: true,
    },
  ],
})
export class LicenseInputSpinnerComponent implements ControlValueAccessor, OnInit {
  @Input() value: number = 0;
  @Input() disabled?: boolean;
  @Input() label: string;
  @Input() tooltipMessage?: string;

  formControl: FormControl;

  onChange: (value: number) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private injector: Injector) {}

  // https://medium.com/@toha.marko/get-actual-control-in-controlvalueaccessor-e5bb3bb6710
  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);

    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = (ngControl as FormControlDirective).form as FormControl;
    }
  }

  writeValue(value: number): void {
    this.value = this.toPositiveInteger(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.value = 0;
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = this.toPositiveInteger(+input.value);
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (['e', 'E', '-', '+', '.'].includes(event.key)) {
      event.preventDefault();
    }
  }

  increment() {
    this.value += 1;
    this.onChange(this.value);
  }

  decrement() {
    this.value -= 1;
    this.onChange(this.value);
  }

  private toPositiveInteger(value: number): number {
    return Math.max(0, Math.trunc(value));
  }
}
