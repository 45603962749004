import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IPasswordValidation } from 'app/shared/model/password-validation.model';

@Component({
  selector: 'cc-activate-account-password',
  templateUrl: './activate-account-password.component.html',
  styleUrls: ['../activate-account.component.scss'],
})
export class ActivateAccountPasswordComponent {
  @Input({ required: true }) working: boolean = false;
  @Input({ required: true }) form!: FormGroup<{ password: FormControl; confirmPassword: FormControl }>;

  @Output() onSubmit = new EventEmitter();

  passwordValidation: IPasswordValidation;

  ngOnInit() {
    this.form.controls.password.valueChanges.subscribe(() => this.setPasswordValidation());
  }

  private setPasswordValidation() {
    const { password } = this.form.controls;

    if (!password || !password.errors) {
      return;
    }

    const { hasMinLength, hasLowercase, hasUppercase, hasNumber } = password.errors;

    this.passwordValidation = { hasMinLength, hasLowercase, hasUppercase, hasNumber };
  }
}
