import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProductBoardRedirectComponent } from './product-board-redirect.component';
import { AuthGuard } from '../auth/auth-guard.service';

const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  {
    path: 'redirect/product-board',
    component: ProductBoardRedirectComponent,
    canActivate: [AuthGuard],
  },
  // ========================= Templates ======================= //
  {
    path: 'teams/:teamId/apps/grid/:templateId',
    redirectTo: '/apps/teams/:teamId/grid/:templateId',
  },
  {
    path: 'teams/:teamId/apps/register/:templateId',
    redirectTo: '/apps/teams/:teamId/register/:templateId',
  },
  {
    path: 'teams/:teamId/apps',
    redirectTo: '/apps/teams/:teamId/active/grid',
  },
  {
    path: 'apps/teams/:teamId/grid',
    redirectTo: '/apps/teams/:teamId/active/grid',
  },
  {
    path: 'companies/:companyId/apps/grid/:templateId',
    redirectTo: '/apps/companies/:companyId/grid/:templateId',
  },
  {
    path: 'companies/:companyId/apps/register/:templateId',
    redirectTo: '/apps/companies/:companyId/register/:templateId',
  },
  {
    path: 'apps/companies/:companyId/grid',
    redirectTo: '/apps/companies/:companyId/active/grid',
  },
  {
    path: 'companies/:companyId/apps',
    redirectTo: '/apps/companies/:companyId/active/grid',
  },
  // ======================= Analytics ========================= //
  {
    path: 'teams/:teamId/analytics/dashboards/:dashboardId/charts/grid/:chartId',
    redirectTo: '/analytics/teams/:teamId/dashboards/:dashboardId/charts/grid/:chartId',
  },
  {
    path: 'teams/:teamId/analytics/dashboards/:dashboardId/charts/grid',
    redirectTo: '/analytics/teams/:teamId/dashboards/:dashboardId/charts/grid',
  },
  {
    path: 'teams/:teamId/analytics/dashboards/:dashboardId/charts/register/:chartId',
    redirectTo: '/analytics/teams/:teamId/dashboards/:dashboardId/charts/register/:chartId',
  },
  {
    path: 'teams/:teamId/analytics/dashboards/:dashboardId/charts/register',
    redirectTo: '/analytics/teams/:teamId/dashboards/:dashboardId/charts/register',
  },
  {
    path: 'teams/:teamId/analytics',
    redirectTo: '/analytics/teams/:teamId/dashboards/grid',
  },
  {
    path: 'companies/:companyId/analytics/dashboards/:dashboardId/charts/grid/:chartId',
    redirectTo: '/analytics/companies/:companyId/dashboards/:dashboardId/charts/grid/:chartId',
  },
  {
    path: 'companies/:companyId/analytics/dashboards/:dashboardId/charts/grid',
    redirectTo: '/analytics/companies/:companyId/dashboards/:dashboardId/charts/grid',
  },
  {
    path: 'companies/:companyId/analytics/dashboards/:dashboardId/charts/register/:chartId',
    redirectTo: '/analytics/companies/:companyId/dashboards/:dashboardId/charts/register/:chartId',
  },
  {
    path: 'companies/:companyId/analytics/dashboards/:dashboardId/charts/register',
    redirectTo: '/analytics/companies/:companyId/dashboards/:dashboardId/charts/register',
  },
  {
    path: 'companies/:companyId/analytics',
    redirectTo: '/analytics/companies/:companyId/dashboards/grid',
  },
  // ========================== Lists ========================== //
  {
    path: 'companies/:companyId/lists/:listId',
    redirectTo: '/lists/companies/:companyId/:listId',
  },
  {
    path: 'companies/:companyId/lists',
    redirectTo: '/lists/companies/:companyId/active',
  },
  {
    path: 'projects/:projectId/lists/:listId',
    redirectTo: '/lists/projects/:projectId/:listId',
  },
  {
    path: 'projects/:projectId/lists',
    redirectTo: '/lists/projects/:projectId/active',
  },
  {
    path: 'lists/projects/:projectId',
    redirectTo: '/lists/projects/:projectId/active',
  },
  {
    path: 'lists/companies/:companyId',
    redirectTo: '/lists/companies/:companyId/active',
  },
  // ========================= Photos ========================== //
  {
    path: 'teams/:teamId/photos',
    redirectTo: '/photos/teams/:teamId',
  },
  // ======================= Home Page ========================= //
  {
    path: 'teams/:teamId',
    redirectTo: '/home/teams/:teamId/activity',
  },
  {
    path: 'projects/:projectId',
    redirectTo: '/home/projects/:projectId/activity',
  },
  {
    path: 'companies/:companyId',
    redirectTo: '/home/companies/:companyId/activity',
  },
  // ======================= Secure ============================ //
  {
    path: 'secure/home/companies/:companyId',
    redirectTo: '/home/companies/:companyId/activity',
  },
  {
    path: 'secure/home/projects/:projectId',
    redirectTo: '/home/projects/:projectId/activity',
  },
  {
    path: 'secure/home/teams/:teamId',
    redirectTo: '/home/teams/:teamId/activity',
  },
  {
    path: 'secure/apps/:appId/forms/workflow/:formId',
    redirectTo: 'apps/:appId/forms/workflow/:formId',
  },
]);

@NgModule({
  imports: [routing],
  declarations: [ProductBoardRedirectComponent],
})
export class RedirectModule {}
