import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(
    private readonly jwtService: JwtService,
    private readonly router: Router,
  ) {}

  async canActivate() {
    const hasToken = await this.jwtService.getToken();

    if (hasToken) {
      void this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
