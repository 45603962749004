import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subject, distinctUntilChanged, noop, takeUntil } from 'rxjs';

import { SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { NetworkService } from 'app/network/network.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { CustomTitleService } from 'app/shared/service/custom-title/custom-title.service';
import { environment } from 'environments/environment';

import { SignUpService } from './sign-up.service';

@Component({
  selector: 'cc-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignUpComponent implements OnInit {
  readonly stepsWithHiddenLoginText = [
    SignUpSteps.UsedEmail,
    SignUpSteps.UsedDomain,
    SignUpSteps.JoinExistingWorkspace,
  ];
  readonly stepsWithConstructionBackground = [
    SignUpSteps.UserInfo,
    SignUpSteps.UsedEmail,
    SignUpSteps.UsedDomain,
    SignUpSteps.JoinExistingWorkspace,
  ];
  readonly stepsWithSidebarBackground = [
    SignUpSteps.BusinessAttributesSelector,
    SignUpSteps.MobileNumber,
    SignUpSteps.HumanVerification,
  ];

  readonly unSubscribe = new Subject<void>();
  readonly signUpSteps = SignUpSteps;

  activeStep: SignUpSteps;
  isMobile = false;

  unsubscribe = new Subject<void>();

  constructor(
    readonly signupService: SignUpService,
    private tagManagerService: GoogleTagManagerService,
    private readonly customTitleService: CustomTitleService,
    private readonly route: ActivatedRoute,
    private readonly appUtilService: AppUtilService,
    private readonly networkService: NetworkService,
    private readonly meta: Meta,
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    }
    this.networkService.testNetwork();
    this.initializeSignUp();
    this.signupService.activeStep$.pipe(takeUntil(this.unSubscribe)).subscribe((step) => {
      this.activeStep = step;
    });
  }

  initializeSignUp(): void {
    void this.tagManagerService.addGtmToDom().catch(noop);
    this.customTitleService.setTitle('Dashpivot Signup');
    this.isMobile = this.appUtilService.isMobile;
    this.setQueryParams();
  }

  setQueryParams(): void {
    this.route.queryParams
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe((params: Params) => {
        this.signupService.setQueryParams(params);
        this.signupService.submitSignUpPageVisitedAndViewedEvents();
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
