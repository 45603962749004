import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { Subject, takeUntil } from 'rxjs';

import { ContinentCodes } from 'app/user/shared/continents.enum';

import Countries from '../../../shared/countries.json';
import { ICountry } from '../../../shared/country.model';
import { SignUpService } from '../../sign-up.service';

@Component({
  selector: 'cc-sign-up-user-phone',
  templateUrl: './sign-up-user-phone.component.html',
  styleUrls: ['./sign-up-user-phone.component.scss', '../../sign-up.component.scss'],
})
export class SignUpUserPhoneComponent implements AfterViewChecked, OnInit {
  @ViewChild('userPhoneForm')
  userPhoneForm: NgForm;

  @ViewChild('mobile')
  mobile: ElementRef;

  countries = Countries;

  model = { mobile: '' };
  hasValidPhoneNumber = true;
  acceptedTnC = false;
  selectedCountry: string;
  continentCodes = ContinentCodes;
  selectedCountryContinentCode: string;
  isProcessing = false;
  unSubscribe = new Subject<void>();

  urlMap = {
    [this.continentCodes.Europe]: {
      privacyPolicy: 'https://sitemate.com/uk/resources/privacy-policy',
      termsOfService: 'https://sitemate.com/uk/resources/terms-of-service',
    },
    default: {
      privacyPolicy: 'https://sitemate.com/resources/privacy-policy',
      termsOfService: 'https://sitemate.com/resources/terms-of-service',
    },
  };

  constructor(
    readonly signupService: SignUpService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.populateCountryCode();
    this.subscribeToSignupProcessing();
    this.setContinentCode();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  private subscribeToSignupProcessing() {
    this.signupService.isProcessing$.pipe(takeUntil(this.unSubscribe)).subscribe((isProcessing) => {
      this.isProcessing = isProcessing;
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private populateCountryCode(): void {
    this.selectedCountry = this.signupService.userCountry;
  }

  validatePhoneNumber() {
    this.hasValidPhoneNumber =
      this.model?.mobile &&
      this.selectedISOCountryCode &&
      isValidPhoneNumber(this.userPhoneNumber, this.selectedISOCountryCode);
  }

  async submitDetails(form: NgForm) {
    this.validatePhoneNumber();

    if (form?.valid && !!this.selectedCountryAreaCode && this.hasValidPhoneNumber) {
      const formattedPhoneNumber = parsePhoneNumber(this.model.mobile, this.selectedISOCountryCode).number;

      this.signupService.updateSignupModel({
        mobile: formattedPhoneNumber,
        continent: this.userContinent,
      });
      await this.signupService.onSubmit();
    }
  }

  hasCountryCodeError(): boolean {
    return this.userPhoneForm?.submitted && !this.selectedCountryAreaCode;
  }

  handleCountrySelect(): void {
    setTimeout(() => {
      if (this?.mobile?.nativeElement) {
        this.mobile.nativeElement.focus();
      }
    }, 0);

    this.validatePhoneNumber();
  }

  hasFormError(field: NgModel): boolean {
    const currentForm = this.userPhoneForm;
    const requiredValidation = currentForm?.submitted && field.untouched && !field.dirty;
    const hasError = (field.invalid && (field.dirty || field.touched)) || requiredValidation;

    return hasError;
  }

  setContinentCode() {
    this.selectedCountryContinentCode = this.userContinent;
  }

  get userCountry(): ICountry {
    return this.countries.find((c) => c.value === this.selectedCountry);
  }

  get selectedCountryAreaCode(): string {
    return this.userCountry?.mcode || null;
  }

  get selectedISOCountryCode(): CountryCode {
    return this.userCountry?.ccode as CountryCode;
  }

  get userPhoneNumber() {
    return this.selectedCountryAreaCode + this.model.mobile;
  }

  get userContinent(): string {
    return Countries.find((country) => country.value === this.selectedCountry)?.continentCode;
  }
}
