<header class="nav-header">
  <img class="sm-pb-4 sm-pl-2" src="/assets/images/dashpivot-logo.svg" alt="Dashpivot logo" />
  <div class="folder-navbar-header">
    <div *ngIf="!currentWorkspace" class="company-logo-spinner">
      <i class="cc-icon cc-icon-spinner" aria-label="Loading spinner"></i>
      <span class="sm-text-sm sm-font-medium sm-text-white" i18n>Loading...</span>
    </div>
    <img *ngIf="currentWorkspace" class="company-logo" [src]="currentWorkspace.logo" alt="company logo" />
    <span *ngIf="currentWorkspace" data-test="home-folder-header" class="sm-min-w-0 sm-flex-1 sm-truncate">{{
      currentWorkspace.name
    }}</span>
  </div>

  <button
    type="button"
    class="folder-tree-switcher button-bg-default-text sm-flex sm-w-full sm-flex-1 sm-items-center sm-space-x-1 sm-rounded sm-p-1"
    [disabled]="isDeployModeActive"
    (click)="!isDeployModeActive && openWorkspaceSwitcher()"
  >
    <i class="cc-icon cc-icon-switch" aria-hidden="true"> </i>
    <span i18n>Switch workspace</span>
  </button>
  <div class="sm-w-full sm-pt-1">
    <cc-search-input
      [theme]="searchInputTheme"
      (valueChanged)="updateSearchParam($event)"
      [eventDebounceInMilliseconds]="searchDebounce"
      #searchInput
    ></cc-search-input>
  </div>
</header>
<section [class.drop-shadow]="!isSafari" class="folders sm-flex-1 sm-space-y-2 sm-overflow-y-auto sm-p-1">
  <ng-container *ngIf="!isLoading">
    <header class="sm-space-y-1" data-test="home-header">
      <cc-banner
        *ngIf="selectedFolderType !== ItemStates.Archived"
        badgeText="Tip"
        bannerText="Select and archive folders"
        size="sm"
        data-test="select-and-archive-banner"
        placement="right-top"
        triggers="mouseenter:mouseleave"
        popoverClass="right-top-arrow-popover"
        class="sm-w-full sm-cursor-default sm-text-left"
        ccBannerLogic
        [bannerKey]="bannerKeys.SelectAndArchiveFolders"
        [localStorageKey]="bannerMetadata[bannerKeys.SelectAndArchiveFolders].localStorageKey"
        [ngbPopover]="popoverTemplate"
        [dismissible]="true"
        [showBadge]="true"
      ></cc-banner>
      <div
        [disablePopover]="selectedFolderType !== ItemStates.Archived"
        [ngbPopover]="i18nMessages.HomeFolderInArchivedViewWarning"
        placement="right-top"
        popoverClass="preview-popover workspace-warning"
        triggers="mouseenter:mouseleave"
        class="popover-container"
      >
        <button
          [disabled]="selectedFolderType === ItemStates.Archived || isDeployModeActive"
          [class.selected]="activeFolder?._id === currentWorkspaceId"
          tabindex="0"
          class="folder home-folder sm-w-full"
          type="button"
          [class.authorised]="
            this.userControllerMap.isAdmin || this.userControllerMap.companies[currentWorkspaceId]
          "
          (click)="navigateToOrganisation(currentWorkspaceId)"
        >
          <i class="cc-icon cc-icon-home-solid" aria-hidden="true"></i>
          <span i18n>Home Folder</span>
        </button>
      </div>
      <div class="folder-helper-container" [class.disabled]="isDeployModeActive">
        <a
          class="folder-helper-link"
          target="_blank"
          rel="noopener noreferrer"
          [attr.aria-disabled]="isDeployModeActive"
          href="https://intercom.help/dashpivot/en/articles/2827406-creating-project-and-team-folders"
        >
          <i class="cc-icon cc-icon-question" aria-hidden="true"></i>
          <span class="sm-text-sm sm-font-medium" i18n>Project and team folders</span>
        </a>
      </div>
    </header>
    <div
      *ngIf="searchInput.value && !companyFolderTree[selectedFolderType]?.length"
      class="sm-flex sm-w-full sm-justify-center sm-py-2"
    >
      <p class="sm-text-sm sm-text-grey-5" *ngIf="!isLoading; else loadingSpinner" i18n>No results</p>
    </div>
    <div
      *ngIf="!searchInput.value && !companyFolderTree[selectedFolderType]?.length"
      class="sm-w-full sm-p-1"
      data-test="empty-state-text"
    >
      <p
        *ngIf="selectedFolderType === ItemStates.Archived; else emptyStateTextForActiveFolder"
        class="sm-text-sm sm-text-grey-5"
        i18n
      >
        No folders have been archived yet. You can still access active folders at the bottom of this screen.
      </p>
    </div>
    <cc-folders
      *ngIf="selectedFolderType === ItemStates.Active"
      [folderTree]="companyFolderTree.active"
      [currentWorkspace]="currentWorkspace"
      [userControllerMap]="userControllerMap"
      [isArchivedView]="false"
      (navigateToTeam)="navigateToTeam($event)"
      (navigateToProject)="navigateToProject($event)"
      [onAddTeam]="addTeam.bind(this)"
      [onRenameProject]="renameProject.bind(this)"
      [onRenameTeam]="renameTeam.bind(this)"
      [onAddProject]="addProject.bind(this)"
      (updateFolders)="updateFolders($event)"
    ></cc-folders>
    <cc-folders
      *ngIf="selectedFolderType === ItemStates.Archived"
      [folderTree]="companyFolderTree.archived"
      [currentWorkspace]="currentWorkspace"
      [userControllerMap]="userControllerMap"
      [isArchivedView]="true"
      (navigateToTeam)="navigateToTeam($event)"
      (navigateToProject)="navigateToProject($event)"
      (updateFolders)="updateFolders($event)"
      [onRenameProject]="renameProject.bind(this)"
      [onRenameTeam]="renameTeam.bind(this)"
    ></cc-folders>
  </ng-container>
</section>

<footer class="nav-footer">
  <section class="sm-w-full sm-border-t sm-border-grey-9 sm-px-1 sm-py-2">
    <cc-state-toggle
      *ngIf="selectedFolderType"
      [defaultValue]="selectedFolderType"
      width="full"
      [theme]="archivedToggleTheme"
      #stateToggle
    >
      <cc-state-toggle-item value="active" (click)="switchFolderType(ItemStates.Active)"
        ><div class="sm-flex sm-items-center sm-space-x-1">
          <i class="cc-icon cc-icon-folder-open-solid" aria-hidden="true"></i>
          <span i18n>Active</span>
        </div></cc-state-toggle-item
      >
      <cc-state-toggle-item value="archived" (click)="switchFolderType(ItemStates.Archived)">
        <div class="sm-flex sm-items-center sm-space-x-1">
          <i class="cc-icon cc-icon-archive" aria-hidden="true"></i>
          <span i18n>Archived</span>
        </div>
      </cc-state-toggle-item>
    </cc-state-toggle>
  </section>
</footer>

<ng-template #loadingSpinner>
  <div class="sm-flex sm-items-center sm-space-x-1">
    <i class="cc-icon cc-icon-spinner-2x" aria-label="Loading spinner"></i>
    <span class="sm-text-sm sm-font-medium sm-text-white">Loading...</span>
  </div>
</ng-template>

<ng-template #popoverTemplate data-test="popover-template">
  <img src="/assets/images/select-and-archive-folders.gif" alt="Select and archive folders" />
  <p i18n class="sm-pt-2">You can select, archive and restore folders in bulk.</p>
</ng-template>

<ng-template #emptyStateTextForActiveFolder>
  <span
    *ngIf="userControllerMap.isAdmin || userControllerMap.companies[currentWorkspaceId]"
    class="sm-space-y-4 sm-text-sm sm-text-grey-5"
    i18n
  >
    <p>No active folders have been created yet. Click "New Project Folder" to get started.</p>
    <p>You can still access archived folders at the bottom of this screen.</p>
  </span>
  <span
    *ngIf="!userControllerMap.isAdmin && !userControllerMap.companies[currentWorkspaceId]"
    class="sm-space-y-4 sm-text-sm sm-text-grey-5"
    i18n
  >
    <p>
      No active folders have been created yet. Contact your admin to create new folders or invite you to an
      existing folder.
    </p>
    <p>You can still access archived folders at the bottom of this screen.</p>
  </span>
</ng-template>
