import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { CompanyWeb } from 'app/shared/model/company.model';
import { ReleaseUpdateService } from 'app/shared/service/release-update.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { UserService } from 'app/user/user.service';

import { WorkspaceSwitcherInteractionEvent } from './workspace-switcher-interaction-event.interface';
import { WorkspaceSwitcherComponent } from './workspace-switcher.component';
import { NoWorkspaceFoundService } from '../no-workspace-found/no-workspace-found.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceSwitcherService {
  isModalOpen = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly modal: NgbModal,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly folderNavBarService: FolderNavBarService,
    private readonly userService: UserService,
    private readonly releaseUpdateService: ReleaseUpdateService,
    private readonly routeParamsService: RouteParamsService,
    private readonly noWorkspaceFoundService: NoWorkspaceFoundService,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    this.routeParamsService
      .get('company')
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(this.showIfCompanyIsArchived.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async openWorkspaceSwitcher(): Promise<WorkspaceSwitcherInteractionEvent> {
    this.isModalOpen = true;

    let result: WorkspaceSwitcherInteractionEvent = { event: 'dismissed' };

    const { queryParamMap } = this.activatedRoute.snapshot;

    if (queryParamMap.get('view') !== 'switchWorkspace') {
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { view: 'switchWorkspace' },
        queryParamsHandling: 'merge',
      });
    }

    if (this.releaseUpdateService.updateAvailable()) {
      this.window.location.reload();
    }

    try {
      const { addNewCompany, selectedCompanyId, noCompaniesFound } = await this.modal.open(
        WorkspaceSwitcherComponent,
        { modalDialogClass: 'modal-dialog-top' },
      ).result;

      if (selectedCompanyId) {
        result = await this.resolveSelectCompany(selectedCompanyId);
      } else if (addNewCompany) {
        result = await this.resolveAddNewCompany();
      } else if (noCompaniesFound) {
        result = { event: 'noWorkspaceFound' };
      }
    } catch {
      // dismissed
    } finally {
      this.isModalOpen = false;
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { view: null },
        queryParamsHandling: 'merge',
      });
    }

    return result;
  }

  private async resolveSelectCompany(selectedCompanyId: string): Promise<WorkspaceSwitcherInteractionEvent> {
    await this.navigationService.navigateToFirstAccessibleFolderInCompany(selectedCompanyId);
    return { event: 'navigatedToExistingCompany', context: selectedCompanyId };
  }

  private async resolveAddNewCompany(): Promise<WorkspaceSwitcherInteractionEvent> {
    try {
      const { company: newCompany } = await this.folderNavBarService.addCompany();
      await this.userService.updateCurrentUser().toPromise();
      await this.navigationService.navigateToOrganisation(newCompany._id);
      return { event: 'addNewCompany', context: newCompany };
    } catch (error) {
      return { event: 'dismissed' };
    }
  }

  private async showIfCompanyIsArchived(company: CompanyWeb) {
    if (company.archived) {
      const { event } = await this.openWorkspaceSwitcher();
      if (event === 'noWorkspaceFound') {
        this.noWorkspaceFoundService.openModal();
      }

      if (event === 'dismissed') {
        void this.showIfCompanyIsArchived(company);
      }
    }
  }
}
