<img class="sm-self-center" src="/assets/images/firewall.svg" alt="firewall-picture" />

<h2 class="sm-text-center sm-text-xl sm-font-medium" i18n>Firewall issue detected</h2>

<div class="sm-m-0 sm-text-center sm-text-sm" i18n>
  <p>
    Looks like your firewall settings are blocking access to some parts of Dashpivot. To resolve this, please
    contact your IT department to adjust the firewall settings and whitelist the following domains:
  </p>
  <br />
  <p><u>dashpivot.com</u>, <u>sitemate.com</u>, <u>flowsite.com</u></p>
  <br />
  <p>
    After you’ve made these changes, please refresh the page to continue. If problems persist, please reach
    out to our support team.
  </p>
</div>

<div class="sm-flex sm-justify-center sm-gap-2">
  <button
    gw-button
    (click)="openLearnMoreLink()"
    data-test="learn-more"
    type="button"
    leftIcon="question"
    color="blue"
    text="Learn more"
  ></button>

  <button
    gw-button
    (click)="openLiveChat()"
    data-test="open-live-chat"
    type="button"
    leftIcon="chat"
    color="grey-2"
    text="Chat with us"
  ></button>
</div>
