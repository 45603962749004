<div class="animate-fade-in construction-background-container">
  <span class="hidden-md-down col-md-4" [class.feature-container]="isTemplateReferral">
    <cc-sign-up-features
      *ngIf="isTemplateReferral"
      vector="/assets/images/signup/template-mockup.svg"
      [features]="signupFeatures.templateReferral"
    ></cc-sign-up-features>
    <img
      *ngIf="isNonTemplateReferral"
      class="icon-man hidden-md-down"
      src="./assets/images/signup/signup-left.svg"
      alt="man pointing out at the form"
    />
  </span>
  <div class="form-container col-md-auto">
    <div class="sign-up-form-container">
      <ng-content></ng-content>
    </div>
    <div class="mt-4 login-text sm-text-center" *ngIf="!hideLoginText">
      <span i18n>Already have a Dashpivot account?</span>
      <strong>
        <a routerLink="/login" i18n>Login</a>
      </strong>
    </div>
  </div>
  <span class="hidden-md-down col-md-4" [class.icon-people-container]="isTemplateReferral">
    <img
      *ngIf="isNonTemplateReferral"
      class="icon-woman"
      src="./assets/images/signup/signup-right.svg"
      alt="woman pointing out at the form"
    />
    <img
      *ngIf="isTemplateReferral"
      class="icon-people"
      src="./assets/images/signup/signup-user-pair.svg"
      alt="people pointing out at the form"
    />
  </span>
</div>

<div class="footer">
  Powered by <a class="sitemate-link" href="https://sitemate.com" rel="noopener noreferrer">Sitemate</a>
</div>
