<form [formGroup]="form" (ngSubmit)="submit()" class="sm-flex sm-w-[460px] sm-flex-col sm-gap-y-2">
  <div class="sm-flex sm-flex-col sm-rounded sm-border sm-border-grey-2">
    <div class="sm-border sm-border-grey-2 sm-p-4">
      <cc-plan-preview [plan]="planPreview" />
    </div>
    <div class="sm-flex sm-flex-col sm-gap-2 sm-border sm-border-grey-2 sm-p-4">
      <div class="sm-flex sm-flex-row sm-justify-between">
        <div>
          <span class="sm-text-xl sm-font-bold">{{
            totalCost | currency: form.value.paymentCurrency : 'symbol-narrow'
          }}</span>
          <span class="sm-text-sm">/month (excludes taxes)</span>
        </div>
        <cc-subscription-term-state-toggle formControlName="subscriptionTerm" />
      </div>

      <cc-license-input-spinner
        label="Dashpivot Contributors"
        [tooltipMessage]="tooltipMessages.dashpivotContributors"
        formControlName="dashpivotContributorUsersPaid"
      />

      <cc-license-input-spinner
        label="Sitemate Paid Users"
        [tooltipMessage]="tooltipMessages.sitematePaidUsers"
        formControlName="sitematePaidUsersPaid"
      />
    </div>
  </div>

  <div class="sm-text-right">
    <span class="sm-text-xs sm-text-secondary-text">Price shown in {{ form.value.paymentCurrency }}</span>
  </div>

  <button
    class="sm-self-start"
    gw-button
    type="submit"
    color="green"
    text="Confirm and continue to payment"
    [disabled]="form.invalid"
  ></button>
</form>
