<div
  data-test="feature-highlight"
  class="feature-higlight sm-flex sm-flex-row sm-flex-wrap sm-content-center sm-gap-x-1"
>
  <ng-container [ngSwitch]="iconStatus">
    <div
      data-test="feature-highlight-icon"
      *ngSwitchCase="PlanFeatureHighlightIconStatus.Valid"
      class="feature-highlight__icon feature-highlight__icon--valid"
    >
      <i class="cc-icon cc-icon-check"></i>
    </div>
    <div
      data-test="feature-highlight-icon"
      *ngSwitchCase="PlanFeatureHighlightIconStatus.Warning"
      class="feature-highlight__icon feature-highlight__icon--warning"
    >
      <i
        [gwTooltip]="featureHighlight.tooltip"
        gwTooltipIndicatorPosition="top"
        class="cc-icon cc-icon-exclamation"
      ></i>
    </div>
    <div
      data-test="feature-highlight-icon"
      *ngSwitchCase="PlanFeatureHighlightIconStatus.Disabled"
      class="feature-highlight__icon feature-highlight__icon--disabled"
    >
      <i class="cc-icon cc-icon-close-small"></i>
    </div>
  </ng-container>

  <div class="sm-flex sm-items-center">
    <span data-test="feature-highlight-description" class="sm-text-xs" i18n>{{
      featureHighlight.description
    }}</span>
  </div>
</div>
