import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

import { SignUpService } from '../../sign-up.service';

@Component({
  selector: 'cc-sign-up-used-email',
  templateUrl: 'sign-up-used-email.component.html',
  styleUrls: ['../../sign-up.component.scss'],
})
export class SignUpUsedEmailComponent {
  email: string;

  constructor(
    private readonly router: Router,
    readonly signupService: SignUpService,
  ) {}

  ngOnInit() {
    const model = this.signupService.getSignUpModel();
    this.email = model.email;
  }

  onLoginClick() {
    void this.router.navigate(['/login']);
  }

  onForgetPasswordClick() {
    const forgotPasswordAuthURL = `${environment.fusionAuth.authority}/password/forgot`;
    const forgotPasswordPage = `${forgotPasswordAuthURL}?email=${this.email}&client_id=${environment.fusionAuth.clientId}`;

    window.open(forgotPasswordPage, '_self');
  }
}
