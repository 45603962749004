import { Dialog } from '@angular/cdk/dialog';
import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountTypes, CompanyPlanTypes, SubscriptionStatuses } from '@site-mate/sitemate-global-shared/lib';

import { IntercomService } from 'app/intercom/intercom.service';
import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { LocalStorageKeys } from 'app/shared/model/local-storage-keys.enum';
import { AccountService } from 'app/shared/service/account.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { SelfServiceCheckoutStepperComponent } from 'app/user/self-service-checkout/self-service-checkout-stepper/self-service-checkout-stepper.component';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-account-upgrade-modal',
  templateUrl: 'account-upgrade-modal.component.html',
  styleUrls: ['account-upgrade-modal.component.scss'],
})
export class AccountUpgradeModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() isCloseButtonVisible: boolean;

  canUpgradeAccount = false;
  canSwitchWorkspace = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly intercomService: IntercomService,
    public readonly activeModal: NgbActiveModal,
    private readonly folderNavbarService: FolderNavBarService,
    private readonly userService: UserService,
    private readonly localStorageService: LocalStorageService,
    private readonly accountService: AccountService,
    private readonly dialog: Dialog,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.setupCanSwitchWorkspace();
    this.setupCanUpgradeAccount();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setupCanSwitchWorkspace() {
    const userControllerMap = this.userService.getUserControllerMap();
    if (userControllerMap.isAdmin) {
      this.canSwitchWorkspace = true;
      return;
    }

    this.folderNavbarService
      .getUserCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((companies) => {
        this.canSwitchWorkspace = companies.length > 1;
      });
  }

  openLiveChat() {
    this.intercomService.open();
  }

  switchWorkspace() {
    this.activeModal.close();
  }

  setupCanUpgradeAccount() {
    this.accountService.currentAccount.pipe(takeUntil(this.destroy$)).subscribe((account) => {
      const isSelfServiceAccount = account.accountType === AccountTypes.SelfServiceAccount;
      const isFeatureFlagEnabled = !!this.localStorageService.getItem(
        LocalStorageKeys.SelfServiceFeatureFlag,
      );

      const { planType, subscriptionStatus } = account.billing?.dashpivot ?? {};
      const isFreeTrialPlan = planType === CompanyPlanTypes.FreeTrial;

      const isFreeTrialActive =
        isSelfServiceAccount && isFreeTrialPlan && subscriptionStatus === SubscriptionStatuses.FreeTrial;
      const isTrialExpired =
        isSelfServiceAccount && isFreeTrialPlan && subscriptionStatus === SubscriptionStatuses.ExpiredTrial;

      const canUpgrade = [isFreeTrialActive, isTrialExpired].some(Boolean);

      this.canUpgradeAccount = canUpgrade && isFeatureFlagEnabled;
    });
  }

  upgradeAccount() {
    const dialogRef = this.dialog.open(SelfServiceCheckoutStepperComponent);

    this.setHostStyle('display', 'none');
    dialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setHostStyle('display', 'flex');
    });
  }

  private setHostStyle(property: string, value: string) {
    this.renderer.setStyle(this.el.nativeElement, property, value);
  }
}
