import { Inject, Injectable } from '@angular/core';

import { WINDOW } from 'app/shared/factory/window.factory';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkService {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  public redirect(url: string) {
    this.window.location.href = url;
  }

  public getLocation() {
    return this.window.location;
  }
}
