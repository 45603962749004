import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of, Subject, throwError } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { UserService } from 'app/user/user.service';

import { MATCHING_CONFIG_STATE_ERROR } from '../auth-error.model';
import { AuthService } from '../auth.service';

@Component({
  template: '',
})
export class LoginRedirectComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly authService: AuthService,
    private readonly i18nService: TmpI18NService,
  ) {}

  ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(({ isAuthenticated }) => {
          if (isAuthenticated) {
            return this.userService.updateCurrentUser();
          }

          return of(null);
        }),
        finalize(() => {
          const customRedirectUrl = this.authService.getCustomAuthRedirectURL();

          if (customRedirectUrl) {
            this.authService.clearCustomAuthRedirectURL();
            return this.router.navigateByUrl(customRedirectUrl);
          }

          return this.router.navigate(['/']);
        }),
      )
      .subscribe({
        error: (error) => {
          const stringError = JSON.stringify(error, Object.getOwnPropertyNames(error));
          const isUndefinedState = stringError.includes(MATCHING_CONFIG_STATE_ERROR);

          if (isUndefinedState) {
            const message = this.i18nService.getMessage('loginRedirectErrorMessage');
            this.authService.showRedirectionModal(message);
            return of(null);
          }

          return throwError(error);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
