import { Component, Inject, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, takeUntil } from 'rxjs';

import { ButtonComponent } from '@site-mate/global-web-ui';

import { IntercomService } from 'app/intercom/intercom.service';
import { WINDOW } from 'app/shared/factory/window.factory';

@Component({
  selector: 'cc-firewall-issue',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './firewall-issue.component.html',
  styleUrls: ['./firewall-issue.component.scss'],
})
export class FirewallIssueComponent implements OnDestroy {
  unsubscribe = new Subject<void>();

  constructor(
    @Inject(WINDOW) private readonly window: Window,

    private readonly intercomService: IntercomService,
    private readonly oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openLearnMoreLink() {
    const intercomLink = 'https://intercom.help/dashpivot/en/articles/9303904-network-and-firewall-issues';
    this.window.open(intercomLink, '_blank', 'noopener noreferrer');
  }

  openLiveChat() {
    this.oidcSecurityService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ isAuthenticated }) => {
        if (!isAuthenticated) {
          this.intercomService.boot();
        }
        this.intercomService.open();
      });
  }
}
