import { Component, OnInit } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';

@Component({
  templateUrl: 'redirect.component.html',
})
export class ProductBoardRedirectComponent implements OnInit {
  constructor(private readonly segmentService: SegmentService) {}

  ngOnInit(): void {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.EmailProductBoardLinkClicked),
      this.segmentService,
    );
    window.location.href = 'https://portal.productboard.com/wm6whau7st9md1ulztigrv4n/tabs/3-planned';
  }
}
