import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/auth/auth-guard.service';
import { CheckAuthGuard } from 'app/auth/check-auth-guard.service';
import { ReleaseUpdateGuard } from 'app/shared/service/release-update-guard.service';

import { SecureComponent } from './secure.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckAuthGuard, AuthGuard],
    canActivateChild: [ReleaseUpdateGuard],
    component: SecureComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home-page.module').then((m) => m.HomePageModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'lists',
        loadChildren: () => import('../lists/lists.module').then((m) => m.ListsModule),
      },
      {
        path: 'photos',
        loadChildren: () => import('../photo/photo-routed.module').then((m) => m.PhotoRoutedModule),
      },
      {
        path: 'apps',
        loadChildren: () =>
          import('../apps/secure-templates-routed.module').then((m) => m.SecureTemplatesRoutedModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('../analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SecureRoutingModule {}
