<button
  class="close-button"
  *ngIf="isCloseButtonVisible"
  type="button"
  aria-label="Close"
  (click)="activeModal.dismiss()"
>
  <i class="cc-icon cc-icon-close" aria-hidden="true"></i>
</button>

<div class="sm-pb-4 sm-pt-6">
  <img [src]="imageSrc" aria-hidden="true" [alt]="imageAlt" />
</div>

<h1 class="sm-text-xl sm-font-bold" i18n>{{ title }}</h1>
<p class="sm-text-center sm-text-sm" i18n [innerHTML]="subtitle | safeHtml"></p>

<section class="sm-flex sm-space-x-2">
  <button
    *ngIf="!canUpgradeAccount"
    data-test="chat-with-us-button"
    gw-button
    type="button"
    color="green"
    text="Chat with us"
    leftIcon="chat"
    (click)="openLiveChat()"
  ></button>
  <button
    *ngIf="canUpgradeAccount"
    data-test="upgrade-button"
    gw-button
    type="button"
    color="green"
    text="Upgrade"
    leftIcon="zap"
    (click)="upgradeAccount()"
  ></button>

  <a href="mailto:info@sitemate.com?subject=Upgrade%20Subscription" role="button">
    <button type="button" gw-button color="grey-2" text="Email us" leftIcon="send"></button>
  </a>
</section>

<ng-container *ngIf="canSwitchWorkspace">
  <p class="sm-text-center sm-text-base" i18n>Have access to multiple workspaces?</p>

  <button
    data-test="switch-workspace"
    gw-button
    type="button"
    color="grey-2"
    text="Switch workspace"
    leftIcon="switch"
    (click)="switchWorkspace()"
  ></button>
</ng-container>
