import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';

import { EventTypes, SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { IPasswordValidation } from 'app/shared/model/password-validation.model';
import { RegexpMatchers } from 'app/shared/model/regexp-matches.model';
import { SignUp } from 'app/shared/model/sign-up.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { SignUpFormSlideAnimation } from './sign-up-form-slide-animation.model';
import { userInfoEventContext } from './sign-up-user-info-event-contexts.model';
import {
  SignUpUserInfoNextActionEventContexts,
  SignUpUserInfoNextActionsRecord,
} from './sign-up-user-info-next-actions.types';
import { SignUpService } from '../../sign-up.service';

@Component({
  selector: 'cc-sign-up-user-info',
  templateUrl: './sign-up-user-info.component.html',
  styleUrls: ['./sign-up-user-info.component.scss'],
  animations: [SignUpFormSlideAnimation],
})
export class SignUpUserInfoComponent implements OnInit, AfterViewInit {
  private readonly eventContext = userInfoEventContext;
  private readonly nextActions: SignUpUserInfoNextActionsRecord = {
    navigateToUsedEmailStep: {
      action: () => this.navigateToUsedEmailStep(),
      context: this.eventContext.Existing,
    },
    navigateToUsedDomainStep: {
      action: () => this.navigateToUsedDomainStep(),
      context: this.eventContext.DomainCheck,
    },
    setFieldsBelowEmailVisible: {
      action: () => this.setFieldsBelowEmailVisible(),
      context: this.eventContext.New,
    },
  };

  @ViewChild('userInfoForm')
  userInfoForm: NgForm;

  @ViewChild('email')
  emailInput: NgModel;

  @ViewChild('nextButton', { read: ElementRef })
  nextButtonElement: ElementRef;

  model = {} as SignUp;

  shouldShowUserInfoFields: boolean;

  isTemplateReferral: boolean;
  isNonTemplateReferral: boolean;

  showPassword: boolean;
  disabledPasswordToolTip: string;
  passwordValidation: IPasswordValidation;
  combinedPasswordValidationRegex: RegExp;

  unsubscribe = new Subject<void>();

  readonly doNotAcceptWithoutAlphanumeric = RegexpMatchers.doNotAcceptWithoutAlphanumeric;
  public emailRegex: RegExp;

  isLoading = false;
  shouldNavigateToUsedEmailStep = false;
  isEmailInvalid = false;

  constructor(
    private readonly companyService: CompanyService,
    private readonly globalApiService: GlobalApiService,
    private readonly appUtilService: AppUtilService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly errorHandler: ErrorHandler,
    private readonly i18nService: TmpI18NService,
    private readonly signUpService: SignUpService,
    private readonly eventsService: EventsService,
    private readonly toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.model = this.signUpService.getSignUpModel();

    this.setSignupReferralType();
    this.disabledPasswordToolTip = this.i18nService.getMessage('passwordDisabledForSSOSignup');
    this.emailRegex = this.appUtilService.emailRegex;
    this.combinedPasswordValidationRegex = this.signUpService.getCombinedPasswordValidationRegex();
    this.shouldShowUserInfoFields = this.signUpService.getSignUpUserInfoFieldsVisibility();
  }

  ngAfterViewInit(): void {
    this.changeDetectionRef.detectChanges();
  }

  async handleNextClick(event: Event): Promise<void> {
    this.isEmailInvalid = this.hasEmailError(this.emailInput);

    if (!this.isEmailInvalid) {
      let nextAction = this.shouldNavigateToUsedEmailStep
        ? this.nextActions.navigateToUsedEmailStep
        : this.nextActions.setFieldsBelowEmailVisible;

      if (!this.shouldShowUserInfoFields) {
        event.preventDefault();
        this.signUpService.updateSignupModel({ email: this.model.email });
        this.verifySSODomain(this.model.email);
        const { isBusinessDomainInUse } = await this.verifyDomainInUse(this.model.email);
        this.isLoading = false;

        if (isBusinessDomainInUse && !this.shouldNavigateToUsedEmailStep) {
          nextAction = this.nextActions.navigateToUsedDomainStep;
        }

        nextAction.action();
        this.sendNextActionEvents(nextAction.context);
      }
    }
  }

  async submitDetails(): Promise<void> {
    this.model.companyName = this.model.companyName.trim();
    const ssoSignupPlaceHolderPassword = 'SSOSignup123';

    if (this.model.companyName && this.model.email) {
      const { success, errorMessage } = await this.signUpService.handleAccountSubdomain(
        this.model.email,
        this.model.companyName,
      );

      if (!success) {
        this.toastrService.error(errorMessage);
        return;
      }

      this.model.subdomain = this.signUpService.getSignUpModel().subdomain;
    }

    const isFormValid = this.userInfoForm?.valid || false;
    if (isFormValid) {
      this.signUpService.updateSignupModel({
        ...this.model,
        password: this.model.isSSOSignUp ? ssoSignupPlaceHolderPassword : this.model.password,
      });
      this.navigateToBusinessAttributesSelector();
    }
  }

  hasEmailError(email: NgModel): boolean {
    const isRequired = !email.pristine && email.errors?.required;
    const isNotUnique = email.errors?.uniqueEmail && !email.errors.uniqueEmail?.invalidFormat;
    if (isNotUnique) {
      return false;
    }

    const isInvalid = isRequired || email.errors?.email || email.errors?.uniqueEmail?.invalidFormat;
    this.isEmailInvalid = Boolean(isInvalid);

    return Boolean(isInvalid);
  }

  onUniqueEmailDirectiveLoadingUpdate({ isLoading, isUsedEmail }) {
    this.isLoading = isLoading;
    this.shouldNavigateToUsedEmailStep = isUsedEmail;
    this.changeDetectionRef.detectChanges();
  }

  onPasswordChange(value: string) {
    this.model.password = value;

    this.passwordValidation = this.signUpService.validatePassword(this.model.password);
  }

  private setSignupReferralType(): void {
    this.isTemplateReferral = this.signUpService.isTemplateReferral;
    this.isNonTemplateReferral = this.signUpService.isNonTemplateReferral;
  }

  private navigateToBusinessAttributesSelector() {
    this.signUpService.setActiveStep(SignUpSteps.BusinessAttributesSelector);
    this.eventsService.trackEvent(EventTypes.CtaClicked, {
      Context: 'Signup Page 1',
    });
  }

  private verifySSODomain(email: string) {
    this.companyService
      .isSSODomain(email)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        ({ isSSODomain }) => {
          if (isSSODomain) {
            this.model.password = '';
          }
          this.model.isSSOSignUp = isSSODomain;
          this.signUpService.updateSignupModel({ isSSOSignUp: isSSODomain });
          this.changeDetectionRef.detectChanges();
        },
        (error) => this.errorHandler.handle(error),
      );
  }

  private async verifyDomainInUse(email: string): Promise<{ isBusinessDomainInUse: boolean }> {
    this.isLoading = true;
    const domain = email.split('@')[1];
    return lastValueFrom(this.globalApiService.isBusinessDomainInUse(domain));
  }

  private sendNextActionEvents(nextActionEventContext: SignUpUserInfoNextActionEventContexts): void {
    this.eventsService.trackEvent(EventTypes.SignUpUserEmail, {
      Context: nextActionEventContext,
    });

    if (nextActionEventContext === this.eventContext.New) {
      this.eventsService.trackEvent(EventTypes.CtaClicked, {
        Context: 'Sign Up Get Started',
      });
    }
  }

  // Next button actions
  private setFieldsBelowEmailVisible(): void {
    this.setNextButtonDisabled();
    this.signUpService.setSignUpUserInfoFieldsVisibility(true);
    this.shouldShowUserInfoFields = true;
    this.changeDetectionRef.detectChanges();
  }

  private navigateToUsedEmailStep() {
    this.signUpService.setActiveStep(SignUpSteps.UsedEmail);
  }

  private navigateToUsedDomainStep() {
    this.signUpService.setActiveStep(SignUpSteps.UsedDomain);
  }

  private setNextButtonDisabled() {
    this.nextButtonElement.nativeElement.disabled = true;
    this.changeDetectionRef.detectChanges();
  }
}
