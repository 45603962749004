<gw-modal [dismissable]="false">
  <div class="sm-flex sm-flex-col sm-gap-2">
    <div class="sm-flex sm-w-full sm-justify-between">
      <div class="sm-flex sm-flex-row sm-content-center">
        <button
          (click)="goPreviousStep()"
          *ngIf="currentStep > 0"
          type="button"
          gw-button
          leftIcon="arrow-left"
          color="grey"
          class="sm-mr-1 sm-h-6 sm-w-6"
        >
          <i class="cc-icon cc-icon-arrow-left"></i>
        </button>
        <div
          class="sm-flex sm-flex-grow sm-flex-wrap sm-content-center sm-justify-center sm-text-xl sm-font-medium"
        >
          <span>{{ currentStepDetails.title }}</span>
        </div>
      </div>
      <div>
        <button
          gw-button
          color="white"
          leftIcon="close"
          type="button"
          (click)="closeModal()"
          class="sm-h-4 sm-w-4"
        ></button>
      </div>
    </div>
    <div>
      <cc-select-plan-step
        class="animate-fade-in"
        *ngIf="currentStep === 0"
        (planSelected)="planSelected($event)"
      />
      <cc-select-licenses-step
        class="animate-fade-in"
        *ngIf="currentStep === 1"
        [selectedPlanType]="form.value?.planType"
        (submitForm)="handleSelectLicensesStep($event)"
      />
    </div>
  </div>
</gw-modal>
