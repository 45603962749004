/* eslint-disable max-lines */
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep, capitalize, isEqual } from 'lodash-es';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';

import {
  Company,
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FolderKinds,
  ItemStates,
  PathService,
} from '@site-mate/dashpivot-shared-library';

import { TemplateDeploymentService } from 'app/apps/template-deployment.service';
import { FolderSelectionService } from 'app/folders/folder-selection.service';
import { ActivityService } from 'app/home/activity/activity.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListDeploymentService } from 'app/lists/list-deployment.service';
import { NavigationService } from 'app/navigation/navigation.service';
import { SegmentService } from 'app/segment/segment.service';
import { FolderWeb } from 'app/shared/model/folder.model';
import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { DropdownComponent } from 'app/ui-components/dropdown/dropdown.component';
import { IUserControllerMap } from 'app/user/user.service';

import { ContextTypes } from './context-types.enum';
import { FoldersService } from './folders.service';
import { SelectedFoldersMap } from '../../../folders/folder-selection.service.types';
import { FolderInlineCreatorComponent } from '../folder-inline-creator/folder-inline-creator.component';
import { FolderInlineInputComponent } from '../folder-inline-input/folder-inline-input.component';
import { FolderNavBarService } from '../folder-navbar.service';

@Component({
  selector: 'cc-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['../folder-navbar.component.scss', './folders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldersComponent implements OnInit, OnChanges {
  readonly ItemStates = ItemStates;
  readonly defaultVisibleFoldersCount = 20;

  private readonly visibleFoldersIncrement = 10;
  private readonly destroy$ = new Subject<void>();
  private openedDropdown: DropdownComponent;
  private folderTreeChangesSubject = new Subject<ProjectWeb[]>();
  private projectIdBehaviorSubject = new BehaviorSubject<string | null>(null);
  protected readonly destroyRef = inject(DestroyRef);

  visibleFoldersMap: Map<string, number> = new Map();
  activeFolder: FolderWeb;
  hoveredFolder: string = null;
  showAllCheckboxes = false;
  selectedFolders: SelectedFoldersMap = new Map();
  openDropdownFolderId: string;
  allFoldersSelected = false;
  activeProjectInArchivedViewWarning: string;
  deployingList = false;
  deployingTemplate = false;
  isRenamingProjectFolderDisabled = false;
  renamingFolder: TeamWeb | ProjectWeb | null = null;
  folderLoading = false;

  @ViewChildren(DropdownComponent) private dropdowns: QueryList<DropdownComponent>;
  @ViewChildren('inlineInput') private inlineInputs: QueryList<FolderInlineInputComponent>;
  @ViewChildren('teamInlineCreator') private teamInlineCreators: QueryList<FolderInlineCreatorComponent>;

  @Input() folderTree: ProjectWeb[] = [];
  @Input() currentWorkspace: Company;
  @Input() userControllerMap: IUserControllerMap = { isAdmin: false };
  @Input() isArchivedView: boolean;

  @Input() onAddTeam: (projectId: string, name: string) => Promise<void>;
  @Input() onAddProject: (workspaceId: string, name: string) => Promise<string>;
  @Input() onRenameTeam: (team: TeamWeb, name: string) => Promise<void>;
  @Input() onRenameProject: (project: ProjectWeb, name: string) => Promise<void>;
  @Output() navigateToTeam = new EventEmitter<string>();
  @Output() navigateToProject = new EventEmitter<string>();
  @Output() updateFolders = new EventEmitter<boolean>();

  constructor(
    readonly teamService: TeamService,
    readonly activityService: ActivityService,
    readonly folderNavBarService: FolderNavBarService,
    readonly foldersService: FoldersService,
    readonly toastrService: ToastrService,
    readonly confirmService: ConfirmService,
    readonly navigationService: NavigationService,
    private readonly pathService: PathService,
    private readonly segmentService: SegmentService,
    private readonly i18nService: TmpI18NService,
    private readonly folderSelectionService: FolderSelectionService,
    private readonly listDeploymentService: ListDeploymentService,
    private readonly templateDeploymentService: TemplateDeploymentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.setupI18nMessages();

    this.folderSelectionService.deselectAllFolders();
    this.selectedFolders.clear();

    this.teamService.currentTeam.pipe(takeUntil(this.destroy$)).subscribe((folder) => {
      this.activeFolder = folder;
      this.changeDetectorRef.detectChanges();
    });

    this.folderSelectionService.selectedFolders$.pipe(takeUntil(this.destroy$)).subscribe((folder) => {
      this.selectedFolders = folder;
      this.showAllCheckboxes = Boolean(folder.size);
      this.updateAllFoldersSelected();
      this.isRenamingProjectFolderDisabled = this.areMultipleProjectFoldersSelected();
    });

    this.folderNavBarService.searchValue.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.visibleFoldersMap.clear();
    });

    combineLatest([
      this.listDeploymentService.activeDeployMode$,
      this.templateDeploymentService.activeDeployMode$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([listDeployMode, templateDeployMode]) => {
        this.deployingList = Boolean(listDeployMode);
        this.deployingTemplate = Boolean(templateDeployMode);
        if (this.openedDropdown) {
          this.moveOrCloseDropdown();
        }

        this.changeDetectorRef.markForCheck();
      });

    this.inlineInputs.changes.subscribe((inputs: QueryList<FolderInlineInputComponent>) => {
      inputs.first?.focus();
    });

    this.folderTreeChangesSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.updateAllFoldersSelected();
    });

    combineLatest([
      this.folderTreeChangesSubject.pipe(startWith(null), pairwise(), takeUntilDestroyed(this.destroyRef)),
      this.projectIdBehaviorSubject.pipe(
        filter((projectId) => projectId !== null),
        takeUntilDestroyed(this.destroyRef),
      ),
    ]).subscribe(
      ([[previousFolders, currentFolders], projectId]: [[FolderWeb[] | null, FolderWeb[]], string]) => {
        const previousProjectIds = previousFolders
          ? previousFolders
              .filter((folder) => folder.kind === FolderKinds.Project)
              .map((folder) => folder._id)
          : [];
        const currentProjectIds = currentFolders
          .filter((folder) => folder.kind === FolderKinds.Project)
          .map((folder) => folder._id);

        const projectsIdentical = isEqual(previousProjectIds, currentProjectIds);
        const folderTreeIncludesLatestProject = currentProjectIds.includes(projectId);
        const currentProjectIdIsNull = this.projectIdBehaviorSubject.getValue() === null;

        if (projectsIdentical || !folderTreeIncludesLatestProject || currentProjectIdIsNull) {
          return;
        }

        this.focusTeamInlineCreatorByProject(projectId, currentFolders as ProjectWeb[]);
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.folderTree) {
      this.folderTreeChangesSubject.next(this.folderTree);
    }
  }

  focusTeamInlineCreatorByProject(projectId: string, folders: ProjectWeb[] | null) {
    const projectIndex = (folders ?? this.folderTree).findIndex((folder) => folder._id === projectId);
    const visibleFoldersCount = this.getVisibleFoldersCount(this.currentWorkspace?._id);
    if (projectIndex >= visibleFoldersCount) {
      this.setVisibleFoldersCountByIndex(projectIndex);
    }

    setTimeout(() => {
      this.teamInlineCreators.get(projectIndex)?.setActive(true);
      this.projectIdBehaviorSubject.next(null);
    });
  }

  setVisibleFoldersCountByIndex(index: number) {
    this.visibleFoldersMap.set(this.currentWorkspace._id, index + 1);
  }

  getVisibleFoldersCount(folderId: string) {
    return this.visibleFoldersMap.get(folderId) || this.defaultVisibleFoldersCount;
  }

  onDropdownStateChange(dropdownState: boolean, folderId: string) {
    if (this.openDropdownFolderId === folderId || dropdownState) {
      this.openDropdownFolderId = dropdownState ? folderId : null;
    }
  }

  setRenamingFolder(value: TeamWeb | ProjectWeb | null) {
    this.renamingFolder = value;
  }

  renameFolderDropdownItemHandler(team: TeamWeb | ProjectWeb) {
    this.setRenamingFolder(team);
    this.closeDropdown();
  }

  onRenameTeamHandler = (team: TeamWeb) => async (name: string) => {
    this.folderLoading = true;
    try {
      await this.onRenameTeam(team, name);
    } finally {
      this.folderLoading = false;
    }
  };

  onRenameProjectHandler = (project: ProjectWeb) => async (name: string) => {
    this.folderLoading = true;
    try {
      await this.onRenameProject(project, name);
    } finally {
      this.folderLoading = false;
    }
  };

  navigateToTeamHandler(teamId: string) {
    this.navigateToTeam.emit(teamId);
  }

  navigateToProjectHandler(projectId: string) {
    this.navigateToProject.emit(projectId);
  }

  increaseVisibleSubfoldersCount(folderId: string) {
    const newLimit = this.getVisibleFoldersCount.bind(this)(folderId) + this.visibleFoldersIncrement;
    this.visibleFoldersMap.set(folderId, newLimit);
  }

  private checkAllFolders() {
    this.folderSelectionService.deselectAllFolders();
    this.folderTree.forEach((project) => {
      const canSelectProjectFolder =
        !this.isArchivedView || (project.state === ItemStates.Archived && !this.deployingList);

      if (this.hasHigherProjectsPermission && canSelectProjectFolder) {
        this.folderSelectionService.toggleFolderSelection(project);
        this.sendDashpivotEvent(ContextTypes.Bulk, capitalize(project.kind));
      }

      project.teams.forEach((team) => {
        if (this.hasHigherTeamPermission(team)) {
          this.folderSelectionService.toggleFolderSelection(team);
          this.sendDashpivotEvent(ContextTypes.Bulk, capitalize(team.kind));
        }
      });
    });
  }

  sendDashpivotEvent(Context: ContextTypes, Hierarchy: string) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FolderSelected, { Context, Hierarchy }),
      this.segmentService,
    );
  }

  changeHoveredFolder(hoveredFolder: string) {
    this.hoveredFolder = hoveredFolder;
  }

  private toggleSelectProject(project: ProjectWeb) {
    if (this.folderLoading) {
      return;
    }
    if (!this.hasHigherProjectsPermission) {
      return;
    }
    if (!this.deployingList) {
      this.toggleSelectChildTeams(project._id);
    }
    this.toggleSelectFolder(project);
    this.updateAllFoldersSelected();

    if (this.folderSelectionService.selectedFolders.has(project._id)) {
      const projectContext = project.teams.length ? ContextTypes.Bulk : ContextTypes.Individual;

      this.sendDashpivotEvent(projectContext, capitalize(project.kind));
    }
  }

  private toggleSelectChildTeams(projectId: string) {
    const childTeams = this.folderTree.find((p) => p._id === projectId).teams;

    if (this.folderSelectionService.selectedFolders.has(projectId)) {
      childTeams.forEach((team) => {
        this.folderSelectionService.deselectFolder(team._id);
        if (this.folderSelectionService.selectedFolders.has(team._id)) {
          this.folderSelectionService.toggleFolderSelection(team);
        }
      });
    } else {
      childTeams.forEach((team) => {
        this.folderSelectionService.selectFolder(team);
        if (!this.folderSelectionService.selectedFolders.has(team._id)) {
          this.folderSelectionService.toggleFolderSelection(team);
        }
        this.sendDashpivotEvent(ContextTypes.Bulk, capitalize(team.kind));
      });
    }
  }

  private toggleSelectTeam(team: TeamWeb) {
    if (this.folderLoading) {
      return;
    }
    if (!this.hasHigherTeamPermission(team)) {
      return;
    }

    this.toggleSelectFolder(team);
    this.updateAllFoldersSelected();
    if (this.selectedFolders.has(team._id)) {
      this.sendDashpivotEvent(ContextTypes.Individual, capitalize(team.kind));
    }
  }

  private toggleSelectFolder(folder: ProjectWeb | TeamWeb) {
    if (this.folderSelectionService.selectedFolders.has(folder._id)) {
      this.removeParentFolderFromSelectedFolders(folder);
      this.folderSelectionService.deselectFolder(folder._id);
      this.moveOrCloseDropdown();
    } else {
      this.folderSelectionService.selectFolder(folder);
      this.reopenDropdown(folder._id);
    }
  }

  private removeParentFolderFromSelectedFolders(folder: ProjectWeb | TeamWeb) {
    const parentId = this.pathService.extractFolderIds(folder.path)[1];
    const parentFolder = this.folderTree.find((parent) => parent._id === parentId);
    this.folderSelectionService.deselectFolder(parentFolder?._id);
  }

  private moveOrCloseDropdown() {
    if (this.deployingList && this.openedDropdown) {
      this.closeDropdown();
      return;
    }

    if (this.openedDropdown) {
      this.closeDropdown();
    }

    if (this.selectedFolders.size) {
      const lastSelectedFolder = Array.from(this.selectedFolders.values()).pop();
      this.reopenDropdown(lastSelectedFolder._id);
    }
  }

  private closeDropdown() {
    this.openedDropdown.close();
    this.openedDropdown = null;
  }

  private reopenDropdown(folderId: string) {
    if (this.deployingList || this.deployingTemplate) {
      return;
    }

    if (this.openedDropdown) {
      this.openedDropdown.close();
    }
    this.openedDropdown = this.dropdowns.find((dd) => dd.id === folderId);
    this.openedDropdown.open();
  }

  onAddTeamHandler = (projectId: string) => async (name: string) => {
    this.folderLoading = true;
    try {
      await this.onAddTeam(projectId, name);
      this.focusTeamInlineCreatorByProject(projectId, null);
    } finally {
      this.folderLoading = false;
    }
  };

  onAddProjectHandler = (workspaceId: string) => async (name: string) => {
    this.folderLoading = true;
    try {
      const projectId = await this.onAddProject(workspaceId, name);
      this.projectIdBehaviorSubject.next(projectId);
    } finally {
      this.folderLoading = false;
    }
  };

  showFolderIcon(folderId: string) {
    return (
      !this.folderIsHoveredOn(folderId) &&
      !this.showAllCheckboxes &&
      !this.deployingList &&
      !this.deployingTemplate
    );
  }

  folderIsHoveredOn(folderId: string) {
    return this.hoveredFolder === folderId;
  }

  showCheckbox(folder: TeamWeb | ProjectWeb) {
    if (this.deployingTemplate) {
      return !this.isArchivedView;
    }

    if (this.deployingList) {
      return folder.kind === FolderKinds.Project && !this.isArchivedView;
    }

    if (folder.kind === FolderKinds.Team) {
      return (
        (this.folderIsHoveredOn(folder._id) || this.showAllCheckboxes) && this.hasHigherTeamPermission(folder)
      );
    }

    if (this.isArchivedView) {
      return (
        (this.folderIsHoveredOn(folder._id) || this.showAllCheckboxes) &&
        this.hasHigherProjectsPermission &&
        folder.state !== ItemStates.Active
      );
    }
    return (this.folderIsHoveredOn(folder._id) || this.showAllCheckboxes) && this.hasHigherProjectsPermission;
  }

  private updateAllFoldersSelected() {
    const teamCount = this.folderTree.reduce((count, { teams }) => count + teams.length, 0);
    const selectableProjectsCount = this.folderTree.filter((folder) => this.canSelectProject(folder)).length;
    const selectableFoldersCount = teamCount + selectableProjectsCount;
    this.allFoldersSelected = this.selectedFolders.size === selectableFoldersCount;
  }

  private deselectAllCheckboxes() {
    this.folderSelectionService.deselectAllFolders();
  }

  toggleSelectAllCheckboxes() {
    if (this.allFoldersSelected) {
      this.deselectAllCheckboxes();
      this.moveOrCloseDropdown();
    } else {
      this.checkAllFolders();
      this.reopenDropdown('select-all');
    }
    this.updateAllFoldersSelected();
  }

  projectClicked(project: ProjectWeb) {
    if (this.renamingFolder && this.renamingFolder._id === project._id) {
      return;
    }

    if (this.showAllCheckboxes || this.deployingList || this.deployingTemplate) {
      this.toggleSelectProject(project);
    } else {
      this.navigateToProject.emit(project._id);
    }
  }

  teamClicked(team: TeamWeb) {
    if (this.renamingFolder && this.renamingFolder._id === team._id) {
      return;
    }

    if (this.showAllCheckboxes || this.deployingList || this.deployingTemplate) {
      this.toggleSelectTeam(team);
    } else {
      this.navigateToTeamHandler(team._id);
    }
  }

  async changeItemStates(newFolderState: ItemStates) {
    try {
      if ([ItemStates.Archived, ItemStates.Deleted].includes(newFolderState)) {
        const isAProjectSelected = Array.from(this.selectedFolders.values()).find(
          ({ kind }) => kind === FolderKinds.Project,
        );
        await this.displayConfirmArchiveOrDelete(
          newFolderState,
          this.selectedFolders.size,
          isAProjectSelected ? FolderKinds.Project : FolderKinds.Team,
        );
      }
      const currentTeamHadTheStateUpdated = this.selectedFolders.has(this.activeFolder._id.toString());

      this.updateFolderState(newFolderState, currentTeamHadTheStateUpdated);

      this.deselectAllCheckboxes();
      this.updateAllFoldersSelected();
    } catch {
      // no-op
    }
    this.deselectAllCheckboxes();
    this.moveOrCloseDropdown();
  }

  private displayConfirmArchiveOrDelete(
    newFolderState: ItemStates,
    totalTeamFolders: number,
    kind: FolderKinds.Project | FolderKinds.Team,
  ) {
    const eventMessages = {
      [ItemStates.Archived]: { key: `archive${capitalize(kind)}`, confirmButtonText: 'Archive' },
      [ItemStates.Deleted]: { key: `delete${capitalize(kind)}`, confirmButtonText: 'Delete' },
    };
    const { key, confirmButtonText } = eventMessages[newFolderState];
    const confirmKey = kind === FolderKinds.Team && totalTeamFolders > 1 ? `${key}s` : key;

    return this.confirmService.confirmDelete(confirmKey, { confirmButtonText });
  }

  private updateFolderState(newFolderState: ItemStates, currentTeamUpdated: boolean) {
    this.foldersService
      .changeItemStates(this.currentWorkspace._id, newFolderState, this.selectedFolders, this.folderTree)
      .subscribe({
        next: () => {
          this.updateFolders.emit(currentTeamUpdated);

          if (currentTeamUpdated) {
            this.teamService.setActiveFolderState(newFolderState);
          }

          const toastrMessage = {
            [ItemStates.Active]: 'successfullyRestoredTeams',
            [ItemStates.Archived]: 'successfullyArchivedTeams',
            [ItemStates.Deleted]: 'successfullyDeletedTeams',
          };
          this.toastrService.successByKey(toastrMessage[newFolderState]);

          if (newFolderState === ItemStates.Deleted) {
            void this.navigationService.navigateToParentFolder();
          }

          this.activityService.onActivitiesRefreshRequired.next();
        },
        error: this.handleFolderStateError(newFolderState, cloneDeep(this.selectedFolders)),
      });
  }

  private handleFolderStateError(newFolderState: ItemStates, selectedFolders: unknown) {
    const handlers = {
      [ItemStates.Active]: this.handleRestoreError,
      [ItemStates.Archived]: this.handleUnknownError,
      [ItemStates.Deleted]: this.handleUnknownError,
    };

    return handlers[newFolderState].bind({ ...this, selectedFolders });
  }

  private handleRestoreError(error: any) {
    // case 1: teams selected, the parent project is archived and the user does not have permission to restore it
    const parentProjects = this.folderTree.filter(({ teams }) => {
      const allTeamsIds = teams.map(({ _id }) => _id);
      return allTeamsIds.some((teamId) => this.selectedFolders.has(teamId));
    });

    if (parentProjects.some(({ state }) => state === ItemStates.Archived)) {
      this.toastrService.errorByKey('folderPermissionsCannotRestoreProject');
      return;
    }

    // generic case
    this.handleUnknownError(error);
  }

  private handleUnknownError(error: any) {
    this.toastrService.error(error.error.message);
  }

  trackByFn(item: ProjectWeb | TeamWeb) {
    return item._id;
  }

  get hasHigherProjectsPermission() {
    return this.userControllerMap?.isAdmin || this.userControllerMap?.companies?.[this.currentWorkspace._id];
  }

  hasHigherTeamPermission(team: TeamWeb) {
    const projectId = this.pathService.extractFolderIds(team.path)[1];

    return this.canNavigateToProject(projectId);
  }

  canNavigateToProject(projectId: string) {
    return this.hasHigherProjectsPermission || this.userControllerMap?.projects?.[projectId];
  }

  canSelectProject(project: ProjectWeb) {
    if (!this.hasHigherProjectsPermission) {
      return false;
    }

    if (this.hoveredFolder === project._id) {
      return true;
    }

    const isInCorrectView = !this.isArchivedView || project.state === ItemStates.Archived;

    return isInCorrectView;
  }

  areMultipleProjectFoldersSelected() {
    const selectedProjectFolders = Array.from(this.selectedFolders.values()).filter(
      ({ kind }) => kind === FolderKinds.Project,
    );
    return selectedProjectFolders.length > 1;
  }

  setupI18nMessages() {
    this.activeProjectInArchivedViewWarning = this.i18nService.getMessage(
      'activeProjectInArchivedViewWarning',
    );
  }
}
