import { PlatformLocation, Location, CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Injector } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from '@site-mate/global-web-ui';

import { TemplatesService } from 'app/apps/templates.service';
import { AddEditFormBaseComponent } from 'app/form/edit-form/add-edit-form-base.component';
import { FormEventsService } from 'app/form/form-events.service';
import { FormModule } from 'app/form/form.module';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListsService } from 'app/lists/lists.service';
import { SamService } from 'app/sam/sam.service';
import { SegmentService } from 'app/segment/segment.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { FormValidationService } from 'app/shared/service/form-validation.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { MetaDataService } from 'app/shared/service/meta-data.service';
import { GlobalWebModalModule } from 'app/shared/service/modal/global-web-modal.module';
import { TimingMarkerService } from 'app/shared/service/timing-marker.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-add-action-modal',
  templateUrl: './add-action-modal.component.html',
  styleUrls: ['./add-action-modal.component.scss'],
  imports: [CommonModule, GlobalWebModalModule, ButtonComponent, FormModule],
  standalone: true,
})
export class AddActionComponent extends AddEditFormBaseComponent {
  constructor(
    protected readonly activeModal: NgbActiveModal,
    protected readonly appUtilService: AppUtilService,
    protected readonly toastr: ToastrService,
    protected readonly templatesService: TemplatesService,
    protected readonly confirmService: ConfirmService,
    protected readonly downloaderService: DownloaderService,
    protected readonly i18nService: TmpI18NService,
    protected readonly userService: UserService,
    protected readonly formService: FormService,
    protected readonly errorHandler: ErrorHandler,
    protected readonly metaDataService: MetaDataService,
    protected readonly modal: NgbModal,
    protected readonly listsService: ListsService,
    protected readonly location: Location,
    protected readonly platformLocation: PlatformLocation,
    protected readonly profileService: ProfileService,
    protected readonly timingMarkerService: TimingMarkerService,
    protected readonly segmentService: SegmentService,
    protected readonly formValidationService: FormValidationService,
    protected readonly logicRuleService: LogicRuleService,
    protected readonly hostElement: ElementRef,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly injector: Injector,
    protected readonly formEventsService: FormEventsService,
    protected readonly samService: SamService,
  ) {
    super(
      location,
      platformLocation,
      activeModal,
      i18nService,
      listsService,
      errorHandler,
      toastr,
      confirmService,
      formService,
      modal,
      metaDataService,
      profileService,
      segmentService,
      userService,
      formValidationService,
      logicRuleService,
      hostElement,
      changeDetectorRef,
      templatesService,
      injector,
      formEventsService,
      appUtilService,
      samService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setupBlankAction();
  }

  private setupBlankAction() {
    const currentUser = this.userService.getCurrentUser();
    this.formService.getBlankForm(this.templateId, currentUser).subscribe({
      next: (form) => {
        this.setupForm(form);
        super.setHeaderSettings(form.template);
      },
      error: this.errorHandler.handle.bind(this.errorHandler),
    });
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  onClose() {
    // do nothing
  }

  beforeDismiss() {
    // do nothing
  }

  async saveAction() {
    await this.saveForm();
  }

  deleteAction() {
    void this.confirmService.confirmDelete('confirmDeleteAction').then(() => {
      this.closeModal();
      this.toastr.successByKey('actionDeleteSucceeded');
      // TODO - Implement delete action toast message once we delete through the API
      // this.toastr.errorByKey('actionDeleteFailed');
    });
  }
}
