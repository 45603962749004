import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'cc-activate-account-signature',
  templateUrl: './activate-account-signature.component.html',
})
export class ActivateAccountSignatureComponent {
  signatureCanvasOptions: Record<string, number> = {
    canvasWidth: 280,
    canvasHeight: 60,
  };

  @Input({ required: true }) form!: FormGroup<{ signature: FormControl; name: FormControl }>;
  @Input({ required: true }) userInfo!: { firstName: string; lastName: string };
  @Input() ssoSignUp = { isSSO: false, isActivating: false };

  @Output() onSubmit = new EventEmitter<FormGroup>();
}
