<div class="sign-up-container" [ccHideIntercom]="isMobile">
  <cc-sign-up-construction-background
    *ngIf="stepsWithConstructionBackground.includes(activeStep)"
    [hideLoginText]="stepsWithHiddenLoginText.includes(activeStep)"
  >
    <cc-sign-up-user-info *ngIf="activeStep === signUpSteps.UserInfo"></cc-sign-up-user-info>
    <cc-sign-up-used-email *ngIf="activeStep === signUpSteps.UsedEmail"></cc-sign-up-used-email>
    <cc-sign-up-used-domain *ngIf="activeStep === signUpSteps.UsedDomain"></cc-sign-up-used-domain>
    <cc-sign-up-join-existing-workspace
      *ngIf="activeStep === signUpSteps.JoinExistingWorkspace"
    ></cc-sign-up-join-existing-workspace>
  </cc-sign-up-construction-background>

  <cc-sign-up-sidebar-background *ngIf="stepsWithSidebarBackground.includes(activeStep)">
    <cc-business-attributes-selector
      *ngIf="activeStep === signUpSteps.BusinessAttributesSelector"
    ></cc-business-attributes-selector>
    <cc-sign-up-user-phone *ngIf="activeStep === signUpSteps.MobileNumber"></cc-sign-up-user-phone>
    <cc-human-verification *ngIf="activeStep === signUpSteps.HumanVerification"></cc-human-verification>
  </cc-sign-up-sidebar-background>
</div>
