import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { IntercomService } from 'app/intercom/intercom.service';
import { SegmentService } from 'app/segment/segment.service';

@Component({
  selector: 'cc-nav-helper-widget',
  templateUrl: './nav-helper-widget.component.html',
  styleUrls: ['./nav-helper-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavHelperWidgetComponent {
  @Input() companyHasFreePlan: boolean;

  readonly navigationEvents = {
    helpMenu: 'Help Menu',
    watchVideoTutorials: 'Watch video tutorials',
    pricingInfo: 'Pricing information',
    trialInfo: 'Trial information',
    chatWithUs: 'Chat with us',
    bookACall: 'Book a call',
    newFeatures: 'New',
    roadmap: 'Roadmap',
  };

  constructor(
    private readonly intercomService: IntercomService,
    private readonly segmentService: SegmentService,
  ) {}

  openLiveChat() {
    this.trackNavEvent(this.navigationEvents.chatWithUs);
    this.intercomService.open();
  }

  trackNavEvent(context: string) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.NavLinkClicked, { Context: context }),
      this.segmentService,
    );
  }
}
