<div class="sm-flex sm-flex-row sm-gap-0">
  <button
    data-test="monthly-button"
    type="button"
    gw-button
    class="!sm-rounded-br-none !sm-rounded-tr-none"
    [color]="subscriptionTerm === SubscriptionTerms.Monthly ? 'blue' : 'grey'"
    text="Monthly"
    (click)="setSubscriptionTerm(SubscriptionTerms.Monthly)"
  ></button>
  <button
    data-test="annual-button"
    type="button"
    gw-button
    class="!sm-rounded-bl-none !sm-rounded-tl-none"
    [color]="subscriptionTerm === SubscriptionTerms.OneYear ? 'blue' : 'grey'"
    text="Annual"
    (click)="setSubscriptionTerm(SubscriptionTerms.OneYear)"
  ></button>
</div>
