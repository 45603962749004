<div ngbDropdown>
  <button
    type="button"
    class="button-regular button-white help-menu"
    ngbDropdownToggle
    (click)="trackNavEvent(navigationEvents.helpMenu)"
  >
    <i class="cc-icon cc-icon-question light" aria-hidden="true"></i>
    <span i18n class="sm-text-sm sm-font-medium">Help</span>
  </button>

  <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="Download pdfs">
    <li>
      <a
        class="dropdown-item tutorials-item"
        target="_blank"
        href="https://intercom.help/dashpivot/en/"
        ngbDropdownItem
        (click)="trackNavEvent(navigationEvents.watchVideoTutorials)"
      >
        <i class="cc-icon cc-icon-video dark" aria-hidden="true"></i>
        <span i18n>Watch video tutorials</span>
      </a>
    </li>

    <ng-container *ngIf="!companyHasFreePlan">
      <li>
        <a
          class="dropdown-item new-feature-item"
          target="_blank"
          href="https://intercom.help/dashpivot/en/collections/2074918-release-notes"
          (click)="trackNavEvent(navigationEvents.newFeatures)"
        >
          <i class="cc-icon cc-icon-gift light" aria-hidden="true"></i>
          <span i18n>New</span>
        </a>
      </li>
      <li>
        <a
          class="dropdown-item roadmap-item"
          target="_blank"
          href="https://portal.productboard.com/wm6whau7st9md1ulztigrv4n/tabs/8-scheduled"
          (click)="trackNavEvent(navigationEvents.roadmap)"
        >
          <i class="cc-icon cc-icon-roadmap light" aria-hidden="true"></i>
          <span i18n>Roadmap</span>
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="companyHasFreePlan">
      <li>
        <a
          class="dropdown-item trial-item"
          target="_blank"
          href="https://intercom.help/dashpivot/en/articles/4793411-trial-information"
          ngbDropdownItem
          (click)="trackNavEvent(navigationEvents.trialInfo)"
        >
          <i class="cc-icon cc-icon-information dark" aria-hidden="true"></i>
          <span i18n>Trial information</span>
        </a>
      </li>
      <li>
        <a
          class="dropdown-item booking-item"
          ngbDropdownItem
          target="_blank"
          href="https://calendly.com/lance-sitemate/10min?back=1&month=2020-12"
          (click)="trackNavEvent(navigationEvents.bookACall)"
        >
          <i class="cc-icon cc-icon-calendar dark" aria-hidden="true"></i>
          <span i18n>Book a call</span>
        </a>
      </li>
      <li>
        <a class="dropdown-item chat-item" ngbDropdownItem (click)="openLiveChat()">
          <i class="cc-icon cc-icon-chat dark" aria-hidden="true"></i>
          <span i18n>Chat with us</span>
        </a>
      </li>
      <li>
        <a
          class="dropdown-item pricing-item"
          target="_blank"
          href="https://sitemate.com/software/dashpivot/pricing/"
          ngbDropdownItem
          (click)="trackNavEvent(navigationEvents.pricingInfo)"
        >
          <i class="cc-icon cc-icon-wallet dark" aria-hidden="true"></i>
          <span i18n>Pricing information</span>
        </a>
      </li>
    </ng-container>
  </ul>
</div>
