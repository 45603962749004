import { startCase } from 'lodash-es';

import { PlanTypeLimits } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

export enum PlanFeatureHighlightKinds {
  FieldsPerTemplate = 'fields-per-template',
  PropertiesPerList = 'properties-per-list',
  ApprovalSignatureConditions = 'approval-signature-conditions',
  ApprovalSignatureLogic = 'approval-signature-logic',
  DashpivotVisitors = 'dashpivot-visitors',
  SitematePaidUsers = 'sitemate-paid-users',
  MultipleWorkspacesAndBrands = 'multiple-workspaces-and-brands',
  SingleSignOn = 'single-sign-on',
}

export enum PlanFeatureHighlightTypes {
  Limit = 'limit',
  Inclusion = 'inclusion',
}

export interface IPlanPreview {
  planType: CompanyPlanTypes;
  name: string;
  summary: string;
  featureHighlights: IPlanFeatureHighlight[];
  isDisabled?: boolean;
}

export interface IPlanFeatureHighlight {
  kind: PlanFeatureHighlightKinds;
  description: string;
  hasExceededLimit?: boolean;
  type: PlanFeatureHighlightTypes;
  tooltip: string;
  isExcluded?: boolean;
}

export const generateHighlight = {
  fieldsPerTemplate(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const limit = PlanTypeLimits.Templates.items[planType];

    return {
      kind: PlanFeatureHighlightKinds.FieldsPerTemplate,
      description: `${limit} fields per template`,
      type: PlanFeatureHighlightTypes.Limit,
      tooltip: `At least one of your templates contains more than ${limit} fields.`,
    };
  },
  propertiesPerList(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const limit = PlanTypeLimits.Lists.properties[planType];

    return {
      kind: PlanFeatureHighlightKinds.PropertiesPerList,
      description: `${limit} properties per list`,
      type: PlanFeatureHighlightTypes.Limit,
      tooltip: `At least one of your lists contains more than ${limit} properties.`,
    };
  },
  approvalSignatureConditions(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const limit = PlanTypeLimits.Templates.complementaryLogic[planType];

    let tooltip = `At least one of your templates contains more than ${limit} approval signature logic conditions.`;
    let description = `${limit} approval signature conditions`;
    let isExcluded = false;
    if (planType === CompanyPlanTypes.Standard) {
      tooltip = `At least one of your templates contains approval signature logic conditions.`;
      description = 'Approval signature conditions';
      isExcluded = true;
    }

    return {
      kind: PlanFeatureHighlightKinds.ApprovalSignatureConditions,
      description,
      type: PlanFeatureHighlightTypes.Limit,
      tooltip,
      isExcluded,
    };
  },
  approvalSignatureLogic(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const isExcluded = planType === CompanyPlanTypes.Standard;

    return {
      kind: PlanFeatureHighlightKinds.ApprovalSignatureLogic,
      description: 'Approval signature logic',
      type: PlanFeatureHighlightTypes.Inclusion,
      tooltip: 'At least one of your templates contains approval signature logic.',
      isExcluded,
    };
  },
  dashpivotVisitors(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const isExcluded = planType === CompanyPlanTypes.Standard;

    return {
      kind: PlanFeatureHighlightKinds.DashpivotVisitors,
      description: 'Dashpivot Visitors',
      type: PlanFeatureHighlightTypes.Inclusion,
      tooltip: 'There are Dashpivot Visitors in your account already.',
      isExcluded,
    };
  },
  sitematePaidUsers(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const isExcluded = planType === CompanyPlanTypes.Standard;

    return {
      kind: PlanFeatureHighlightKinds.SitematePaidUsers,
      description: 'Sitemate Paid Users',
      type: PlanFeatureHighlightTypes.Inclusion,
      tooltip: 'There are Sitemate Paid Users in your account already.',
      isExcluded,
    };
  },
  multipleWorkspacesAndBrands(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const isExcluded = planType === CompanyPlanTypes.Standard;

    return {
      kind: PlanFeatureHighlightKinds.MultipleWorkspacesAndBrands,
      description: 'Multiple workspaces/brands',
      type: PlanFeatureHighlightTypes.Inclusion,
      tooltip: 'Your account contains multiple workspaces.',
      isExcluded,
    };
  },
  singleSignOn(planType: CompanyPlanTypes): IPlanFeatureHighlight {
    const isExcluded = planType === CompanyPlanTypes.Standard;

    return {
      kind: PlanFeatureHighlightKinds.SingleSignOn,
      description: 'Single Sign-On (SSO)',
      type: PlanFeatureHighlightTypes.Inclusion,
      tooltip: 'SSO is enabled on your account already.',
      isExcluded,
    };
  },
};

export function generatePlanPreviews(): IPlanPreview[] {
  return [
    {
      planType: CompanyPlanTypes.Standard,
      name: startCase(CompanyPlanTypes.Standard),
      summary: 'This plan suits teams who need better ways to manage documents and processes internally.',
      featureHighlights: [
        generateHighlight.fieldsPerTemplate(CompanyPlanTypes.Standard),
        generateHighlight.propertiesPerList(CompanyPlanTypes.Standard),
        generateHighlight.approvalSignatureConditions(CompanyPlanTypes.Standard),
        generateHighlight.approvalSignatureLogic(CompanyPlanTypes.Standard),
        generateHighlight.dashpivotVisitors(CompanyPlanTypes.Standard),
        generateHighlight.sitematePaidUsers(CompanyPlanTypes.Standard),
        generateHighlight.multipleWorkspacesAndBrands(CompanyPlanTypes.Standard),
        generateHighlight.singleSignOn(CompanyPlanTypes.Standard),
      ],
    },
    {
      planType: CompanyPlanTypes.Pro,
      name: startCase(CompanyPlanTypes.Pro),
      summary:
        'This plan is best suited for companies who collaborate with external contractors and parties.',
      featureHighlights: [
        generateHighlight.fieldsPerTemplate(CompanyPlanTypes.Pro),
        generateHighlight.propertiesPerList(CompanyPlanTypes.Pro),
        generateHighlight.approvalSignatureConditions(CompanyPlanTypes.Pro),
        generateHighlight.approvalSignatureLogic(CompanyPlanTypes.Pro),
        generateHighlight.dashpivotVisitors(CompanyPlanTypes.Pro),
        generateHighlight.sitematePaidUsers(CompanyPlanTypes.Pro),
        generateHighlight.multipleWorkspacesAndBrands(CompanyPlanTypes.Pro),
        generateHighlight.singleSignOn(CompanyPlanTypes.Pro),
      ],
    },
    {
      planType: CompanyPlanTypes.Premium,
      name: startCase(CompanyPlanTypes.Premium),
      summary: 'Our premium plan allows you to scale with higher limits than Pro whilst staying secure.',
      featureHighlights: [
        generateHighlight.fieldsPerTemplate(CompanyPlanTypes.Premium),
        generateHighlight.propertiesPerList(CompanyPlanTypes.Premium),
        generateHighlight.approvalSignatureConditions(CompanyPlanTypes.Premium),
        generateHighlight.approvalSignatureLogic(CompanyPlanTypes.Premium),
        generateHighlight.dashpivotVisitors(CompanyPlanTypes.Premium),
        generateHighlight.sitematePaidUsers(CompanyPlanTypes.Premium),
        generateHighlight.multipleWorkspacesAndBrands(CompanyPlanTypes.Premium),
        generateHighlight.singleSignOn(CompanyPlanTypes.Premium),
      ],
    },
    {
      planType: CompanyPlanTypes.Platinum,
      name: startCase(CompanyPlanTypes.Platinum),
      summary: 'Our most advanced plan enables you to build complex, custom, and advanced systems.',
      featureHighlights: [
        generateHighlight.fieldsPerTemplate(CompanyPlanTypes.Platinum),
        generateHighlight.propertiesPerList(CompanyPlanTypes.Platinum),
        generateHighlight.approvalSignatureConditions(CompanyPlanTypes.Platinum),
        generateHighlight.approvalSignatureLogic(CompanyPlanTypes.Platinum),
        generateHighlight.dashpivotVisitors(CompanyPlanTypes.Platinum),
        generateHighlight.sitematePaidUsers(CompanyPlanTypes.Platinum),
        generateHighlight.multipleWorkspacesAndBrands(CompanyPlanTypes.Platinum),
        generateHighlight.singleSignOn(CompanyPlanTypes.Platinum),
      ],
    },
  ];
}
