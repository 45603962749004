<ol>
  <li data-test="check-all-folders-container" *ngIf="hasHigherProjectsPermission">
    <button
      type="button"
      class="folder animate-fade-in sm-w-full sm-flex-row"
      data-test="check-all-folders"
      [disabled]="isArchivedView && (deployingList || deployingTemplate)"
      (click)="toggleSelectAllCheckboxes()"
      *ngIf="folderTree.length"
    >
      <div class="folder-icon-container">
        <button
          type="button"
          class="checkbox-container"
          [class.checked]="allFoldersSelected"
          role="checkbox"
          [disabled]="isArchivedView && (deployingList || deployingTemplate)"
          [attr.aria-checked]="allFoldersSelected"
          aria-labelledby="select-all-label"
        >
          <i class="cc-icon cc-icon-check" *ngIf="allFoldersSelected" aria-hidden="true"></i>
        </button>
      </div>
      <span id="select-all-label">Select All</span>

      <cc-dropdown
        class="!sm-ml-auto"
        container="body"
        placement="right-top auto"
        id="select-all"
        [autoClose]="false"
        focusOnOpen="none"
        size="lg"
        (stateChange)="onDropdownStateChange($event, 'select-all')"
      >
        <div class="sm-h-4 sm-w-4" dropdownToggle></div>

        <cc-dropdown-item
          *ngIf="!isArchivedView"
          data-test="archive-all"
          (click)="changeItemStates(ItemStates.Archived)"
          dropdownItem
        >
          <div class="navbar-dropdown-item">
            <i class="cc-icon cc-icon-archive" aria-hidden="true"></i>
            <span *ngIf="selectedFolders.size">
              Archive {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                selectedFolders.size === 1 ? '' : 's'
              }}</span
            >
          </div>
        </cc-dropdown-item>

        <cc-dropdown-item
          *ngIf="isArchivedView"
          data-test="delete-all"
          (click)="changeItemStates(ItemStates.Deleted)"
          dropdownItem
        >
          <div class="navbar-dropdown-item">
            <i class="cc-icon cc-icon-delete" aria-hidden="true"></i>
            <span
              >Delete {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                selectedFolders.size === 1 ? '' : 's'
              }}</span
            >
          </div>
        </cc-dropdown-item>

        <cc-dropdown-item
          *ngIf="isArchivedView"
          data-test="restore-all"
          (click)="changeItemStates(ItemStates.Active)"
          dropdownItem
        >
          <div class="navbar-dropdown-item">
            <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
            <span
              >Restore {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                selectedFolders.size === 1 ? '' : 's'
              }}</span
            >
          </div>
        </cc-dropdown-item>
      </cc-dropdown>
    </button>
  </li>

  <!-- Project folders -->
  <li
    *ngFor="
      let project of folderTree | slice: 0 : getVisibleFoldersCount(currentWorkspace?._id);
      trackBy: trackByFn
    "
    data-test="project-folder-container"
  >
    <span
      [disablePopover]="!isArchivedView"
      [ngbPopover]="activeProjectInArchivedViewWarning"
      placement="right-top"
      popoverClass="preview-popover workspace-warning"
      triggers="mouseenter:mouseleave"
      class="popover-container"
    >
      <div
        class="folder sm-w-full sm-flex-row"
        [class.!sm-py-0]="renamingFolder === project"
        [class.!sm-pr-0]="renamingFolder === project"
        [class.authorised]="canNavigateToProject(project._id)"
        [class.selected]="activeFolder?._id === project._id"
        [class.dropdown-open]="openDropdownFolderId === project._id"
        data-test="project-folder"
        [disabled]="(project.state !== ItemStates.Archived || deployingList) && isArchivedView"
        (click)="projectClicked(project)"
        (keydown.space)="projectClicked(project)"
        (focusin)="changeHoveredFolder(project._id)"
        (mouseenter)="changeHoveredFolder(project._id)"
        (mouseleave)="changeHoveredFolder(null)"
      >
        <div class="folder-icon-container">
          <i
            class="cc-icon"
            [class.cc-icon-folder-closed-solid]="
              (!showCheckbox(project) || showFolderIcon(project._id)) && project.state === ItemStates.Active
            "
            [class.cc-icon-archive]="
              (!showCheckbox(project) || showFolderIcon(project._id) || deployingList) &&
              project.state === ItemStates.Archived
            "
            aria-hidden="true"
            [attr.aria-checked]="selectedFolders.has(project._id)"
          ></i>
          <ng-container>
            <button
              type="button"
              class="checkbox-container"
              [class.checked]="selectedFolders.has(project._id)"
              (click)="toggleSelectProject(project); $event.stopPropagation()"
              *ngIf="showCheckbox(project)"
              role="checkbox"
              data-test="toggle-select-project"
              [attr.aria-checked]="selectedFolders.has(project._id)"
            >
              <i
                class="cc-icon cc-icon-check"
                *ngIf="selectedFolders.has(project._id)"
                aria-hidden="true"
              ></i>
            </button>
          </ng-container>
        </div>
        <cc-folder-inline-input
          #inlineInput
          *ngIf="renamingFolder === project; else projectLabel"
          (onBlur)="setRenamingFolder(null)"
          inputValue="{{ project.name }}"
          [submit]="onRenameProjectHandler(project)"
          class="sm-w-full"
          data-test="project-inline-input"
        ></cc-folder-inline-input>
        <ng-template #projectLabel>
          <span class="sm-truncate sm-pr-4" [title]="project.name" data-test="project-label">{{
            project.name
          }}</span>
        </ng-template>

        <cc-dropdown
          class="!sm-ml-auto"
          container="body"
          placement="right-top auto"
          [id]="project?._id"
          [autoClose]="false"
          focusOnOpen="none"
          size="lg"
          *ngIf="hasHigherProjectsPermission"
          (stateChange)="onDropdownStateChange($event, project._id)"
        >
          <div dropdownToggle></div>

          <cc-dropdown-item
            *ngIf="!isArchivedView"
            data-test="archive-project"
            (click)="changeItemStates(ItemStates.Archived)"
            dropdownItem
          >
            <div class="navbar-dropdown-item">
              <i class="cc-icon cc-icon-archive" aria-hidden="true"></i>
              <span *ngIf="selectedFolders.size">
                Archive {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                  selectedFolders.size === 1 ? '' : 's'
                }}</span
              >
            </div>
          </cc-dropdown-item>

          <cc-dropdown-item
            *ngIf="isArchivedView"
            data-test="delete-project"
            (click)="changeItemStates(ItemStates.Deleted)"
            dropdownItem
          >
            <div class="navbar-dropdown-item">
              <i class="cc-icon cc-icon-delete" aria-hidden="true"></i>
              <span
                >Delete {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                  selectedFolders.size === 1 ? '' : 's'
                }}</span
              >
            </div>
          </cc-dropdown-item>

          <cc-dropdown-item
            *ngIf="isArchivedView"
            data-test="restore-project"
            (click)="changeItemStates(ItemStates.Active)"
            dropdownItem
          >
            <div class="navbar-dropdown-item">
              <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
              <span
                >Restore {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                  selectedFolders.size === 1 ? '' : 's'
                }}</span
              >
            </div>
          </cc-dropdown-item>
          <cc-dropdown-item
            *ngIf="project._id && !isRenamingProjectFolderDisabled"
            data-test="edit-project"
            (click)="$event.stopPropagation(); renameFolderDropdownItemHandler(project)"
            dropdownItem
          >
            <div class="navbar-dropdown-item">
              <i class="cc-icon cc-icon-pencil" aria-hidden="true"></i>
              <span *ngIf="selectedFolders.size" i18n>Rename Project Folder</span>
            </div>
          </cc-dropdown-item>
        </cc-dropdown>
      </div>
    </span>

    <!-- Team folders -->
    <ol class="sm-pl-4">
      <li
        *ngFor="let team of project.teams | slice: 0 : getVisibleFoldersCount(project._id)"
        data-test="team-folder-container"
      >
        <div
          (click)="teamClicked(team)"
          (keydown.space)="teamClicked(team)"
          class="folder authorised sm-w-full"
          [class.!sm-py-0]="renamingFolder === team"
          [class.!sm-pr-0]="renamingFolder === team"
          [class.dropdown-open]="openDropdownFolderId === team._id"
          [class.selected]="activeFolder?._id === team._id"
          (focusin)="changeHoveredFolder(team._id)"
          (mouseenter)="changeHoveredFolder(team._id)"
          (mouseleave)="changeHoveredFolder(null)"
          [disabled]="deployingList || (deployingTemplate && isArchivedView)"
          data-test="team-folder"
        >
          <div class="folder-icon-container">
            <i
              class="cc-icon"
              [class.cc-icon-folder-closed-solid]="
                (!showCheckbox(team) || showFolderIcon(team._id)) && team.state === ItemStates.Active
              "
              [class.cc-icon-archive]="
                (!showCheckbox(team) || showFolderIcon(team._id)) && team.state === ItemStates.Archived
              "
              aria-hidden="true"
            ></i>
            <ng-container>
              <button
                type="button"
                class="checkbox-container"
                [class.checked]="selectedFolders.has(team._id)"
                (click)="toggleSelectTeam(team); $event.stopPropagation()"
                *ngIf="showCheckbox(team)"
                role="checkbox"
                data-test="toggle-select-team"
                [attr.aria-checked]="selectedFolders.has(team._id)"
              >
                <i class="cc-icon cc-icon-check" *ngIf="selectedFolders.has(team._id)" aria-hidden="true"></i>
              </button>
            </ng-container>
          </div>
          <cc-folder-inline-input
            #inlineInput
            *ngIf="renamingFolder === team; else teamLabel"
            (onBlur)="setRenamingFolder(null)"
            inputValue="{{ team.name }}"
            [submit]="onRenameTeamHandler(team)"
            class="sm-w-full"
            data-test="team-inline-input"
          ></cc-folder-inline-input>
          <ng-template #teamLabel>
            <span class="sm-truncate sm-pr-4" [title]="team.name" data-test="team-label">{{
              team.name
            }}</span>
          </ng-template>
          <cc-dropdown
            class="!sm-ml-auto"
            container="body"
            placement="right-top auto"
            [id]="team?._id"
            [autoClose]="false"
            focusOnOpen="none"
            size="lg"
            *ngIf="canNavigateToProject(project._id)"
            (stateChange)="onDropdownStateChange($event, team._id)"
          >
            <div dropdownToggle></div>
            <cc-dropdown-item
              *ngIf="!isArchivedView"
              data-test="delete-team"
              (click)="changeItemStates(ItemStates.Archived)"
              dropdownItem
            >
              <div class="navbar-dropdown-item">
                <i class="cc-icon cc-icon-archive" aria-hidden="true"></i>
                <span *ngIf="selectedFolders.size">
                  Archive {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                    selectedFolders.size === 1 ? '' : 's'
                  }}</span
                >
              </div>
            </cc-dropdown-item>
            <cc-dropdown-item
              *ngIf="isArchivedView"
              data-test="delete-team"
              (click)="changeItemStates(ItemStates.Deleted)"
              dropdownItem
            >
              <div class="navbar-dropdown-item">
                <i class="cc-icon cc-icon-delete" aria-hidden="true"></i>
                <span
                  >Delete {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                    selectedFolders.size === 1 ? '' : 's'
                  }}</span
                >
              </div>
            </cc-dropdown-item>
            <cc-dropdown-item
              *ngIf="isArchivedView"
              data-test="restore-team"
              (click)="changeItemStates(ItemStates.Active)"
              dropdownItem
            >
              <div class="navbar-dropdown-item">
                <i class="cc-icon cc-icon-undo" aria-hidden="true"></i>
                <span
                  >Restore {{ selectedFolders.size === 1 ? '' : selectedFolders.size }} folder{{
                    selectedFolders.size === 1 ? '' : 's'
                  }}</span
                >
              </div>
            </cc-dropdown-item>
            <cc-dropdown-item
              *ngIf="team._id && selectedFolders.size === 1"
              data-test="edit-team"
              (click)="$event.stopPropagation(); renameFolderDropdownItemHandler(team)"
              dropdownItem
            >
              <div class="navbar-dropdown-item">
                <i class="cc-icon cc-icon-pencil" aria-hidden="true"></i>
                <span *ngIf="selectedFolders.size" i18n>Rename Team Folder</span>
              </div>
            </cc-dropdown-item>
          </cc-dropdown>
        </div>
      </li>
      <li
        *ngIf="project.teams.length > getVisibleFoldersCount(project._id)"
        class="sm-flex sm-justify-center sm-pr-4"
      >
        <button
          type="button"
          class="increase-visible-folders-count-button"
          (click)="increaseVisibleSubfoldersCount(project._id)"
          data-test="load-more-teams"
          i18n
        >
          Load more teams
        </button>
      </li>
      <li data-test="add-team-folder" *ngIf="canNavigateToProject(project._id) && !isArchivedView">
        <cc-folder-inline-creator
          #teamInlineCreator
          [submit]="onAddTeamHandler(project._id)"
          label="New Team Folder"
          placeholder="New Team Folder..."
          [parentId]="project._id"
        ></cc-folder-inline-creator>
      </li>
    </ol>
  </li>
  <li
    *ngIf="folderTree.length > getVisibleFoldersCount(currentWorkspace?._id)"
    class="sm-flex sm-justify-center"
  >
    <button
      type="button"
      class="increase-visible-folders-count-button"
      (click)="increaseVisibleSubfoldersCount(currentWorkspace?._id)"
      data-test="load-more-projects"
      i18n
    >
      Load more projects
    </button>
  </li>
  <li data-test="add-project-folder" *ngIf="hasHigherProjectsPermission && !isArchivedView">
    <cc-folder-inline-creator
      [submit]="onAddProjectHandler(currentWorkspace._id)"
      label="New Project Folder"
      placeholder="New Project Folder..."
      [parentId]="currentWorkspace._id"
    ></cc-folder-inline-creator>
  </li>
</ol>
