<form
  class="form animate-fade-in sign-up-form-container"
  #userPhoneForm="ngForm"
  name="userPhoneForm"
  (ngSubmit)="submitDetails(userPhoneForm)"
>
  <h1 class="heading-text" data-test="heading-text" i18n>Add your phone number</h1>
  <p class="sm-mb-4 sm-text-default-text">
    Your information will be kept private and secure, it wont’ be shared with any third parties.
  </p>
  <div class="form-group">
    <div class="input-group">
      <div
        class="input-group-prepend country-code d-flex align-items-center"
        [class.error-state]="hasCountryCodeError()"
      >
        <ng-select
          (change)="setContinentCode()"
          id="signup-country"
          class="ng-select signup-country"
          name="country-list"
          [items]="countries"
          bindValue="value"
          bindLabel="name"
          placeholder="Select"
          i18n-placeholder="Select"
          [(ngModel)]="selectedCountry"
          filterPlaceholder="Type to search..."
          i18n-filterPlaceholder="Type to search..."
          (close)="handleCountrySelect()"
          [clearable]="false"
          [selectOnTab]="true"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="p-1 country-option f16">
              <div class="flag-icon flag {{ item.ccode.toLowerCase() }}"></div>
              <div>({{ selectedCountryAreaCode }})</div>
            </div>
          </ng-template>
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <div class="country-option f16">
              <div class="flag-icon flag {{ item.ccode.toLowerCase() }}"></div>
              <div class="country-text">{{ item.name }}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <input
        [(ngModel)]="model.mobile"
        type="tel"
        class="form-control form-input"
        [class.error-state]="hasFormError(mobile) || !hasValidPhoneNumber"
        id="signup-mobile"
        placeholder="Phone number..."
        i18n-placeholder="Phone number..."
        #mobile="ngModel"
        name="mobile"
        required
        pattern="[0-9]*"
        digitOnly
        (ngModelChange)="validatePhoneNumber()"
      />
    </div>
    <div
      *ngIf="hasFormError(mobile) || hasCountryCodeError() || !hasValidPhoneNumber"
      class="sm-mt-1 sm-text-xs sm-text-default-red"
      id="mobile-danger"
    >
      <div i18n *ngIf="mobile.errors?.required || hasCountryCodeError()">Required</div>
      <div *ngIf="!mobile.errors?.required && !hasValidPhoneNumber" i18n>
        Invalid mobile number - incorrect length.
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="checkbox abc-checkbox abc-checkbox-primary d-flex align-items-center">
      <input
        class="styled form-checkbox"
        type="checkbox"
        id="signup-terms"
        name="signup-terms"
        required
        [(ngModel)]="acceptedTnC"
      />
      <label class="default-label terms-label checkbox-label sm-cursor-pointer" for="signup-terms">
        I agree to the
        <a
          [href]="
            selectedCountryContinentCode === continentCodes.Europe
              ? urlMap[continentCodes.Europe].privacyPolicy
              : urlMap.default.privacyPolicy
          "
          target="_blank"
          rel="noopener"
          class="sm-underline"
          data-test="privacy-policy"
        >
          Privacy Policy</a
        >
        &
        <a
          [href]="
            selectedCountryContinentCode === continentCodes.Europe
              ? urlMap[continentCodes.Europe].termsOfService
              : urlMap.default.termsOfService
          "
          target="_blank"
          rel="noopener"
          class="sm-underline"
          data-test="terms-of-service"
          >Terms of Service</a
        >
      </label>
    </div>
    <div class="sm-mt-1 sm-text-xs sm-text-default-red" i18n *ngIf="userPhoneForm.submitted && !acceptedTnC">
      Please accept the Privacy Policy and Terms of Service.
    </div>
  </div>
  <div class="btn-submit-container">
    <button
      type="submit"
      id="btn-submit-form-2"
      data-test="submit-user-phone-form"
      class="button-large button-blue sm-text-white"
      ccSegmentCTA
      ctaContext="Sign up Page Phone Number"
      [ccBusyButton]="isProcessing"
      [class.sm-opacity-60]="!userPhoneForm.valid || hasCountryCodeError() || !hasValidPhoneNumber"
    >
      <span i18n>Create account</span>
    </button>
    <p class="sm-mt-4 sm-text-xs sm-text-secondary-text" data-test="recaptcha-terms">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">Privacy Policy</a>
      and
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener">Terms of Service</a>
      apply.
    </p>
  </div>
</form>
