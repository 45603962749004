<div class="sm-flex sm-flex-col sm-items-center sm-justify-center sm-space-y-4 sm-text-center sm-text-sm">
  <i class="cc-icon cc-icon-users" aria-hidden="true"></i>
  <p i18n>
    Please contact your company or site administrator and ask them to invite you to the existing Dashpivot
    workspace using your work email address.
  </p>
  <p i18n>You can send them this article on how to invite you:</p>
  <a
    href="https://intercom.help/dashpivot/en/articles/3199677-adding-users"
    target="_blank"
    rel="noopener"
    class="sm-rounded-md sm-bg-default-blue sm-p-2 sm-text-white hover:sm-text-white hover:sm-no-underline"
    data-test="invite-users-link"
    i18n
    >How to invite users</a
  >
</div>
