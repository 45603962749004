import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ButtonComponent } from '@site-mate/global-web-ui';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

import { PlanPreviewComponent } from '../plan-preview/plan-preview.component';
import { generatePlanPreviews, IPlanPreview } from '../plan-preview-data';

@Component({
  selector: 'cc-select-plan-step',
  templateUrl: './select-plan-step.component.html',
  styleUrls: ['./select-plan-step.component.scss'],
  standalone: true,
  imports: [CommonModule, PlanPreviewComponent, ButtonComponent],
})
export class SelectPlanStepComponent implements OnInit {
  @Output() planSelected = new EventEmitter<CompanyPlanTypes>();
  plans = generatePlanPreviews();
  hasDisabledPlans = false;

  ngOnInit(): void {
    this.initDisabledPlans();
  }

  selectPlan(plan: IPlanPreview) {
    this.planSelected.emit(plan.planType);
  }

  initDisabledPlans() {
    this.hasDisabledPlans = this.plans.some((plan) => plan.isDisabled);
  }
}
