import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { TooltipDirective } from '@site-mate/global-web-ui';

import { RegexpMatchers } from 'app/shared/model/regexp-matches.model';

@Component({
  selector: 'cc-folder-inline-input',
  templateUrl: './folder-inline-input.component.html',
  styleUrls: [],
})
export class FolderInlineInputComponent {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  readonly inputValueValidator = RegexpMatchers.doNotAcceptWithoutAlphanumeric;

  loading = false;
  invalidInput = false;

  @Input() label = 'Create/Update';
  @Input() placeholder = 'Name...';
  @Input() inputValue = '';
  @Input() submit: (input: string) => Promise<void>;
  @Output() onBlur = new EventEmitter();

  @ViewChild('inputRef') inputRef: ElementRef | null = null;
  @ViewChild('labelRef') labelRef: ElementRef | null = null;
  @ViewChildren(TooltipDirective) tooltipDirectives: QueryList<TooltipDirective>;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent) {
    if (!this.labelRef.nativeElement.contains(event.target)) {
      if (this.inputValue !== '') {
        void this.handleSubmission();
      } else {
        this.handleBlur();
      }
    }
  }

  focus() {
    if (this.inputRef) {
      this.inputRef.nativeElement.focus();
    }
  }

  handleInputFocus() {
    this.tooltipDirectives.forEach((tooltipDirective) => {
      if (tooltipDirective.tooltipRef) {
        tooltipDirective.tooltipRef.instance.animationState = 'hidden';
      }
    });
    this.invalidInput = false;
  }

  handleBlur() {
    this.onBlur.emit();
  }

  setLoading(value: boolean) {
    this.loading = value;
    this.changeDetectorRef.detectChanges();
  }

  setInvalidInput(value: boolean) {
    this.invalidInput = value;
  }

  reset() {
    this.setLoading(false);
    this.invalidInput = false;
    this.inputValue = '';
    this.handleBlur();
  }

  async handleSubmission() {
    this.setLoading(true);

    if (!new RegExp(this.inputValueValidator).test(this.inputValue)) {
      this.setLoading(false);

      if (!this.invalidInput) {
        this.setInvalidInput(true);
      } else {
        this.reset();
      }
      return;
    }

    try {
      await this.submit(this.inputValue);
    } finally {
      this.reset();
    }
  }
}
