import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { isEqual } from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, first } from 'rxjs/operators';

import { TemplateDeploymentService } from 'app/apps/template-deployment.service';
import { ListDeploymentService } from 'app/lists/list-deployment.service';
import { DeploymentModes } from 'app/shared/model/lists/deployment-modes.model';
import { PopoverComponent } from 'app/ui-components/popover/popover.component';

import { FolderSelectionService } from './folder-selection.service';

@Component({
  selector: 'cc-deploy-mode-popover',
  templateUrl: './deploy-mode-popover.component.html',
  styleUrls: ['./deploy-mode-popover.component.scss'],
})
export class DeployModePopover implements AfterViewInit, OnDestroy {
  private readonly unsubscribe = new Subject<void>();
  private stopObservingChanges: Subject<void>;
  @ViewChild(PopoverComponent) popoverComponent: PopoverComponent;
  listNames: string[] = [];
  templateNames: string[] = [];
  activeListDeployMode: DeploymentModes;
  activeTemplateDeployMode: DeploymentModes;
  haveChangesBeenMade: boolean;
  applyingChanges = false;
  buttonLabel: string;
  initialSelectedFolderIds = new Set<string>();

  private readonly listLabels = {
    [DeploymentModes.BulkDeploy]: 'Deploy Lists',
    [DeploymentModes.BulkUndeploy]: 'Undeploy Lists',
    [DeploymentModes.Management]: 'Apply Changes',
  };

  private readonly templateLabels = {
    [DeploymentModes.BulkDeploy]: 'Deploy Templates',
    [DeploymentModes.BulkUndeploy]: 'Undeploy Templates',
    [DeploymentModes.Management]: 'Apply Changes',
  };

  readonly deployModes = DeploymentModes;

  constructor(
    private readonly listDeploymentService: ListDeploymentService,
    private readonly templateDeploymentService: TemplateDeploymentService,
    private readonly folderSelectionService: FolderSelectionService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    combineLatest([
      this.listDeploymentService.activeDeployMode$,
      this.templateDeploymentService.activeDeployMode$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([listDeployMode, templateDeployMode]) => {
        this.activeListDeployMode = listDeployMode;
        this.activeTemplateDeployMode = templateDeployMode;
      });
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.listDeploymentService.listsBeingDeployed$,
      this.templateDeploymentService.templatesBeingDeployed$,
    ])
      .pipe(
        takeUntil(this.unsubscribe),
        filter(([lists, templates]) => Boolean(lists) || Boolean(templates)),
      )
      .subscribe(([lists, templates]) => {
        this.listNames = lists?.map(({ name }) => name);
        this.templateNames = templates?.map(({ name }) => name);
      });

    combineLatest([
      this.listDeploymentService.activeDeployMode$,
      this.templateDeploymentService.activeDeployMode$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([listDeployMode, templateDeployMode]) => {
        this.setButtonLabel(listDeployMode, templateDeployMode);

        if (this.stopObservingChanges) {
          this.stopObservingChanges.next();
          this.stopObservingChanges.complete();
        }

        if (listDeployMode || templateDeployMode) {
          this.observeForChanges();
          this.initialSelectedFolderIds = new Set(this.folderSelectionService.selectedFoldersIds);
          this.applyingChanges = false;
          this.popoverComponent.open();
        } else {
          this.popoverComponent.close();
        }
      });
  }

  private setButtonLabel(listDeployMode: DeploymentModes, templateDeployMode: DeploymentModes) {
    this.buttonLabel = listDeployMode
      ? this.listLabels[listDeployMode]
      : this.templateLabels[templateDeployMode];

    this.changeDetectorRef.detectChanges();
  }

  private observeForChanges() {
    this.folderSelectionService.selectedFolders$.pipe(first()).subscribe(() => {
      this.initialSelectedFolderIds = new Set(this.folderSelectionService.selectedFoldersIds);
    });

    this.stopObservingChanges = new Subject();
    this.folderSelectionService.selectedFolders$.pipe(takeUntil(this.stopObservingChanges)).subscribe(() => {
      const currentSelectedFolderIds = new Set(this.folderSelectionService.selectedFoldersIds);
      this.haveChangesBeenMade = !isEqual(currentSelectedFolderIds, this.initialSelectedFolderIds);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.stopObservingChanges) {
      this.stopObservingChanges.next();
      this.stopObservingChanges.complete();
    }
  }

  cancelChanges() {
    this.applyingChanges = true;
    this.listDeploymentService.stopDeployMode();
    this.templateDeploymentService.stopDeployMode();
  }

  applyChanges() {
    this.applyingChanges = true;
    if (this.activeListDeployMode) {
      this.listDeploymentService.applyDeployedChanges();
    } else if (this.activeTemplateDeployMode) {
      this.templateDeploymentService.applyDeployedChanges();
    }
  }
}
