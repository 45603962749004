const SignUpFeatureList = {
  templateReferral: [
    'Hundreds of free customisable templates available',
    'Fill out on computer, phone or tablet',
    'Add your own logo and colours',
    'Sign off electronically',
    'Export as PDF or CSV in one click',
  ],
  nonTemplateReferral: [
    'Unlimited forms, photos and storage',
    'Conditional form logic and automatic formulas',
    'iOS and Android app, works online and offline',
    'Instant contactless signatures',
    'Automated registers and workflows',
    'Real-time analytics and tracking',
  ],
};

export default SignUpFeatureList;
