<cc-folder-navbar></cc-folder-navbar>
<cc-deploy-mode-popover></cc-deploy-mode-popover>
<div class="secure-container sm-relative sm-flex sm-flex-1 sm-flex-col">
  <cc-overlay></cc-overlay>
  <cc-top-nav></cc-top-nav>
  <router-outlet></router-outlet>
  <div class="form-modal-container sm-z-50"></div>
  <ng-container *ngFor="let fileUploadParams of currentUploads; trackBy: uniqueByUrl">
    <cc-file-uploader-modal [params]="fileUploadParams"></cc-file-uploader-modal>
  </ng-container>
</div>
