import { StaticProvider, TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID } from '@angular/core';

import { LocalStorageService } from 'app/shared/service/local-storage.service';

function getTranslationsWithWebpack(locale: string) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-dynamic-require, global-require
    const file = require(`${'raw-loader!'}${'../../assets/locale/messages.'}${locale}.xlf`);
    return Promise.resolve(file);
  } catch (e) {
    const error = `messages.${locale}.xlf not found`;
    // eslint-disable-next-line no-console
    console.error(error);
    return Promise.reject(error);
  }
}

export function getTranslationProviders(): Promise<StaticProvider[]> {
  const service = new LocalStorageService(window);
  // Get the locale id from the global
  const locale = service.getItem('locale');
  // return no providers if fail to get translation file for locale
  const noProviders: StaticProvider[] = [];
  // No locale or U.S. English: no translation providers
  if (!locale || locale === 'en-US') {
    return Promise.resolve(noProviders);
  }
  return getTranslationsWithWebpack(locale)
    .then((translations: string) => [
      { provide: TRANSLATIONS, useValue: translations },
      { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
      { provide: LOCALE_ID, useValue: locale },
    ])
    .catch(() => noProviders); // ignore if file not found
}
