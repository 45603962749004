import { Injectable, Injector } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

import { WINDOW } from 'app/shared/factory/window.factory';
import { LocalStorageService } from 'app/shared/service/local-storage.service';

@Injectable()
export class AuthLocalStorageService implements AbstractSecurityStorage {
  private localStorageService: LocalStorageService;
  constructor() {
    // TODO: Manually injecting local storage service until we upgrade angular-auth-oidc-client
    //       library to at least v14 which uses native dependency injection for custom storage
    //       https://angular-auth-oidc-client.com/docs/migrations/v13-to-v14#providing-a-custom-storage-service
    const injector = Injector.create({
      providers: [{ provide: LocalStorageService }, { provide: WINDOW, useFactory: () => window }],
    });
    this.localStorageService = injector.get(LocalStorageService);
  }

  read(key: string) {
    return this.localStorageService.getItem(key);
  }

  write(key: string, value: any): void {
    this.localStorageService.setItem(key, value);
  }

  remove(key: string): void {
    this.localStorageService.removeItem(key);
  }

  clear(): void {
    this.localStorageService.clear();
  }
}
