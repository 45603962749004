<div ccTmpI18N>
  <!--apps-->
  <div i18n id="deleteForm">Are you sure you want to delete this form?</div>
  <div i18n id="archiveApp">Are you sure you want to archive the selected Templates?</div>
  <div i18n id="deleteApp">Are you sure you want to delete selected Templates?</div>
  <div i18n id="deleteRow">Are you sure you want to delete this row?</div>
  <div i18n id="formTableClearSingleRow">Are you sure you want to clear this row?</div>
  <div i18n id="formTableClearAllRows">Are you sure you want to clear ALL rows from this table?</div>
  <div i18n id="formTableDeleteAllRows">Are you sure you want to delete ALL rows from this table?</div>
  <div i18n id="confirmChangeColumnType">
    Are you sure you want to change this column cell type? Any existing forms using the previous cell type
    will not display data in the register view or exports.
  </div>
  <div i18n id="confirmChangeCellType">
    Are you sure you want to change this cell type? This may cause confusion with existing forms in the
    register view or when exporting.
  </div>
  <div i18n id="archiveCompanyApp">
    Are you sure you want to archive the selected Templates? Note: Archiving templates that are deployed to
    team folders will also archive the deployed templates.
  </div>
  <div i18n id="deleteCompanyApp">
    You are about to delete a template which is being used across multiple project teams. This action is
    permanent and will delete all forms saved using this template. Are you sure you want to delete?
  </div>
  <div i18n id="saveCompanyApp">
    You are about to apply changes to a template which is being used across multiple project teams. This
    action will update this template for all teams. Are you sure you want to save these changes?
  </div>

  <div i18n id="confirmApply">Apply</div>
  <div i18n id="confirmOk">Ok</div>
  <div i18n id="confirmConfirm">Confirm</div>
  <div i18n id="confirmArchive">Archive</div>
  <div i18n id="cloneForm">You need to save before you can clone this form</div>
  <div i18n id="sendForm">You need to save before you can send this form as a PDF</div>
  <div i18n id="saveBeforeSign">Would you like to save your changes before signing?</div>
  <div i18n id="saveAndSign">Save and sign</div>

  <div i18n id="saveBeforeAttach">You need to save before you can attach a PDF to this form</div>
  <div i18n id="confirmSaveAndAttach">Save and attach</div>

  <div i18n id="resetSignature">Are you sure you want to reset the signatures?</div>
  <div i18n id="saveAndReset">Save and reset</div>
  <div i18n id="confirmReset">Reset</div>
  <div i18n id="saveBeforeResetSignature">
    Would you like to save your changes before making more changes?
  </div>

  <div i18n id="singleWorkspaceWarning">Only available if you have access to more than one workspace.</div>
  <div i18n id="HomeFolderInArchivedViewWarning">
    You can only access the home folder by toggling to the active tab at the bottom of the sidebar.
  </div>

  <div i18n id="activeProjectInArchivedViewWarning">
    You can only access active project folders by toggling to the active tab at the bottom of the sidebar.
  </div>
  >
  <div i18n id="noFolderToNavigate">User does not have access to any team/project in this workspace.</div>
  <div i18n id="noFolderAccess">You do not have access to this folder</div>
  <div i18n id="homeFolderRedirect">
    You do not have access to the folder in the URL link. You've been redirected to the next accessible folder
    in this workspace
  </div>

  <div i18n id="downloadForm">You need to save before you can download this form as a PDF</div>

  <div i18n id="printForm">Would you like to save your changes before printing?</div>

  <div i18n id="deleteThumbnailField">
    Are you sure you want to delete this Field?
    <br />
    <br />
    Note: Fields used in the form thumbnail will also be removed.
  </div>

  <div i18n id="deleteField">Are you sure you want to delete this Field?</div>
  <div i18n id="updateThumbnailField">
    This field is displayed on the form thumbnail.Updating this field type will remove the current field from
    the form thumbnails in the timeline and workflow views.
  </div>

  <div i18n id="deleteRule">Are you sure you want to delete this Rule?</div>

  <div i18n id="deleteCondition">Are you sure you want to remove this condition?</div>

  <div i18n id="deleteAttachment">Are you sure you want to delete this attachment?</div>

  <div i18n id="deleteSelectedForms">Are you sure you want to delete the selected forms?</div>

  <div i18n id="deletedSelectedForms">The selected forms were deleted successfully.</div>

  <div i18n id="deleteSelectedFormsError">
    An error occurred while attempting to delete the selected forms.
  </div>

  <div i18n id="signForm">Are you sure you want to sign this form?</div>
  <div i18n id="appCreated">New Template created.</div>

  <div i18n id="appRemoved">Template deleted.</div>

  <div i18n id="appUpdated">Template updated.</div>
  <div i18n id="templateIsUpdating">
    Your template update is being processed, please wait a few minutes until the changes are deployed
  </div>
  <div i18n id="templateListSourceChange">
    Changing the List source will clear any logic rule settings. Are you sure you want to continue?
  </div>

  <div i18n id="displayOnTemplateTypePopover">
    Template type cannot be changed, you will need to Clone this Template to change it to a Workflow or
    Timeline.
  </div>
  <div i18n id="prefilledCellPopover">
    This cell contains prefilled text, which can only be changed by editing the template.
  </div>

  <div i18n id="formCreated">New document created.</div>

  <div i18n id="formUpdated">Document updated.</div>
  <div i18n id="formSaved">Document Saved.</div>

  <div i18n id="formUpdateError">An error occurred while updating the document.</div>
  <div i18n id="formDeleted">Document deleted.</div>
  <div i18n id="formCloned">Document cloned.</div>

  <div i18n id="newPwdSaved">Your new password has been saved.</div>

  <div i18n id="generalErr">
    We're having troubles, please contact the support team in the bottom right corner.
  </div>

  <div i18n id="noConnectionErr">
    Cannot connect. Please check your internet connection and firewall settings. To learn about
    troubleshooting this issue:
    <a
      class="sm-underline hover:sm-underline"
      href="https://intercom.help/dashpivot/en/articles/9303904-network-and-firewall-issues"
      rel="noopener noreferrer"
      target="_blank"
      >Click here</a
    >
  </div>

  <div i18n id="signatureUploadError">
    Unable to upload signature. Please check your internet connection and firewall settings.
  </div>

  <div i18n id="unauthorizedSSO">You will be logged out</div>

  <div i18n id="addApp">Add Template</div>
  <div i18n id="editApp">Edit Template</div>
  <div i18n id="previewApp">Preview Template</div>

  <div i18n id="editorView">Fields Editor</div>
  <div i18n id="mobileAppsView">Mobile Apps</div>
  <div i18n id="webPreviewView">Web Preview</div>
  <div i18n id="settingsView">Settings</div>
  <div i18n id="headerFooterView">Header/Footer Editor</div>

  <!-- Actions -->
  <div i18n id="confirmDeleteAction">Are you sure you want to delete this action?</div>
  <div i18n id="actionDeleteSucceeded">Action deleted successfully!</div>
  <div i18n id="actionDeleteFailed">Unable to delete action. Please try again.</div>
  <div i18n id="actionSaveSucceeded">Action saved successfully!</div>
  <div i18n id="actionUpdateSucceeded">Action updated successfully!</div>
  <div i18n id="formOrActionSaveFailed">Unable to save changes. Please try again.</div>

  <!-- Signup in Form modal -->
  <div i18n id="signUpToDownload">Sign up to download this template</div>
  <div i18n id="signUpToSave">Sign up to save your edits</div>
  <div i18n id="signUpDownloadButtonLabel">Sign up to download</div>
  <div i18n id="signUpSaveButtonLabel">Sign up to save</div>

  <!-- Sam Actions -->
  <div i18n id="samHasFinishedProcessing">Sam has finished processing</div>

  <!-- Header/Footer Field tooltips -->
  <div i18n id="brandingToolTip">
    The logo and brand settings for this workspace are located in your Home Folder > Settings.
  </div>
  <div i18n id="formMetaDataToolTip">
    This information is variable, based on the template settings and the folder where the form is created.
  </div>
  <div i18n id="pdfExportMetaDataToolTip">
    This information is variable, and is generated automatically when the form is exported as a PDF.
  </div>
  <div i18n id="otherFieldMetadata">This information is predefined, and is the same on every form.</div>

  <div i18n id="addItem">Add</div>

  <div i18n id="editItem">Edit</div>

  <div i18n id="Column1">Column1</div>
  <div i18n id="Column2">Column2</div>
  <div i18n id="Column3">Column3</div>
  <div i18n id="Column4">Column4</div>

  <!-- template field types -->

  <div i18n id="templateFieldText">Text</div>
  <div i18n id="templateFieldSingleLineText">Single line text</div>
  <div i18n id="templateFieldMultiLineText">Multi line text</div>
  <div i18n id="templateFieldPreFilledText">Prefilled text</div>

  <div i18n id="templateFieldDate">Date</div>
  <div i18n id="templateFieldDateTimeRange">Date time range</div>
  <div i18n id="templateFieldDateRange">Date range</div>
  <div i18n id="templateFieldSingleDateTime">Single date time</div>
  <div i18n id="templateFieldSingleDate">Single date</div>
  <div i18n id="templateFieldExpiryDate">Expiry date</div>

  <div i18n id="templateFieldYesNoCheckBox">Yes/No</div>
  <div i18n id="templateFieldYesNoNaCheckBox">Yes/No/NA</div>
  <div i18n id="templateFieldYesNoCheckBoxWithText">Yes/No with text</div>
  <div i18n id="templateFieldYesNoNaCheckBoxWithText">Yes/No/NA with text</div>

  <div i18n id="templateFieldList">List</div>
  <div i18n id="templateFieldMultipleChoice">Multiple choice</div>
  <div i18n id="templateFieldDropdown">Dropdown</div>

  <div i18n id="templateFieldDefaultTable">Default table</div>
  <div i18n id="templateFieldPrefilledTable">Prefilled table</div>

  <div i18n id="templateFieldSignonTable">Scan Sitemate ID</div>

  <div i18n id="templateFieldSignature">Signature</div>
  <div i18n id="templateFieldManualSignature">Sign manually</div>
  <div i18n id="templateFieldApprovalSignature">Approval signature</div>

  <div i18n id="templateFieldPhoto">Photos</div>
  <div i18n id="templateFieldPhotoExtraSmall">Photos (XS)</div>
  <div i18n id="templateFieldPhotoSmall">Photos (S)</div>
  <div i18n id="templateFieldPhotoMedium">Photos (M)</div>
  <div i18n id="templateFieldPhotoLarge">Photos (L)</div>
  <div i18n id="templateFieldPhotoExtraLarge">Photos (XL)</div>

  <div i18n id="templateFieldSketch">Sketch</div>

  <div i18n id="templateFieldPageBreak">Page break</div>

  <div i18n id="templateFieldPerson">Person</div>
  <div i18n id="templateFieldSinglePerson">Single Person</div>

  <div i18n id="templateFieldTableDesc">Table name</div>
  <!-- List Property Table Cell -->
  <div i18n id="prefilledTableNotSupportedOnLPTC">
    List property is currently not supported for prefilled tables. Switching the table type will remove the
    list property columns in the table.
    <br /><br />
    Are you sure you want to update this table to a prefilled table?
  </div>

  <!-- Default Location Table Cell -->
  <div i18n id="deleteLocationCell">Are you sure you want to delete this location capture?</div>

  <!-- Template field type tooltips -->
  <div i18n id="templateFieldTableExtendCellTypeTooltip">
    Click ‘Extend first cell type to all rows’ to update all of the rows and values in this specific column to
    match the first row cell type.
  </div>

  <!-- Template header footer fields -->
  <div i18n id="templateFieldLogo">Logo</div>
  <div i18n id="templateFieldFilePath">Filepath</div>
  <div i18n id="templateFieldFilePathPreviewText">Workspace-Project-Team</div>
  <div i18n id="templateFieldTemplateID">Template ID</div>
  <div i18n id="templateFieldTemplateVersion">Template Version</div>
  <div i18n id="templateFieldFormVersion">Form Version</div>
  <div i18n id="templateFieldPDFCreationTimeAndDate">PDF Creation Date and Time</div>
  <div i18n id="templateFieldPDFPageNumber">PDF Page Number</div>
  <div i18n id="templateFieldPDFExportDisclaimer">PDF Export Disclaimer</div>
  <div i18n id="templateFieldPreFilledText">Prefilled Text</div>
  <div i18n id="headerFooterLengthError">Prefilled text in the header/footer is too long.</div>

  <!-- form fields -->

  <div i18n id="formNumberColumnPlaceholder">Type number...</div>
  <div i18n id="formNumberColumnPlaceholderTruncated">Type...</div>
  <div i18n id="templateNamePatternError">Template Name cannot be blank or have slashes.</div>
  <div i18n id="templateIdPatternError">Template ID cannot be blank or have slashes.</div>
  <div i18n id="templateFormNamePatternError">Form Name cannot be blank or have slashes.</div>
  <div i18n id="templateNameIsRequiredError">Template name is required.</div>
  <div i18n id="templateIdIsRequiredError">Template ID is required.</div>
  <div i18n id="templateFormNameIsRequiredError">Form name is required.</div>
  <div i18n id="templateIdUsedError">Template ID has been used.</div>
  <div i18n id="templateTypeError">Template type is required.</div>

  <!-- person field -->
  <div i18n id="missingUser">Missing User</div>
  <div i18n id="archivedUserNotice">The selected person is no longer an active Dashpivot Account User.</div>

  <!-- form preview warnings -->
  <div i18n id="formPreviewWarningUploadAttachment">You cannot upload attachments in a form preview</div>
  <div i18n id="formPreviewWarningDeleteAttachment">You cannot delete attachments in a form preview</div>
  <div i18n id="formPreviewWarningAddPhoto">You cannot add photos in a form preview</div>
  <div i18n id="formPreviewWarningDeletePhoto">You cannot delete photos in a form preview</div>
  <div i18n id="formPreviewWarningSignature">You cannot use signatures in a form preview</div>
  <div i18n id="formPreviewWarningSketch">You cannot add sketches in a form preview</div>

  <!--photos-->
  <div i18n id="deletePhotos">Are you sure you want to delete the selected photos?</div>

  <div i18n id="deletePhoto">Are you sure you want to delete this photo?</div>

  <div i18n id="photoRemoved">Photo deleted.</div>
  <div i18n id="photosRemoved">Photos deleted.</div>
  <div i18n id="fileBeingUploaded">
    There are files still being uploaded. Are you sure you want to stop the uploading and leave?
  </div>
  <div i18n id="filesUploadInProgress">Uploading selected entries, please wait...</div>

  <div i18n id="addTagsModalTitle">Add photo tags</div>
  <div i18n id="addTagsModalMessage">
    Select the photo tags you wish to add to the selected photos. Maximum selection of 10 tags.
  </div>
  <div i18n id="addTagsModalActionTitle">Apply</div>
  <div i18n id="tagsAdded">Photo tags applied successfully!</div>
  <div i18n id="tagsNotAdded">Unable to add photo tags.</div>

  <!--Home-->
  <div i18n id="photoEngagementTitle">New Photos uploaded</div>
  <div i18n id="formEngagementTitle">New forms created</div>

  <div i18n id="removeUserFromTeam">Are you sure you want to remove access for the selected user?</div>
  <div i18n id="removeUsersFromTeam">Are you sure you want to remove access for the selected users?</div>

  <div i18n id="contributorRemoved">Contributor removed successfully.</div>
  <div i18n id="contributorAdded">Contributor added successfully.</div>

  <div i18n id="visitorRemoved">Visitor removed successfully.</div>
  <div i18n id="visitorAdded">Visitor added successfully.</div>

  <div i18n id="contributorPermissionUpdated">Contributor permissions updated successfully.</div>
  <div i18n id="visitorPermissionUpdated">Visitor permissions updated successfully.</div>

  <!--Project setting-->
  <div i18n id="addListNameHelper">The List Name is the title of your list.</div>

  <div i18n id="listAdded">New list created.</div>
  <div i18n id="failedToAddList">Unable to create list. Please try again.</div>
  <div i18n id="listRowColorsBulkApplied">List item colours applied successfully!</div>
  <div i18n id="failedToBulkApplyListRowColors">Unable to apply list item colours.</div>
  <div i18n id="archiveCompanyList">
    Are you sure you want to archive the selected lists? Note: Archiving lists that are deployed to project
    folders will also archive the deployed lists.
  </div>
  <div i18n id="archiveProjectList">Are you sure you want to archive the selected lists?</div>
  <div i18n id="deleteCompanyList">
    Are you sure you want to delete the selected lists? Note: Deleting lists that are deployed to project
    folders will also delete the deployed lists.
  </div>
  <div i18n id="deleteProjectList">Are you sure you want to delete the selected lists?</div>
  <div i18n id="listArchived">List(s) have successfully been archived.</div>
  <div i18n id="listArchivedError">Unable to archive selected list(s).</div>
  <div i18n id="listRestored">List(s) have successfully been restored.</div>
  <div i18n id="listRestoredError">Unable to restore selected list(s).</div>
  <div i18n id="listRemoved">List(s) have successfully been deleted.</div>
  <div i18n id="listRemovedError">Unable to delete selected list(s).</div>
  <div i18n id="deleteList">Are you sure you want to delete the selected list(s)?</div>

  <div i18n id="listNameUpdated">List name updated successfully!</div>
  <div i18n id="failedToUpdateListName">Unable to update list name.</div>

  <div i18n id="deleteListItem">Are you sure you want to delete this item?</div>

  <div i18n id="undeployListItem">Are you sure you want to delete this item?</div>

  <div i18n id="deleteListItemPropertyAttachment">Are you sure you want to delete this attachment?</div>

  <div i18n id="listItemUndeployed">List item deleted.</div>

  <div i18n id="listDeployManagementSuccessful">List deployment updated successfully.</div>
  <div i18n id="listDeployManagementFailed">Unable to deploy list.</div>

  <div i18n id="listsBulkDeploySuccessful">Lists deployed successfully.</div>
  <div i18n id="listsBulkDeployFailed">Unable to deploy lists.</div>

  <div i18n id="listsBulkUndeploySuccessful">Lists undeployed successfully.</div>
  <div i18n id="listsBulkUndeployFailed">Unable to undeploy lists.</div>

  <div i18n id="listExpiryDateNotificationUpdated">Notification settings saved.</div>
  <div i18n id="listUnsubscribeCompanyMismatch">
    The provided company does not match the property provided for this action.
  </div>
  <div i18n id="listUnsubscribeProjectMismatch">
    The provided project does not match the property provided for this action.
  </div>
  <div i18n id="listUnsubscribeSuccess">Successfully unsubscribed.</div>
  <div i18n id="addList">Add List</div>
  <div i18n id="editList">Edit List</div>
  <div i18n id="newList">New List</div>
  <div i18n id="list-item-colour-explainer">
    The colour that is applied to list item will be displayed in forms, register view and PDF exports.
  </div>
  <div i18n id="listNameRequired">List name is required.</div>
  <div i18n id="listPropertyKindWarning">
    Once a property type is selected, it is fixed and cannot be changed.
  </div>
  <div i18n id="listPropertyLimitReached">
    List property field limit reached in this template. The cells that contain list property will clone over
    as text cells.
  </div>
  <div i18n id="listPropertyRequiredTitle">Property columns required for CSV upload</div>
  <div i18n id="listPropertyRequiredBody">
    This list has no property values. Uploading a CSV with no property values will only upload the first
    column of the CSV. Please populate the list with property values if you wish to upload more than one
    column.
  </div>
  <div i18n id="listInvalidFormattingTitle">Invalid formating detected in CSV upload</div>
  <div i18n id="listInvalidFormattingBody">
    Continuing the upload will result in leaving cells with invalid formatting blank. Are you sure you want to
    continue with the CSV upload?
  </div>

  <div i18n id="teamTagTitle">Team Photo Tags</div>

  <div i18n id="projectTagTitle">Project Photo Tags</div>

  <div i18n id="newTagGroup">New Tag Group</div>

  <div i18n id="addTagGroup">Add Tag Group</div>
  <div i18n id="editTagGroup">Edit Tag Group</div>

  <div i18n id="tagGroupAdded">New tag group created.</div>
  <div i18n id="deleteTagGroup">Are you sure you want to delete this tag group?</div>

  <div i18n id="tagGroupRemoved">Tag group deleted.</div>
  <div i18n id="tagGroupUpdated">Tag group updated.</div>
  <div i18n id="kmlRemoved">Alignment deleted.</div>
  <div i18n id="deleteKml">Are you sure you want to delete this alignment?</div>
  <div i18n id="kmlAdded">New alignment created.</div>

  <!--User account-->
  <div i18n id="activationIdInvalid">Invalid activation code.</div>
  <div i18n id="activationIdRevoked">Your Dashpivot invitation is no longer valid.</div>

  <div i18n id="deleteSignOnSignature">
    Are you sure you want to delete this signature? This operation cannot be reverted.
  </div>

  <div i18n id="deleteSignature">Are you sure you want to remove this signature?</div>

  <div i18n id="deleteYourSignature">Are you sure you want to remove your signature?</div>

  <div i18n id="editYourSignature">Are you sure you want to edit your signature?</div>
  <div i18n id="confirmEditSignature">Edit Signature</div>

  <div i18n id="removeSignature">Are you sure you want to remove the Signature?</div>
  <div i18n id="uploadSignatureError">Unable to upload signature. Please try again.</div>

  <div i18n id="profileSaved">User profile saved successfully.</div>

  <div i18n id="passwordSaved">Password saved.</div>
  <div i18n id="disabledChangePasswordForSSO">
    Looks like you are using an external identity provider (IdP) to access Dashpivot using single sign-on
    (SSO). You’ll need to go to your IdP directly to change your password, or ask your Dashpivot admin for
    help.
  </div>

  <!--Organisation management-->
  <div i18n id="addProject">Add Project</div>
  <div i18n id="editProject">Edit Project</div>
  <div i18n id="deleteProject">Are you sure you want to delete this project?</div>
  <div i18n id="projectDeleted">Project deleted.</div>

  <div i18n id="addCompany">Add Organisation</div>
  <div i18n id="editCompany">Edit Organisation</div>
  <div i18n id="workspaceCreated">New workspace created.</div>
  <div i18n id="deleteCompany">Are you sure you want to delete this organisation?</div>
  <div i18n id="workspaceDeleted">Workspace deleted.</div>
  <div i18n id="companyUpdated">Organisation updated.</div>
  <div i18n id="companyBillingUpdated">Organisation billing updated.</div>

  <div i18n id="addTeam">Add Team</div>
  <div i18n id="editTeam">Edit Team</div>
  <div i18n id="deleteTeam">Are you sure you want to delete this team?</div>
  <div i18n id="teamDeleted">Team deleted.</div>

  <div i18n id="folderAdded">New folder created successfully!</div>
  <div i18n id="folderRenamed">Folder name updated successfully!</div>

  <!-- Company home settings -->
  <div i18n id="invalidSingleDomainForJointVenture">
    Domains should be more than one for joint-venture workspaces
  </div>
  <div i18n id="invalidPublicDomains">Non-business email domains are not allowed</div>
  <div i18n id="invalidDomain">Invalid domain</div>
  <div i18n id="jointVentureDomainNotSet">
    A joint-venture domain must be specified for joint-venture workspaces
  </div>
  <div i18n id="jvDomainCannotBeOneOfTheParentDomains">
    The joint-venture domain cannot be the same as a parent domain
  </div>
  <div i18n id="atLeastTwoParentDomains">
    At least two parent domains must be specified for joint-venture workspaces
  </div>
  <div i18n id="parentDomainsCannotBeSameAsJv">
    Parent domains cannot be the same as the joint-venture domain
  </div>
  <div i18n id="legacyRestrictionsTooltip">
    Toggle on to restrict Dashpivot Visitors and Sitemate Paid Users from being added.
  </div>
  <div i18n id="general">General</div>
  <div i18n id="branding">Branding</div>
  <div i18n id="billing">Billing</div>
  <div i18n id="smsGlobalBilling">Global Billing</div>
  <div i18n id="smsDashpivotBilling">Dashpivot Billing</div>
  <div i18n id="smsSecurity">Security</div>
  <div i18n id="workspaceSettingsUpdateSuccess">Workspace settings updated.</div>
  <div i18n id="workspaceSettingsUpdateFailed">Workspace settings could not be updated.</div>
  <div i18n id="sitemateStartSettingsUpdateSuccess">Sitemate Start settings updated.</div>
  <div i18n id="sitemateStartSettingsUpdateFailed">Sitemate Start settings could not be updated.</div>

  <div i18n id="confirmYes">Yes</div>
  <div i18n id="confirmCancel">Cancel</div>
  <div i18n id="confirmNo">No</div>
  <div i18n id="confirmSave">Save</div>
  <div i18n id="confirmSaveAndDownload">Save and download</div>
  <div i18n id="confirmSaveAndSend">Save and send</div>
  <div i18n id="confirmSaveAndClone">Save and clone</div>
  <div i18n id="confirmDiscard">Discard change</div>

  <div i18n id="confirmSign">Sign</div>

  <div i18n id="confirmDelete">Delete</div>

  <div i18n id="confirmRemove">Remove</div>

  <div i18n id="saveChangeBeforeClose">Do you want to save your change before leaving?</div>
  <div i18n id="saveChangeBeforeCloseForm">Do you want to save this form?</div>

  <div i18n id="leaveWithoutSavingChange">Are you sure you want to leave without saving your change?</div>

  <div i18n id="startDate">Start</div>

  <div i18n id="endDate">End</div>

  <div i18n id="placeHolderText">Type text</div>

  <div i18n id="appIdUsed">Template ID has been used.</div>

  <div i18n id="appIdRequired">Template ID is required.</div>
  <div i18n id="listItemsInTableRequired">Please specify the list items in the table.</div>
  <div i18n id="listInCategoryRequired">Please specify the list in the category.</div>
  <div i18n id="templateValidationMissingListProperty">
    Unable to save template due to invalid or missing list properties. Please check and try again.
  </div>
  <div i18n id="listPropertyTableCellCountLimitExceeded">
    Limit of 3 List property fields exceeded. Please remove the excess fields to save this template.
  </div>
  <div i18n id="listNotAccessible">
    This template is referencing a list that does not exist in this folder. Please remove the list in order to
    save this template.
  </div>
  <div i18n id="listInTemplateIsDeleted">
    This template is referencing a list that has been deleted. Please link a new list in order to save this
    template.
  </div>
  <div i18n id="itemInCategoryRequired">Please add items in the category.</div>
  <div i18n id="appNameRequired">Template name is required.</div>
  <div i18n id="tableWithLegacyFieldsCannotBeSaved">
    This Template contains legacy table column widths and cannot be saved. To save this template, please
    replace the legacy widths shown in
  </div>
  <div i18n id="tableWithLegacyFieldsStepsToFix">and change the width to: S, M, L or XL.</div>
  <div i18n id="rowInPrefilledTableRequired">Prefilled tables must contain at least one row</div>
  <div i18n id="templateFieldsRequired">Template needs to have at least one field</div>

  <div i18n id="itemNameRequired">Form name is required.</div>
  <div i18n id="templateInvalidApprovalSignatures">
    Incorrect number of Approval Signatures. Workflow forms must have 1 less signature than workflow columns.
  </div>
  <div i18n id="templateInvalidApprovalSignaturesLogic">Approval signature logic error. Input required.</div>
  <div i18n id="templateDuplicatedLogicActions">Duplicating actions detected in logic</div>
  <div i18n id="templateDuplicatedComplementaryRule">Duplicating rule detected</div>
  <div i18n id="planExceededLogicRules">Plan type rule limit exceeded</div>

  <div i18n id="templateHistoryNotAvailable">Template history not available prior to July 2020.</div>
  <div i18n id="templateLogicRuleInvalid">This template contains incomplete or invalid logic.</div>
  <div i18n id="templateLogicRuleOnChild">Cannot add logic field into another logic field.</div>
  <div i18n id="templateApprovalSignatureInLogicRule">
    Approval Signatures cannot be placed within Logic blocks.
  </div>
  <div i18n id="populateApprovalSignatureInLogicRule">
    Please populate the approval signature logic before adding conditional logic.
  </div>
  <div i18n id="betaWarningBannerApprovalSignatureLogic">
    Conditions within Approval Signature Logic are currently in beta. This feature is only available on
    Dashpivot web and not the mobile apps.
  </div>
  <div i18n id="proPlanExceedORMaxRulesBannerWarning">
    Limit of 3 conditional rules exceeded. Please remove excess logic or
  </div>
  <div i18n id="proPlanExceedANDMaxRulesBannerWarning">
    Limit of 3 conditional logic blocks exceeded. Please remove excess logic or
  </div>
  <div i18n id="premiumPlanExceedORMaxRulesBannerWarning">
    Limit of 6 conditional rules exceeded. Please remove excess logic or
  </div>
  <div i18n id="premiumPlanExceedANDMaxRulesBannerWarning">
    Limit of 6 conditional logic blocks exceeded. Please remove excess logic or
  </div>
  <div i18n id="templatePageBreakInLogicRule">Page break fields cannot be placed within Logic blocks.</div>
  <div i18n id="planDoesNotIncludeSignatureLogicField">
    You do not have the correct Dashpivot subscription plan to use this feature. This logic will not be
    applied to workflow forms.
  </div>
  <div i18n id="templateLogicRuleUnavailable">
    The data from this List is not currently supported with Template Logic, our team is working on allowing
    this, please try again next week or contact support if you have any questions.
  </div>
  <div i18n id="signatureLogicRuleExceeded">
    Unable to apply Approval Signature logic to this signature/workflow, as the number of conditions has
    exceeded your current plan limit.
  </div>
  <div i18n id="signatureLogicRuleExceededSecondPart">
    to unlock more conditional rules for approval signature or remove the excess conditions in the Template
    Editor.
  </div>
  <div i18n id="signatureLogicRuleReachedLimitFirstPart">
    The total number of conditional approval signature logic fields you can add into your templates is based
    on your subscription plan.
  </div>
  <div i18n id="signatureLogicRuleReachedLimitSecondPart">
    Your plan supports the maximum number of conditional approval signature logic fields in Dashpivot.
  </div>

  <div i18n id="pageBreakFirstFieldErrorLabel">Page break cannot be the first field</div>
  <div i18n id="pageBreakLastFieldErrorLabel">Page break cannot be the last field</div>
  <div i18n id="pageBreakConsecutiveFieldError">Page break cannot be next to another page break</div>
  <div i18n id="pageBreakFirstFieldErrorMessage">The first field cannot be a page break field</div>
  <div i18n id="pageBreakLastFieldErrorMessage">The last field cannot be a page break field</div>

  <div i18n id="logoUpdating">Updating logo...</div>
  <div i18n id="logoAdded">Logo uploaded successfully.</div>
  <div i18n id="logoTooLarge">
    Selected logo is too large. Please select an image file which is less than 50Kb.
  </div>

  <div i18n id="appNameInvalid">Template name cannot have slash.</div>
  <div i18n id="colorUpdating">Updating colour scheme...</div>
  <div i18n id="colorUpdated">Colour scheme updated successfully.</div>

  <div i18n id="formulaError">Formula error. Please check all formulas.</div>
  <div i18n id="formLogicApplied">Form logic applied</div>
  <div i18n id="formLogicChangeConfirmation">
    Changing your answer will clear the values in the linked logic fields below.
  </div>

  <div i18n id="invalidForm">The form has invalid or incomplete data. Please check.</div>
  <div i18n id="closingInvalidFormInLastColumn">
    Cannot close completed workflow forms with missing required fields. Please check and then save.
  </div>
  <div i18n id="setupSignatureProfile">You have not setup your signature. Do you want to setup now?</div>

  <div i18n id="mailNotExist">The email address does not exist. Please check spelling.</div>

  <div i18n id="sendFormPDF">Send form as PDF successfully.</div>

  <div i18n id="movePhoto">Photos moved successfully.</div>
  <div i18n id="copyPhoto">Photos copied successfully.</div>
  <div i18n id="removePhoto">Are you sure you want to remove this photo?</div>
  <div i18n id="removeAllPhotos">Are you sure you want to remove all photos?</div>
  <div i18n id="noRegisterSelected">Please select the items that you want to export.</div>
  <div i18n id="cannotExportRegister">Some error happened. Cannot export selected items.</div>
  <div i18n id="noPermissionDelete">You don't have the permission to delete this item.</div>
  <div i18n id="selectPhoto">Please select photos to proceed.</div>
  <div i18n id="inputTextBeforeAdd">Please input text in the text box before adding it.</div>
  <div i18n id="selectShape">Please select the shape you want to delete.</div>
  <div i18n id="deleteBackground">Are you sure you want to delete the background image?</div>
  <div i18n id="editTemplateTag">Edit Template Tag</div>
  <div i18n id="addTemplateTag">Add Template Tag</div>
  <div i18n id="templateTagAdded">New template tag added.</div>
  <div i18n id="templateTagUpdated">Template tag updated.</div>
  <div i18n id="templateTagDeleted">Template tag deleted.</div>
  <div i18n id="templateTagUnspecified">Unspecified</div>
  <div i18n id="deleteTemplateTag">Are you sure you want to delete this template tag?</div>
  <div i18n id="templateTabAdded">New template tab added.</div>
  <div i18n id="templateTabUpdated">
    Tabs are being updated for all company templates, this could take several minutes.
  </div>
  <div i18n id="templateTabDeleted">
    Tabs are being updated for all company templates, this could take several minutes.
  </div>
  <div i18n id="deleteTemplateTab">Are you sure you want to delete this template tab?</div>
  <div i18n id="tabAddedToTemplate">Template added to tab.</div>
  <div i18n id="tabRemoveFromTemplate">Template removed from tab.</div>
  <div i18n id="uniqueNameTemplateTab">Tab label must be unique.</div>
  <div i18n id="removeTabFromTemplate">Are you sure you want to remove this template from this tab?</div>
  <div i18n id="actionCopy">Copy</div>
  <div i18n id="actionMove">Move</div>
  <div i18n id="orgTemplateInfo">
    Organisation controllers can edit this template in the Template Library of your company Home Folder.
  </div>
  <div i18n id="exportPDFWithOriginalPhotosInfo">
    This option will download a ZIP folder containing individual PDFs for each form, and original photo files.
  </div>

  <div i18n id="appDeployed">Template deployed successfully</div>
  <div i18n id="appUndeployed">Template un-deployed successfully</div>

  <div i18n id="appRestored">Template(s) have successfully been Restored.</div>
  <div i18n id="appRestoredError">Unable to restore selected template(s).</div>
  <div i18n id="appArchived">Template(s) have successfully been archived.</div>
  <div i18n id="appArchivedError">Unable to archive selected template(s).</div>

  <div i18n id="templateDeployManagementSuccessful">Template deployed successfully.</div>
  <div i18n id="templateDeployManagementFailed">Unable to deploy template</div>

  <div i18n id="templatesBulkDeploySuccessful">Templates deployed successfully.</div>
  <div i18n id="templatesBulkDeployFailed">Unable to deploy templates.</div>

  <div i18n id="templatesBulkUndeploySuccessful">Templates undeployed successfully.</div>
  <div i18n id="templatesBulkUndeployFailed">Unable to undeploy templates.</div>

  <div i18n id="confirmDeployApps">Are you sure you want to deploy selected templates to this team?</div>
  <div i18n id="selectApps">Please select the templates you want to deploy</div>
  <div i18n id="regViewExported">Register view exported successfully</div>
  <div i18n id="regViewSettingSaved">Register view columns setting saved successfully</div>
  <div i18n id="exportCSVInfo">
    This option will export all of the forms selected to an excel file with raw text. Photos, videos, sketches
    and signatures will not be shown with this option.
  </div>
  <div i18n id="importCSVMaxFilesError">Unable to upload more than one CSV file at a time.</div>
  <div i18n id="horizontalTablesInfo">Horizontal tables are only available in app version 19.12 onward</div>
  <div i18n id="listPropertyTableTellBetaBanner">
    List Property cells are not supported in the Dashpivot app or Sitemate app yet.
  </div>
  <div i18n id="importByPasteDescription">
    You can copy and paste data from a spreadsheet into a Text, Number, Time or Date cell above.
  </div>
  <div i18n id="importByPasteDescriptionBrowserDefinedFormats">
    You can copy and paste data from a spreadsheet into a Text, Number, Time or Date cell above.
  </div>
  <div i18n id="tableKeyboardShortcuts">You can move between cells using arrow keys, tab and shift+tab.</div>
  <div i18n id="exportRegViewInfo">
    This option will download the selected forms into an A3 register PDF, with each form attached at the back
    as A4 PDFs.
  </div>
  <div i18n id="downloadFormsInfo">This option will download the selected forms into individual A4 PDFs.</div>
  <div i18n id="downloadRegisterOnlyInfo">
    This option will download the selected forms into an A3 register PDF.
  </div>
  <div i18n id="exportCsvVisibleColumnsInfo">
    This option will download all of the visible columns in the register view.
  </div>
  <div i18n id="exportCsvAllColumnsInfo">
    This option will download all columns in the register view, including hidden ones.
  </div>
  <div i18n id="exportCsvVisibleColumnsInfoWithAutofill">
    This option will autofill the data in your CSV and export all of the visible columns in the register view.
  </div>
  <div i18n id="exportCsvAllColumnsInfoWithAutofill">
    This option will autofill the data in your CSV and export all columns in the register view, including
    hidden ones.
  </div>

  <div id="deletePhotoDesc" i18n>Are you sure you want to delete the description?</div>
  <div id="deletePhotoComment" i18n>Are you sure you want to delete this comment?</div>
  <div id="deletePhotoTag" i18n>Are you sure you want to remove the tag?</div>

  <div id="photoCommentDeleted" i18n>Comment deleted successfully.</div>
  <div id="photoTagRemoved" i18n>Tag removed successfully.</div>
  <div id="photoDescDeleted" i18n>Description removed successfully.</div>

  <div i18n id="workflowNotificationOn">You will receive email notifications for this column.</div>
  <div i18n id="workflowNotificationOff">
    You will not receive email notifications for this column any more.
  </div>
  <div i18n id="workflowNotificationListUpdated">Notification list updated successfully.</div>
  <div i18n id="notificationInfo">
    Turn on to receive an email notification when a form moves into this column.
  </div>
  <div i18n id="unsubscribeTokenInvalid">Invalid token</div>

  <div i18n id="subscribedPersonalWeekly">
    You have been subscribed to the personal weekly snapshot email.
  </div>
  <div i18n id="unSubscribedPersonalWeekly">
    You have been unsubscribed from the personal weekly snapshot email.
  </div>

  <div i18n id="subscribedTeamWeekly">You have been subscribed to the team weekly snapshot email.</div>
  <div i18n id="unSubscribedTeamWeekly">You have been unsubscribed from the team weekly snapshot email.</div>

  <div i18n id="subscribedProjectMonthly">
    You have been subscribed to the project monthly snapshot email.
  </div>
  <div i18n id="unSubscribedProjectMonthly">
    You have been unsubscribed from the project monthly snapshot email.
  </div>

  <div i18n id="subscribedOrgMonthly">
    You have been subscribed to the organisation monthly snapshot email.
  </div>
  <div i18n id="unSubscribedOrgMonthly">
    You have been unsubscribed from the organisation monthly snapshot email.
  </div>

  <div i18n id="copiedSnippet">Copied snippet successfully.</div>
  <div i18n id="failedCopySnippet">Failed to copy snippet.</div>
  <div i18n id="confirmCopyAppsFromLibrary">
    Are you sure you want to copy selected templates to this team?
  </div>
  <div i18n id="noTemplateFieldAdded">Cannot save until you drag and drop a field into the template.</div>
  <div i18n id="dashboardAdded">Dashboard added successfully.</div>
  <div i18n id="dashboardUpdated">Dashboard updated successfully.</div>
  <div i18n id="deleteDashboard">
    This action is permanent and cannot be undone. Are you sure you want to delete selected dashboards and the
    following charts?
  </div>
  <div i18n id="dashboardDeleted">Dashboard deleted successfully.</div>
  <div i18n id="chartAdded">Chart added successfully.</div>
  <div i18n id="quantity">Quantity</div>
  <div i18n id="deleteCharts">Are you sure you want to delete selected charts?</div>
  <div i18n id="chartsDeleted">Charts deleted successfully.</div>
  <div i18n id="emailInviteSent">Email invite sent successfully.</div>
  <div i18n id="listImported">List uploaded successfully.</div>
  <div i18n id="listImportFailed">List upload unsuccessful.</div>
  <div i18n id="chartUpdated">Chart updated successfully.</div>
  <div i18n id="needTemplateBeforeCreateChart">
    Oops! You need to setup a template containing a default table field before you can setup any charts in
    this team.
  </div>
  <div i18n id="invalidColumnId">The id of this column is invalid. Please contact support team.</div>
  <div i18n id="chartFilterMoreThanTwo">There should only be up to 2 filters.</div>
  <div i18n id="chartFilterNotValid">Please fill out the filter before you run the chart.</div>
  <div i18n id="invalidSignUpForm">You have invalid input in the form. Please check.</div>
  <div i18n id="invalidRawCode">Invalid raw code.</div>
  <div i18n id="formNotFound">This form has been deleted.</div>
  <div i18n id="cloningWillExceedLimit">
    Unable to perform this action. Cloning this logic block will exceed your current plan limit.
  </div>

  <div i18n id="exceededFieldsLimitFromRawCode">
    The number of fields exceeds the limit. Please remove some fields in order to Hide Raw Code and Save the
    template.
  </div>

  <div i18n id="selectAtLeastOneTemplate">You must select at least 1 template to get started.</div>
  <div i18n id="deleteListProperty">Are you sure you want to delete this property?</div>

  <div i18n id="notifyApplicationUpdatedReload">
    A new version of Dashpivot is available. Press Ok to refresh your browser, and try again.
  </div>

  <div i18n id="upgradePlanToUnlockFeature">Upgrade your plan to unlock this feature</div>
  <div i18n id="ssoAvailable">Single Sign-On (SSO) is now available:</div>
  <div i18n id="ssoAvailableImprovedSecurity">
    Single Sign-On (SSO) is now available for improved security and convenience:
  </div>

  <div i18n id="unlockApprovalSignatureLogic">Unlock Approval Signature Logic</div>
  <div i18n id="unlockConditionalApprovalSignatureLogic">Unlock Conditional Approval Signature Logic</div>
  <div i18n id="unlockApprovalSignatureLogicSubtitle">
    Contact us to upgrade your subscription plan and enable Approval Signature logic to add restrictions to
    which user types/permissions can Approval Sign forms and Reset Workflows.
  </div>
  <div i18n id="unlockApprovalSignatureLogicSubtitleProPlan">
    Contact us to upgrade your subscription plan and enable up to 12 Conditional Approval Signature logic to
    add restrictions with logic to which user types/permissions can Approval Sign forms and Reset Workflows.
  </div>

  <div i18n id="unlockConditionalApprovalSignatureLogic">Unlock Conditional Approval Signature Logic</div>
  <div i18n id="unlockConditionalApprovalSignatureLogicSubtitle">
    Contact us to upgrade your subscription plan and enable up to 12 Conditional Approval Signature logic to
    add restrictions with logic to which user types/permissions can Approval Sign forms and Reset Workflows.
  </div>

  <div i18n id="upgradePlanToUnlockLimit">
    Contact us to upgrade your subscription plan and increase the number of properties you can add into your
    Lists.
  </div>

  <!--Downloader-->
  <div i18n id="downloadingError">Please try again, or contact support.</div>
  <div i18n id="downloadingTitleError">Processing failed</div>
  <div i18n id="downloadingTitleFinished">Ready to download</div>

  <!-- Users -->
  <div i18n id="dashpivotUsers">Dashpivot Users</div>
  <div i18n id="sitemateUsers">Sitemate Users</div>

  <div i18n id="orgControllers">Organisation Controllers</div>
  <div i18n id="orgMembers">All Other Users</div>

  <div i18n id="projectControllers">Project Controllers</div>
  <div i18n id="projectMembers">Project Members</div>

  <div i18n id="teamControllers">Team Controllers</div>
  <div i18n id="teamMembers">Team Members</div>

  <div i18n id="contributor">Contributors</div>
  <div i18n id="visitor">Visitors</div>

  <div i18n id="freeUsers">Free</div>
  <div i18n id="paidUsers">Paid</div>
  <div i18n id="ongoingSubmissions">Ongoing Submissions</div>
  <div i18n id="formLinks">Sitemate Links</div>
  <div i18n id="singleSubmissions">Single Submissions</div>

  <div i18n id="learnHowToUse">Learn how to use this for</div>
  <div i18n id="preStarts">Pre-Starts</div>
  <div i18n id="methodStatements">Method Statements (SWMS/RAMS)</div>
  <div i18n id="permits">Permits</div>

  <div i18n id="contributorsNoActiveUsers">No Contributors have been added in this folder yet.</div>
  <div i18n id="contributorsNoPendingUsers">
    There are no pending invites for Contributors in this folder.
  </div>

  <div i18n id="contributorsSubFolderNoActiveUsers">
    No Contributors have been added into sub folders yet.
  </div>
  <div i18n id="contributorsSubFolderNoPendingUsers">
    There are no pending invites for Contributors in sub folders.
  </div>

  <div i18n id="visitorsNoActiveUsers">No Visitors have been added in this folder yet.</div>
  <div i18n id="visitorsNoPendingUsers">There are no pending invites for Visitors in this folder.</div>

  <div i18n id="visitorsSubFolderNoActiveUsers">No Visitors have been added into sub folders yet.</div>
  <div i18n id="visitorsSubFolderNoPendingUsers">
    There are no pending invites for Visitors in sub folders.
  </div>

  <div i18n id="inviteVisitorsTypeError">Cannot add a Dashpivot Contributor as a Visitor.</div>
  <div i18n id="inviteContributorTypeError">Cannot add a Dashpivot Visitor as a Contributor.</div>

  <div i18n id="teamUserHeaderSubtitle">
    with Team Member permissions can upload photos, create forms, and view charts. Team Controller permissions
    can edit templates, create charts and invite users.
  </div>

  <div i18n id="projectUserCurrentFolderHeaderSubtitle">
    with Project Member permissions can upload photos, create forms, and view charts. Project Controller
    permissions can do everything Team Controllers can do for all team folders in this project folder, as well
    as create new team folders, create project-specific lists and invite team controllers.
  </div>
  <div i18n id="projectUserSubFolderHeaderSubtitle">
    in sub folders may have Team Controller and/or Team Member permissions in one or several sub folders.
  </div>

  <div i18n id="organisationUserCurrentFolderHeaderSubtitle">
    with Org Member permissions can upload photos, create forms, and view charts. Org Controller permissions
    can access and edit all Project and Team Folders within this workspace.
  </div>
  <div i18n id="organisationUserSubFolderHeaderSubtitle">
    in sub folders may have Project Controller, Team Controller and/or Team Member permissions in one or
    several sub folders.
  </div>

  <!-- Sitemate Links -->
  <div i18n id="singleLink">Single</div>
  <div i18n id="ongoingLink">Ongoing</div>
  <div i18n id="linkCopiedToClipboard">Link copied to clipboard</div>
  <div i18n id="templateLinksSingleTitle">Single Sitemate Links</div>
  <div i18n id="templateLinksRecurringTitle">Ongoing Sitemate Links</div>
  <div i18n id="templateLinksSingleSubTitle">
    Single Submission Sitemate Links can provide anyone with access to submit a single form for one or more
    templates, via the Sitemate app without a Dashpivot account.
  </div>
  <div i18n id="templateLinksRecurringSubTitle">
    Ongoing Submission Sitemate Links can provide anyone with access to submit multiple forms for one or more
    templates, via the Sitemate app without a Dashpivot account.
  </div>
  <div i18n id="templateLinksCreateFormSingleTitle">Create a Sitemate Link for Single Submissions</div>
  <div i18n id="templateLinksCreateFormRecurringTitle">Create a Sitemate Link for Ongoing Submissions</div>
  <div i18n id="templateLinksUpdateFormSingleTitle">Edit Sitemate Link for Single Submissions</div>
  <div i18n id="templateLinksUpdateFormRecurringTitle">Edit Sitemate Link for Ongoing Submissions</div>
  <div i18n id="templateLinksUnarchiveFormSingleTitle">Restore Sitemate Link for Single Submissions</div>
  <div i18n id="templateLinksUnarchiveFormRecurringTitle">Restore Sitemate Link for Ongoing Submissions</div>
  <div i18n id="templateLinkIsWorkflow">
    is setup as a workflow, which means Sitemate users can only fill out the fields above the first approval
    signature. Fields below the first approval signature will be visible in the Sitemate app, but cannot be
    filled out. If you’d like those fields to be filled out via the Sitemate app, you should move them above
    the first approval signature. Note: these fields can later be filled out in Dashpivot.
  </div>
  <div i18n id="templateLinkHasSignOnField">
    contains a ‘Scan Sitemate ID’ field, which cannot be used in forms submitted via the Sitemate app. The
    form can still be filled out, but any ‘Scan Sitemate ID’ fields will appear disabled.
  </div>
  <div i18n id="templateLinkHasPersonField">
    contains a ‘Person’ field, which cannot be used in forms submitted via the Sitemate app. The form can
    still be filled out, but any ‘Person’ fields will appear disabled.
  </div>
  <div i18n id="templateLinkIsWorkflowTitle">Workflow Template Detected</div>
  <div i18n id="templateLinkHasSignOnFieldTitle">Sitemate Scan ID Detected</div>
  <div i18n id="templateLinkHasPersonFieldTitle">Person Field Detected</div>
  <div i18n id="archiveLink">Are you sure you want to archive?</div>
  <div i18n id="archiveLinkSuccess">Link archived</div>
  <div i18n id="unarchiveLinkSuccess">Link unarchived</div>

  <!-- Activity -->
  <div i18n id="welcomeToDashpivot">Welcome to Dashpivot</div>
  <div i18n id="thanksForSigningUp">
    Thanks for signing up! Below you’ll find a guide we’ve created to help your team setup and use the
    software.
  </div>
  <div i18n id="guideExpiry">This guide will disappear when upgrading from a free trial to a paid plan</div>

  <!-- Activity Guide -->
  <div i18n id="settingUpFolders">Setting up Folders</div>
  <div i18n id="settingUpFoldersContent">
    The first step in organising digital information is to create folder structure that works for your
    company, in the sidebar at the top left of the screen. You can learn about the best way to do this for
    your company here:
  </div>
  <div i18n id="settingUpCompanyLogo">Setting up Company Logo</div>
  <div i18n id="settingUpCompanyLogoContent">
    If you want your forms to look professional online and when exported as a PDF, you'll want to make sure
    you upload your logo and set your brand colour. This will appear on every form, chart and PDF you create.
  </div>
  <div i18n id="logoUploadedSuccessfully">Logo uploaded successfully!</div>
  <div i18n id="settingUpLists">Setting up Lists</div>
  <div i18n id="settingUpListsContent">
    The ‘Lists’ section is for standardising asset and maintenance registers, which can be referenced in your
    form templates as dropdown menus. Common use cases include lists of employees, plant and equipment,
    subcontractors, suppliers, cost codes, work activities and assets.
  </div>
  <div i18n id="usingPhotos">Using Photos</div>
  <div i18n id="usingPhotosContent">
    The ‘Photos’ section is dedicated to organising your team’s photos using time/date-stamps, GPS stamps,
    tags, descriptions and markups. You can link photos to forms you fill out in the 'Templates' section.
  </div>
  <div i18n id="settingUpTemplates">Setting up Templates</div>
  <div i18n id="settingUpTemplatesContent">
    To go paperless and fill out forms electronically online and in the Dashpivot app, you can create form
    templates in the ‘Templates’ section. We have free versions available to help you get started, spanning
    safety, quality, environmental, commercial docs, and more!`
  </div>
  <div i18n id="usingAnalytics">Using Analytics</div>
  <div i18n id="usingAnalyticsContent">
    The ‘Analytics’ section is used to visualise data being captured in your forms via the ‘Templates'
    section. It’s most commonly used to track production, labour, compliance KPIs, and performance.
  </div>
  <div i18n id="addingUsers">Adding Users</div>
  <div i18n id="addingUsersContent">
    To provide access to your team, you’ll need to invite them to create a Dashpivot account via the ‘Users’
    section. Once setup, they’ll be able to access and create forms, and upload photos.
  </div>
  <div i18n id="usingTheMobileApp">Using the Mobile App</div>
  <div i18n id="usingTheMobileAppContent">
    The Dashpivot app allows you to fill out forms and upload photos on your phone/tablet. It's available for
    iOS and Android devices, works offline, has draft mode, autosave, photo markup and photo stamping.
  </div>

  <!-- Signup -->
  <div i18n id="skipIndex">Skip for now</div>
  <div i18n id="next">Next</div>
  <div i18n id="passwordDisabledForSSOSignup">
    Looks like your email domain is already in use and has been verified, so you won't need a Dashpivot
    password to login - instead you’ll be able to login using your company’s SSO service.
  </div>
  <div i18n id="ssoRedirectionMessage">
    You will be redirected to your identity provider to login via single sign on (SSO) shortly...
  </div>
  <div i18n id="loginRedirectErrorMessage">You will be redirected shortly...</div>

  <div i18n id="fastTrackYourSetup">Let us know more about what you do</div>
  <div i18n id="tellUsALittleBitAboutYourSelf">
    Tell us a little about your company, so we can recommend templates and features that will be most useful
    to you and your team. You can change this selection later.
  </div>
  <div i18n id="errorInVerticalRoles">
    An error occurred while attempting to retrieve industries and roles.
  </div>

  <!-- List overwrite warning  -->
  <div i18n id="warning">Warning</div>
  <div i18n id="deployedListOverwrite">
    Uploading a CSV will remove list items added from the List Library parent list, and create new list items
    from the CSV. However, you will be able to re-add the previous list items from the parent list manually.
  </div>
  <div i18n id="nonDeployedListOverwrite">
    Uploading a CSV will overwrite all existing list items and properties, and cannot be undone. Are you sure
    you want to overwrite?
  </div>

  <!--Form users-->
  <div i18n id="deletedAssignedUsersTeam">
    Are you sure you want to remove this user’s access to all Sitemate Links in this folder?
  </div>
  <div i18n id="deletedAssignedUsersCompany">
    Are you sure you want to remove this user’s access to the templates associated with ALL sitemate links?
  </div>

  <!-- Template editor warnings -->
  <div i18n id="legacyFormulaEngineWarning">
    Formulas can now cross-reference multiple tables. Please enable this via the formula bar.
  </div>
  <div i18n id="settingsThumbnailFieldRequiredError">Form thumbnails require at least 1 field.</div>
  <div i18n id="personFieldNameError">
    Duplicated Person Field name detected. Please use unique Field names for Person Fields.
  </div>

  <!-- Template editor toasts -->
  <div i18n id="upgradeFormulaEngineSuccess">Table cross-referencing is now enabled!</div>

  <!--Popover buil template-->
  <div i18n id="templateNameInfo">The Template name is the title of your template.</div>
  <div i18n id="templateIDInfo">
    The Template ID is a unique identifier code used to help manage and reference templates.
  </div>
  <div i18n id="formNameInfo">
    The Form name is used to denote the type of form being created with this template (eg. diary, checklist,
    form, inspection, timesheet, report, request, method statement, record, permit, induction, variation,
    order, docket)
  </div>
  <div i18n id="addToTabInfo">
    Template tabs are used to organise your templates, typically by function (eg. safety, quality,
    environment, finance, commercial, plant) and can be created and managed in your Template Library, located
    in the Home Folder.
  </div>

  <!--Folders-->
  <div i18n id="successfullyArchivedTeams">Folder(s) have successfully been archived.</div>
  <div i18n id="successfullyRestoredTeams">Folder(s) have successfully been restored.</div>
  <div i18n id="successfullyDeletedTeams">Folder(s) have successfully been deleted.</div>

  <div i18n id="archiveFolder">Are you sure you want to archive the selected folder?</div>
  <div i18n id="archiveFolders">Are you sure you want to archive these selected folders?</div>
  <div i18n id="archiveProject">
    Are you sure you want to archive these selected folders?
    <br />
    <br />
    Note: Archiving project folders will also archive any team folders it contains.
  </div>
  <div i18n id="archiveTeam">Are you sure you want to archive the selected folder?</div>
  <div i18n id="archiveTeams">Are you sure you want to archive these selected folders?</div>
  <div i18n id="deleteTeam">Are you sure you want to permanently delete the selected folder?</div>
  <div i18n id="deleteTeams">Are you sure you want to permanently delete these selected folders?</div>
  <div i18n id="deleteProject">
    Are you sure you want to permanently delete these selected folders?
    <br />
    <br />
    Note: Deleting project folders will also delete any team folders it contains.
  </div>
  <div i18n id="archiveFolderToast">You are now viewing an archived folder.</div>

  <div i18n id="folderPermissionsCannotRestoreProject">
    You do not have access to restore a project folder. Please contact your organisation controller.
  </div>

  <!-- Forms Register Actions -->
  <div i18n id="formsRegisterSelectSingleForm">Select this form</div>
  <div i18n id="formsRegisterDeselectSingleForm">Deselect this form</div>
  <div i18n id="formsRegisterSelectAllForms">Select all forms</div>
  <div i18n id="formsRegisterDeselectAllForms">Deselect all forms</div>
  <div i18n id="formsRegisterExpandSingleForm">Expand this form</div>
  <div i18n id="formsRegisterContractSingleForm">Contract this form</div>

  <!-- FormulasV2 -->
  <div i18n id="formulasV2Enabled">Table cross-referencing is now enabled!</div>

  <!-- Generic Errors -->
  <div i18n id="inputRequiredError">Input required</div>

  <!-- Add Workspace -->

  <div i18n id="addToExistingAccount">Add this workspace to an existing Sitemate Start Account</div>
  <div i18n id="createNewAccount">Create a new Sitemate Start Account for this workspace</div>
  <div i18n id="additionalWorkspaceNotAllowedOnStandard">
    Creating additional Dashpivot workspaces is not included on the Standard Plan:
  </div>

  <!-- Self Service Checkout Stepper -->
  <div i18n id="selfCheckoutLicenseStepDashpivotContributorsTooltip">
    Dashpivot Contributors are internal staff members who have more visibility and control over your processes
    (eg. management, supervisors, engineers, technicians).
  </div>
  <div i18n id="selfCheckoutLicenseStepSitematePaidUsersTooltip">
    Sitemate Paid Users can sign off, submit and review forms electronically via the Sitemate App. Typically
    for workforce users (eg. operators, labourers) who need ongoing involvement and access to submit forms.
  </div>
</div>
